import React from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { CSSTransition } from 'react-transition-group';
import AddChooserList from './add-chooser-list';
import AddLink from './add-link';
import AddProductsList from './add-products-list';
import DeleteFormWidget from './delete-form-widget';
import DeletePagePanel from './delete-page-panel';
import { InsideModalWrapper } from './index.styled';

const InsideModal = ({ modal, setModal }) => {
  const onClick = e => {
    if (e.target === e.currentTarget) {
      setModal(null);
    }
  };

  const ref = useOnclickOutside(
    () => {
      setModal(null);
    },
    {
      ignoreClass: `ignore-click`,
    },
  );

  const setPopupComponent = () => {
    switch (modal.type) {
      case 'delete-page-panel':
        return <DeletePagePanel modal={modal} setModal={setModal} />;
      case 'delete-form-widget':
        return <DeleteFormWidget modal={modal} setModal={setModal} />;
      case 'add-chooser-list':
        return <AddChooserList modal={modal} setModal={setModal} />;
      case 'add-products-list':
        return <AddProductsList modal={modal} setModal={setModal} />;
      case 'add-link':
        return <AddLink modal={modal} setModal={setModal} />;
      default:
        return <></>;
    }
  };

  if (!modal?.type) return null;

  return (
    <CSSTransition appear classNames="modal-transition" in timeout={100}>
      <InsideModalWrapper
        id="inside-modal-wrapper-id"
        onclick={onClick}
        style={{ justifyContent: modal.content?.top ? 'start' : 'center', overflow: 'scroll' }}>
        <CSSTransition key="modal-content-id" appear classNames="popup-in" in timeout={200}>
          <div
            key="inside-modal-content-id"
            ref={ref}
            className="modal-content"
            id="inside-modal-content-id"
            style={{
              marginTop: modal.content?.top ? '86px' : '0',
            }}>
            {setPopupComponent()}
          </div>
        </CSSTransition>
      </InsideModalWrapper>
    </CSSTransition>
  );
};

export default InsideModal;

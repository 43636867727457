import styled from 'styled-components';

export const MinMaxInputWrapper = styled.div`
  .input-field {
    height: 32px;
    border-radius: 12px;
    border: 1px solid ${({ theme }) => theme.colors.ZenGray2};

    placeholder {
      color: ${({ theme }) => theme.colors.ZenGray2};
    }
  }

  .input-border {
    border: none;
    border-radius: 12px;
  }

  .input-field-prefix {
    display: flex;
    align-items: center;
    padding-left: 16px;
    border-radius: 12px;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

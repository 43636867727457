import React from 'react';
import styled from 'styled-components';
import PhoneInput from './phone-input';

const FormPhoneInput = ({
  name,
  is_required,
  onChange = () => {},
  font = null,
  fontFamily,
  is_autofill,
  phoneValue = {},
  sub_label = null,
  showRequiredError,
}) => {
  const onChangeValue = (country, phone) => {
    const newPhoneValue = {
      phone,
      country,
      value: `${country}${phone?.replaceAll('-', '').replaceAll(' ', '') || ''}`,
    };
    onChange(newPhoneValue);
  };

  const onChangeCountry = country => {
    onChangeValue(country, phoneValue.phone);
  };

  const onChangePhone = phone => {
    onChangeValue(phoneValue.country || '+1', phone);
  };

  return (
    <FormPhoneInputWrapper className="w-full">
      <div className="mb-1">
        <span
          className="regular-text lighter-text"
          style={{ fontSize: font?.size ? `${font.size}px` : '14px', color: font?.color || '#6B7280', ...fontFamily }}>
          {name}
        </span>
        {is_required && <span className="required-star ml-1">*</span>}
      </div>
      <PhoneInput
        className={`form-phone-input ${showRequiredError && !phoneValue?.phone && 'phone-input-error'} ${
          is_autofill && 'disabled-phone-input'
        }`}
        phone={phoneValue?.phone}
        setPhone={phone => onChangePhone(phone)}
      />
      {sub_label && (
        <div className="mt-1">
          <span className="regular-text gray-500-text">{sub_label}</span>
        </div>
      )}
    </FormPhoneInputWrapper>
  );
};

const FormPhoneInputWrapper = styled('div')`
  .form-phone-input {
    .phone-input-dropdown {
      .organisations-border {
        padding: 8px;
        width: 80px;

        .selected-name {
          .selected-icon {
            margin-right: 4px !important;
          }
        }

        .input-arrow {
          min-height: 24px;
        }
      }
      .mc-options {
        top: 50px;
        .mc-option-item {
          padding: 0 4px;

          .option-icon {
            margin-right: 4px !important;
          }
        }
      }
    }
    .phone-input {
      padding-left: 70px !important;
    }
  }

  .phone-input-error {
    .phone-input {
      border-color: ${({ theme }) => theme.colors.ZenNegative};

      &:hover {
        border-color: ${({ theme }) => theme.colors.ZenNegative};
      }

      &:focus {
        border-color: ${({ theme }) => theme.colors.ZenNegative};
      }
    }
  }

  .disabled-phone-input {
    pointer-events: none;
    cursor: not-allowed;
    .input {
      background: ${({ theme }) => theme.colors.backgroundColor};
      opacity: 0.6;
    }
  }
`;

export default FormPhoneInput;

import React from 'react';
import { PrizeDrawDetailHeaderWrapper } from '../../styles/components/prize-draw-detail/prize-draw-detail.styled';

const ProfileDetailHeader = ({ title, titleContent = null, actionContent = null }) => {
  return (
    <PrizeDrawDetailHeaderWrapper className="items-center justify-between w-full pl-6 pr-4 py-4 border-bottom">
      <div className="flex items-center justify-start mr-2">
        <label className="medium-text font-20">{title}</label>
        {titleContent}
      </div>
    </PrizeDrawDetailHeaderWrapper>
  );
};

export default ProfileDetailHeader;

import React from 'react';
import { useTranslation } from 'react-i18next';

const NoDataComponent = () => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center justify-center h-full w-full">
      <label className="semibold-text grey-secondary-text font-40 o05">{t('NO_DATA')}</label>
    </div>
  );
};

export default NoDataComponent;

import React from 'react';

import { PrizeDrawDetailRightWrapper } from '../../styles/components/prize-draw-detail/prize-draw-detail.styled';
import ProfileDetail from './profile-user-details';

const UserProfleRight = () => {
  return (
    <PrizeDrawDetailRightWrapper className="flex border flex-1">
      <ProfileDetail />
    </PrizeDrawDetailRightWrapper>
  );
};

export default UserProfleRight;

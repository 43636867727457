import React from 'react';
import CustomColorPicker from '../../../components/Elements/ColorPicker/index';
import { ColorPickerWrapper } from '../edit-form-version.styled';

export const ColorPicker = ({
  selectedColor,
  label,
  className,
  settingKey,
  onChangeColor,
  colorPicker = '',
  setColorPicker = () => {},
}) => {
  return (
    <ColorPickerWrapper className={className} selectedColor={selectedColor}>
      {label && <label className="regular-text flex-1">{label}</label>}
      <div className="flex pxy-1 divider-border radius-3 relative picker-container">
        <div
          className="color divider-border cursor mr-2"
          onClick={() => setColorPicker(colorPicker === settingKey ? '' : settingKey)}
        />
        <label className="regular-text font-16 flex-1">{selectedColor}</label>
        {colorPicker === settingKey && (
          <CustomColorPicker
            onChangeComplete={color => onChangeColor(settingKey, color.hex)}
            close={() => setColorPicker('')}
            color={selectedColor}
            setColor={color => onChangeColor(settingKey, color)}
          />
        )}
      </div>
    </ColorPickerWrapper>
  );
};

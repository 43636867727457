import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import uuid from 'react-uuid';
import { ReactComponent as EditIcon } from '../../assets/images/edit.svg';
import { ReactComponent as Loading } from '../../assets/images/loading.svg';
import { ReactComponent as MailIcon } from '../../assets/images/mail.svg';
import { ReactComponent as MobileIcon } from '../../assets/images/mobile.svg';
import { ReactComponent as PlusIcon } from '../../assets/images/plus.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/trash-red.svg';
import { OrganisationContext } from '../../context/organisationContext';
import { capitalize, checkRoleTier, formatText, getUserInitials } from '../../helpers/utils';
import OtpInput from '../../pages/otp-input';
import {
  authenticateMFACpde,
  getDetailsProfileUser,
  updateMFA,
  updateProfile,
  updateprofileData,
  userConsent,
  userImage,
  userMfa,
  verifyCode,
} from '../../store/features/profileSlice';
import { addToast } from '../../store/features/toastSlice';
import { uploadMedia } from '../../store/services/media';
import { PrizeDrawDetailLeftWrapper } from '../../styles/components/prize-draw-detail/prize-draw-detail.styled';
import Button from '../Elements/button/button';
import Checkbox from '../checkbox';
import Menu from '../common/menu';
import PhoneInput from '../common/phone-input';
import Status from '../common/status/automation-log-status';
import UpdateAction from '../common/update-action';

const userLeft = ({ prfile_Id }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const { id } = useParams();

  const { user: loggedInUser } = useSelector(state => state.user);
  const { user, mfaConfigration, userDataProfile } = useSelector(state => state?.profile || {});

  const { organizations, isAccessAccount, original_organizations } = loggedInUser || {};
  const { role: loggedInUserRole } = (isAccessAccount ? original_organizations?.[0] : organizations?.[0]) || {};

  const { setModal, hasPermission } = useContext(OrganisationContext);
  const [updateUserDetail, setUpdateUserDetail] = useState({ type: '', value: '' });
  const [updateUserDetailMFA, setUpdateUserDetailMFA] = useState({ type: '', value: '' });
  const inputRef = useRef();
  const [imageData, setImageData] = useState({});
  const [mfaConfig, setMfaConfig] = useState();
  const [mfaConfigDefault, setMfaConfigDefault] = useState();
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otpValue, setOtpValue] = useState('');
  const [mfaId, setMfaId] = useState('');
  const [isFirstInputFocus, setFirstInputFocus] = useState(false);
  const [isProfileUploading, setIsProfileUploading] = useState(false);
  const [isProfileDeleting, setIsProfileDeleting] = useState(false);
  const [consent, setConsent] = useState(false);
  const [phoneInputIsFocused, setPhoneInputFocused] = useState(false);
  const [showConsentError, setShowConsentError] = useState(false);

  const isEditPermission =
    // hasPermission('USER_MANAGE_OWN') &&
    // (user?.user?.email === userDataProfile?.email || checkRoleTier(loggedInUserRole, userDataProfile?.role));

    !prfile_Id
      ? user?.user?.email === userDataProfile?.email && hasPermission('USER_MANAGE_OWN')
      : hasPermission('USERS_MANAGE') && checkRoleTier(loggedInUserRole, userDataProfile?.role);

  useEffect(() => {
    setMfaConfigDefault(mfaConfigration);
    const defaultMfaConfig = mfaConfigration && mfaConfigration.find(config => config.is_default === true);
    if (defaultMfaConfig) {
      setMfaConfig(defaultMfaConfig.type);
    }
  }, [mfaConfigration]);

  useEffect(() => {
    setImageData({ url: userDataProfile?.avatar?.url });
  }, [userDataProfile?.avatar?.url]);

  const onCancelUpdate = () => {
    setEmailError(false);
    setPhoneError(false);
    setUpdateUserDetail({ type: '', value: '' });
    setUpdateUserDetailMFA({ type: '', value: '' });
    setMfaConfigDefault(mfaConfigration);
  };

  const checkOtp = type => {
    const request = {
      type,
      code: otpValue,
    };

    dispatch(verifyCode(request))
      .then(response => {
        onCancelUpdate();
        dispatch(getDetailsProfileUser({ id: id ? id : user?.id })).catch(() => {});
        setOtpValue(null);
        dispatch(addToast({ error: false, text: 'Verify Code SuccessFully' }));
      })
      .catch(err => {
        dispatch(addToast({ error: true, text: err?.response?.data?.error_description || 'Please enter correct OTP' }));
      });
  };

  const checkOTPMFA = type => {
    dispatch(authenticateMFACpde({ id: mfaId?.id, request: { code: otpValue, method: type } }))
      .then(response => {
        onCancelUpdate();
        dispatch(getDetailsProfileUser({ id: id ? id : user?.id })).catch(() => {});
        setOtpValue(null);
        dispatch(addToast({ error: false, text: 'Verify Code SuccessFully' }));
      })
      .catch(err => {
        dispatch(addToast({ error: true, text: err?.response?.data?.error_description || 'Please enter correct OTP' }));
      });
  };
  const handleUserConsent = async () => {
    try {
      const consentRequest = [{ send_by: 'SMS', consent: consent }];
      await dispatch(userConsent({ request: consentRequest })).catch(error => {
        const errorText = error?.response?.data?.error_description || 'Error while activating user';
        setLoading(false);
        dispatch(addToast({ error: true, text: errorText, id: uuid() }));
        return Promise.reject(error);
      });
      return Promise.resolve(true);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const onUpdate = async (field = '', imgData = '') => {
    let imageData = {};
    let updatedDetails = {
      title: '',
      timezone: '',
      language: '',
      forename: userDataProfile?.name.split(' ')[0],
      surname: userDataProfile?.name.split(' ').slice(1).join(' '),
      email: userDataProfile?.email,
      phone: userDataProfile?.phone?.replace(/[\s-+]+/g, '').replace(/^0/, ''),
      avatar: {
        id: userDataProfile?.avatar?.media_external_id || '',
      },
      country_code: '',
    };
    if (field === 'img' && imgData) {
      try {
        setIsProfileUploading(true);
        imageData = await uploadMedia(imgData);
        updatedDetails = { ...updatedDetails, avatar: { id: imageData?.media_external_id } };
        setIsProfileUploading(false);
      } catch (error) {
        setIsProfileUploading(false);
        dispatch(addToast({ error: true, text: 'Error while uploading image', id: uuid() }));
        return;
      }
      if (prfile_Id) {
        dispatch(updateProfile({ id: id, request: updatedDetails }))
          .then(response => {
            dispatch(getDetailsProfileUser({ id: id })).catch(() => {});
            onCancelUpdate();
          })
          .catch(err => {
            setIsProfileUploading(false);
          });
      } else {
        dispatch(updateprofileData(updatedDetails))
          .then(response => {
            onCancelUpdate();
            dispatch(getDetailsProfileUser({ id: id })).catch(() => {});
          })
          .catch(err => {
            setIsProfileUploading(false);
          });
      }
    } else if (field === 'img' && !imgData) {
      setIsProfileDeleting(true);
      updatedDetails = { ...updatedDetails, avatar: { id: '' }, avatar_id: '' };
      if (prfile_Id) {
        dispatch(updateProfile({ id: id, request: updatedDetails }))
          .then(response => {
            dispatch(getDetailsProfileUser({ id: id })).catch(() => {});
            onCancelUpdate();
            setIsProfileDeleting(false);
            setImageData('');
            dispatch(userImage(''));
          })
          .catch(err => {
            setIsProfileDeleting(false);
          });
      } else {
        dispatch(updateprofileData(updatedDetails))
          .then(response => {
            onCancelUpdate();
            dispatch(getDetailsProfileUser({ id: id })).catch(() => {});
            setIsProfileDeleting(false);
            setImageData('');
            dispatch(userImage(''));
          })
          .catch(err => {
            setIsProfileDeleting(false);
          });
      }
    } else if (updateUserDetail.type === 'name') {
      const newName = updateUserDetail?.value;
      const forename = newName.split(' ')[0];
      const surname = newName.split(' ').slice(1).join(' ');
      if (forename && surname) {
        updatedDetails = { ...updatedDetails, forename, surname };
        if (prfile_Id) {
          dispatch(updateProfile({ id: id, request: updatedDetails }))
            .then(() => {
              dispatch(getDetailsProfileUser({ id: id })).catch(() => {});
              onCancelUpdate();
            })
            .catch(err => {});
        } else {
          dispatch(updateprofileData(updatedDetails))
            .then(() => {
              onCancelUpdate();
              dispatch(getDetailsProfileUser({ id: id })).catch(() => {});
            })
            .catch(() => {});
        }
      } else {
        if (!newName) {
          dispatch(addToast({ error: true, text: 'Name cannot be empty' }));
        } else if (!surname) {
          dispatch(addToast({ error: true, text: 'Please enter a surname' }));
        }
      }
    } else if (updateUserDetail.type === 'mobile') {
      const newCountryCode = updateUserDetail?.value?.countryCode;
      const newPhone = updateUserDetail?.value?.mobile.replace(/\+1|\+44/g, '');
      if (newCountryCode !== updatedDetails.country_code || newPhone !== updatedDetails.phone) {
        updatedDetails = {
          ...updatedDetails,
          country_code: newCountryCode,
          phone: newPhone.replace(/[\s-+]+/g, '').replace(/^0/, ''),
        };
        if (!consent) {
          dispatch(addToast({ error: true, text: t('CONSENT_ERROR'), id: uuid() }));
          setShowConsentError(true);
          return;
        } else {
          setShowConsentError(false);
        }
        await handleUserConsent().then(() => {
          if (prfile_Id) {
            dispatch(updateProfile({ id: id, request: updatedDetails }))
              .then(response => {
                dispatch(getDetailsProfileUser({ id: id })).catch(() => {});
                onCancelUpdate();
              })
              .catch(err => {});
          } else {
            dispatch(updateprofileData(updatedDetails))
              .then(response => {
                dispatch(getDetailsProfileUser({ id: id })).catch(() => {});
                dispatch(dispatch(addToast({ error: false, text: 'Sent Code Successfully' })));
              })
              .catch(err => {});
          }
        });
      } else {
        onCancelUpdate();
      }
    } else {
      const newValue = updateUserDetail.value.trim();
      if (newValue && newValue !== updatedDetails[updateUserDetail.type]) {
        updatedDetails = { ...updatedDetails, [updateUserDetail.type]: newValue };
        if (prfile_Id) {
          dispatch(updateProfile({ id: id, request: updatedDetails }))
            .then(response => {
              dispatch(getDetailsProfileUser({ id: id })).catch(() => {});
              onCancelUpdate();
            })
            .catch(err => {});
        } else {
          dispatch(updateprofileData(updatedDetails))
            .then(response => {
              if (updateUserDetail.type !== 'email') {
                onCancelUpdate();
              }
              if (updateUserDetail.type === 'email') {
                dispatch(dispatch(addToast({ error: false, text: 'Sent Code Successfully' })));
              }
              dispatch(getDetailsProfileUser({ id: id })).catch(() => {});
            })
            .catch(err => {});
        }
      } else {
        dispatch(addToast({ error: true, text: 'Email cannot be empty' }));
      }
    }
  };

  const onUpdateMFA = async val => {
    dispatch(userMfa({ type: mfaConfig, is_default: true }))
      .then(response => setMfaId(response), dispatch(addToast({ error: false, text: 'Sent Code SuccessFully' })))
      .catch(err => {
        dispatch(addToast({ error: true, text: err?.response?.data?.error_description || 'Code Not Sent' }));
      });
  };

  const emptyImageContent = (
    <div
      className={`img-container border flex items-center justify-center radius-4 cursor mr-4 ${
        isEditPermission && 'empty-img-content'
      }`}>
      <input
        className="display-none"
        type="file"
        ref={inputRef}
        onChange={e => {
          setImageData({ ...imageData, img: e.target.files[0], url: window.URL.createObjectURL(e.target.files[0]) });
          dispatch(
            userImage({ ...imageData, img: e.target.files[0], url: window.URL.createObjectURL(e.target.files[0]) }),
          );
          onUpdate('img', e.target.files[0]);
        }}
      />
      {getUserInitials(userDataProfile || {}) && (
        <div className="user-icon flex items-center justify-center empty-img">
          <span className="font-24 bold-text grey-text">{getUserInitials(userDataProfile || {})}</span>
        </div>
      )}

      {isEditPermission && (
        <div className="add-img-btn radius-2 flex items-center justify-center" onClick={() => inputRef.current.click()}>
          <PlusIcon />
        </div>
      )}
    </div>
  );

  const imageContent = (
    <div className="flex items-center justify-center radius-4 cursor img-data-wrapper relative mr-4">
      {isProfileDeleting ? (
        <Loading />
      ) : (
        isEditPermission && (
          <div
            className="delete-btn-wrapper absolute flex items-center justify-center radius-2"
            onClick={() => onUpdate('img', '')}>
            <DeleteIcon width={14} height={14} />
          </div>
        )
      )}
      <img
        className="flex items-center justify-center radius-2"
        src={imageData?.url}
        style={{ objectFit: 'cover', height: '64px', width: '64px' }}
      />
    </div>
  );

  const imageAvater = (
    <div className="flex items-center justify-center radius-4 cursor img-data-wrapper relative mr-4">
      {isEditPermission && (
        <div
          className="delete-btn-wrapper absolute flex items-center justify-center radius-2"
          onClick={() => onUpdate('img', '')}>
          <DeleteIcon width={14} height={14} />
        </div>
      )}
      <img
        className="flex items-center justify-center radius-2"
        src={userDataProfile?.avatar?.url}
        style={{ objectFit: 'cover', height: '64px', width: '64px' }}
      />
    </div>
  );

  const nameField =
    updateUserDetail.type === 'name' ? (
      <div className="update-detail-contaner relative">
        <input
          className="input w-full edit-input"
          value={updateUserDetail.value}
          onChange={e => setUpdateUserDetail({ ...updateUserDetail, value: e.target.value })}
          placeholder="Name"
          autoFocus
        />
        <UpdateAction onCancel={onCancelUpdate} onUpdate={() => onUpdate('name')} />
      </div>
    ) : (
      <div
        className="flex"
        onClick={() =>
          isEditPermission &&
          setUpdateUserDetail({
            type: 'name',
            value: userDataProfile?.name,
          })
        }>
        <span
          className={`regular-text font-16 flex items-center one-line mr-10 cursor ${
            isEditPermission && 'content-hover-edit-1'
          }`}>
          <span className="one-line">{userDataProfile?.name}</span>
          {isEditPermission && <EditIcon width={16} height={16} className="edit-icon ml-2" />}
        </span>
      </div>
    );
  const FieldInputEmail =
    !prfile_Id || user?.user?.email === userDataProfile?.email ? (
      <div className="w-full">
        <div>
          <input
            className={`input w-full ${emailError && 'required-error'}`}
            value={updateUserDetail?.value}
            placeholder="Enter Email"
            onChange={({ target }) => {
              setUpdateUserDetail({
                ...updateUserDetail,
                value: target.value,
              });
              setEmailError(false);
            }}
          />
          <Button
            size="large"
            width="100%"
            className={`mt-4 ${updateUserDetail?.value ? 'primary' : 'disabled'}`}
            onClick={onUpdate}
            label={t('SEND_CODE')}
            borderRadius="16px"
          />
        </div>
        <div className="pt-6 mfa-form">
          <label className="subtitle regular-text lighter-text font-12">{t('VERIFY_CODE_EMAIL')}</label>
          <OtpInput
            className={'otp-input mt-2'}
            containerClass={'otp-content'}
            errorStyle={'error-style'}
            inputStyle={'input-style'}
            isInputNum={true}
            onChange={data => {
              setFirstInputFocus(false);
              setOtpValue(data);
            }}
            placeholder="000000"
            value={otpValue}
            isFirstInputFocus={isFirstInputFocus}
          />
          <div className="flex items-center gap-4">
            <Button
              size="medium"
              width="100%"
              className={`mt-6 cancle-button-email`}
              onClick={() => {
                setOtpValue(''), onCancelUpdate();
              }}
              label={t('CANCEL')}
              borderRadius="12px"
            />
            <Button
              size="medium"
              width="100%"
              onClick={() => checkOtp('EMAIL')}
              className={`mt-6 ${
                otpValue && otpValue.length === 6 ? 'primary email-mfa-save' : 'disabled email-mfa-save'
              }`}
              label={t('SAVE')}
              borderRadius="12px"
            />
          </div>
        </div>
      </div>
    ) : (
      <div className="w-full">
        <div className="update-detail-contaner relative">
          <input
            className={`input w-full ${emailError && 'required-error'}`}
            value={updateUserDetail?.value}
            placeholder="Enter Email"
            onChange={({ target }) => {
              setUpdateUserDetail({
                ...updateUserDetail,
                value: target.value,
              });
              setEmailError(false);
            }}
          />
          <UpdateAction onCancel={onCancelUpdate} onUpdate={onUpdate} />
        </div>
      </div>
    );

  const FieldInputEmailMFA =
    !prfile_Id || user?.user?.email === userDataProfile?.email ? (
      <div className="w-full px-6">
        <div>
          <input
            className={`input w-full ${emailError && 'required-error'}`}
            value={updateUserDetailMFA?.value}
            placeholder="Enter Email"
            disabled={true}
            onChange={({ target }) => {
              setUpdateUserDetailMFA({
                ...updateUserDetailMFA,
                value: target.value,
              });
              setEmailError(false);
            }}
          />
          <Button
            size="medium"
            width="100%"
            className={`mt-4 ${updateUserDetailMFA?.value ? 'primary' : 'disabled'}`}
            onClick={() => onUpdateMFA('email')}
            label={t('SEND_CODE')}
            borderRadius="12px"
          />
        </div>
        <div className="pt-6 mfa-form">
          <label className="subtitle regular-text lighter-text font-12">{t('VERIFY_CODE_EMAIL')}</label>
          <OtpInput
            className={'otp-input mt-2'}
            containerClass={'otp-content'}
            errorStyle={'error-style'}
            inputStyle={'input-style'}
            isInputNum={true}
            onChange={data => {
              setFirstInputFocus(false);
              setOtpValue(data);
            }}
            placeholder="000000"
            value={otpValue}
            isFirstInputFocus={isFirstInputFocus}
          />
          <div className="flex items-center gap-4">
            <Button
              size="medium"
              width="100%"
              className={`mt-6 cancle-button-email`}
              onClick={() => {
                setMfaConfig(mfaConfigration && mfaConfigration.find(config => config.is_default === true).type);
                setOtpValue('');
              }}
              label={t('CANCEL')}
              borderRadius="12px"
            />
            <Button
              size="medium"
              width="100%"
              onClick={() => checkOTPMFA('EMAIL')}
              className={`mt-6 ${
                otpValue && otpValue.length === 6 ? 'primary email-mfa-save' : 'disabled email-mfa-save'
              }`}
              label={t('SAVE')}
              borderRadius="12px"
            />
          </div>
        </div>
      </div>
    ) : (
      <div className="w-full px-6">
        <div className="update-detail-contaner relative">
          <input
            className={`input w-full ${emailError && 'required-error'}`}
            value={updateUserDetailMFA?.value}
            placeholder="Enter Email"
            onChange={({ target }) => {
              setUpdateUserDetailMFA({
                ...updateUserDetailMFA,
                value: target.value,
              });
              setEmailError(false);
            }}
          />
          <UpdateAction onCancel={onCancelUpdate} onUpdate={onUpdate} />
        </div>
      </div>
    );
  const emailField =
    updateUserDetail.type === 'email' ? (
      <div className={`${updateUserDetail.type === 'email' ? 'p-0' : 'pxy-6'}`}>
        <div className="flex gap-2 items-baseline">
          <MailIcon height={16} width={16} />
          {FieldInputEmail}
        </div>
      </div>
    ) : (
      <div
        className={`flex`}
        onClick={() =>
          isEditPermission &&
          setUpdateUserDetail({
            type: 'email',
            value: userDataProfile?.email,
          })
        }>
        <div className={`flex row-gap-2 justify-between cursor ${isEditPermission && 'content-hover-edit-1'}`}>
          <div className="flex col-gap-2 items-center">
            <MailIcon height={16} width={16} />
            <label className={`regular-text font-14`}>{userDataProfile?.email}</label>
          </div>
          {isEditPermission && <EditIcon width={16} height={16} className={`edit-icon ml-2 mt-1`} />}
        </div>
      </div>
    );

  const FieldInputMobile =
    !prfile_Id || user?.user?.email === userDataProfile?.email ? (
      <div className="w-full px-6">
        <div className="flex flex-column">
          <PhoneInput
            selectedCountry={updateUserDetail?.value?.countryCode ? updateUserDetail?.value?.countryCode : '+1'}
            setSelectedCountry={country =>
              setUpdateUserDetail({
                ...updateUserDetail,
                value: {
                  ...updateUserDetail.value,
                  countryCode: country,
                },
              })
            }
            className={`${phoneError && 'error-phone'}`}
            phone={updateUserDetail?.value?.mobile?.replace(/\+1|\+44/g, '')}
            setPhone={value => {
              setUpdateUserDetail({
                ...updateUserDetail,
                value: {
                  ...updateUserDetail.value,
                  mobile: value,
                },
              });
              setPhoneError(false);
            }}
            setFocused={isFocus => setPhoneInputFocused(isFocus)}
          />
          {phoneInputIsFocused || updateUserDetail?.value?.mobile ? (
            <div className="flex flex-column w-full items-start justify-start mt-2 consent-wrapper">
              <label className="regular-text font-12">{`${t('CONSENT_LABEL')}:`}</label>
              <div className="flex items-start mt-1 col-gap-1 w-full">
                <Checkbox
                  checked={consent}
                  onChange={checked => setConsent(checked)}
                  showRequiredError={showConsentError}
                />
                <p className="regular-text flex-1 font-12">{t('CONSENT_TEXT')}</p>
              </div>
            </div>
          ) : (
            <></>
          )}
          <Button
            size="large"
            width="100%"
            className={`mt-4 ${
              updateUserDetail?.value?.mobile &&
              updateUserDetail?.value?.mobile?.replace(/[\s-]/g, '').replace(/\+1|\+44/g, '').length === 10
                ? 'primary'
                : 'disabled'
            }`}
            onClick={onUpdate}
            label={t('SEND_CODE')}
            borderRadius="12px"
          />
        </div>
        <div className="pt-6 mfa-form">
          <label className="subtitle regular-text lighter-text font-12">{t('VERIFY_CODE')}</label>
          <OtpInput
            className={'otp-input mt-2'}
            containerClass={'otp-content'}
            errorStyle={'error-style'}
            inputStyle={'input-style'}
            isInputNum={true}
            onChange={data => {
              setFirstInputFocus(false);
              setOtpValue(data);
            }}
            placeholder="000000"
            value={otpValue}
            isFirstInputFocus={isFirstInputFocus}
          />
          <div className="flex items-center gap-4">
            <Button
              size="medium"
              width="100%"
              className={`mt-6 cancle-button-email`}
              onClick={() => {
                setOtpValue(''), onCancelUpdate();
              }}
              label={t('CANCEL')}
              borderRadius="12px"
            />
            <Button
              size="medium"
              width="100%"
              onClick={() => checkOtp('PHONE')}
              className={`mt-6 ${
                otpValue && otpValue.length === 6 ? 'primary email-mfa-save ' : 'disabled email-mfa-save'
              }`}
              label={t('SAVE')}
              borderRadius="12px"
            />
          </div>
        </div>
      </div>
    ) : (
      <div className="w-full">
        <div className="update-detail-contaner relative">
          <PhoneInput
            selectedCountry={updateUserDetail?.value?.countryCode ? updateUserDetail?.value?.countryCode : '+1'}
            setSelectedCountry={country =>
              setUpdateUserDetail({
                ...updateUserDetail,
                value: {
                  ...updateUserDetail.value,
                  countryCode: country,
                },
              })
            }
            className={`${phoneError && 'error-phone'}`}
            phone={updateUserDetail?.value?.mobile?.replace(/\+1|\+44/g, '')}
            setPhone={value => {
              setUpdateUserDetail({
                ...updateUserDetail,
                value: {
                  ...updateUserDetail.value,
                  mobile: value,
                },
              });
              setPhoneError(false);
            }}
          />
          <UpdateAction onCancel={onCancelUpdate} onUpdate={onUpdate} />
        </div>
      </div>
    );

  const FieldInputMobileMFA =
    !prfile_Id || user?.user?.email === userDataProfile?.email ? (
      <div className="w-full px-6">
        <div>
          <PhoneInput
            selectedCountry={updateUserDetailMFA?.value?.countryCode ? updateUserDetailMFA?.value?.countryCode : '+1'}
            disabled={true}
            setSelectedCountry={country =>
              setUpdateUserDetailMFA({
                ...updateUserDetailMFA,
                value: {
                  ...updateUserDetailMFA.value,
                  countryCode: country,
                },
              })
            }
            className={`${phoneError && 'error-phone'}`}
            phone={updateUserDetailMFA?.value?.mobile?.replace(/\+1|\+44/g, '')}
            setPhone={value => {
              setUpdateUserDetailMFA({
                ...updateUserDetailMFA,
                value: {
                  ...updateUserDetailMFA.value,
                  mobile: value,
                },
              });
              setPhoneError(false);
            }}
          />
          <Button
            size="medium"
            width="100%"
            className={`mt-4 ${
              updateUserDetailMFA?.value?.mobile &&
              updateUserDetailMFA?.value?.mobile?.replace(/[\s-]/g, '').replace(/\+1|\+44/g, '').length === 10
                ? 'primary'
                : 'disabled'
            }`}
            onClick={onUpdateMFA}
            label={t('SEND_CODE')}
            borderRadius="12px"
          />
        </div>
        <div className="pt-6 mfa-form">
          <label className="subtitle regular-text lighter-text font-12">{t('VERIFY_CODE')}</label>
          <OtpInput
            className={'otp-input mt-2'}
            containerClass={'otp-content'}
            errorStyle={'error-style'}
            inputStyle={'input-style'}
            isInputNum={true}
            onChange={data => {
              setFirstInputFocus(false);
              setOtpValue(data);
            }}
            placeholder="000000"
            value={otpValue}
            isFirstInputFocus={isFirstInputFocus}
          />
          <div className="flex items-center gap-4">
            <Button
              size="medium"
              width="100%"
              className={`mt-6 cancle-button-email`}
              onClick={() => {
                setMfaConfig(mfaConfigration && mfaConfigration.find(config => config.is_default === true).type);
                setOtpValue('');
              }}
              label={t('CANCEL')}
              borderRadius="12px"
            />
            <Button
              size="medium"
              width="100%"
              onClick={() => checkOTPMFA('SMS')}
              className={`mt-6 ${
                otpValue && otpValue.length === 6 ? 'primary email-mfa-save' : 'disabled email-mfa-save'
              }`}
              label={t('SAVE')}
              borderRadius="12px"
            />
          </div>
        </div>
      </div>
    ) : (
      <div className="w-full px-6">
        <div className="update-detail-contaner relative">
          <PhoneInput
            selectedCountry={updateUserDetail?.value?.countryCode ? updateUserDetail?.value?.countryCode : '+1'}
            setSelectedCountry={country =>
              setUpdateUserDetail({
                ...updateUserDetail,
                value: {
                  ...updateUserDetail.value,
                  countryCode: country,
                },
              })
            }
            className={`${phoneError && 'error-phone'}`}
            phone={updateUserDetail?.value?.mobile?.replace(/\+1|\+44/g, '')}
            setPhone={value => {
              setUpdateUserDetail({
                ...updateUserDetail,
                value: {
                  ...updateUserDetail.value,
                  mobile: value,
                },
              });
              setPhoneError(false);
            }}
          />
          <UpdateAction onCancel={onCancelUpdate} onUpdate={onUpdate} />
        </div>
      </div>
    );
  const mobileEditField =
    updateUserDetail.type === 'mobile' ? (
      <div className={`${updateUserDetail.type === 'mobile' ? 'p-0' : 'pxy-6'}`}>
        <div className="flex gap-2 items-baseline">
          <MobileIcon height={16} width={16} />
          {FieldInputMobile}
        </div>
      </div>
    ) : (
      <div
        className={`flex`}
        onClick={() =>
          isEditPermission &&
          setUpdateUserDetail({
            type: 'mobile',
            value: {
              countryCode: userDataProfile?.country_code ? userDataProfile?.country_code : '+1',
              mobile: userDataProfile?.phone ? userDataProfile?.phone : '',
            },
          })
        }>
        <div className={`flex row-gap-2 justify-between cursor ${isEditPermission && 'content-hover-edit-1'}`}>
          <div className="flex col-gap-2 items-center mt-1">
            <MobileIcon height={16} width={16} />
            <label className={`regular-text font-14`}>{userDataProfile?.phone}</label>
            <label className={`lighter-text medium-text font-10 lable-bg-color px-2`}>{t('MOBILE')}</label>
          </div>
          {isEditPermission && <EditIcon width={16} height={16} className={`edit-icon ml-2 mt-1`} />}
        </div>
      </div>
    );

  const onDeleteUser = async () => {
    setModal({
      type: 'user-action',
      content: {
        user: userDataProfile,
        top: true,
        isDelete: true,
        isCancelInvitation: false,
        title: 'Are you sure you want to delete this user?',
        isUserDetailPage: true,
      },
    });
  };

  const onChangeStatus = async payload => {
    const is_active = payload === 'Active' ? true : false;
    setModal({
      type: 'user-action',
      content: {
        user: { ...userDataProfile, is_active: is_active },
        top: true,
        isChangeStatus: true,
        isActive: !is_active,
        shouldUpdateUserList: false,
        title: `Are you sure you want to ${!is_active ? 'deactivate' : 'activate'} this user?`,
        isUserDetailPage: true,
      },
    });
  };

  const getMenuList = () => {
    const menuItems = [{ name: 'Delete', onClick: onDeleteUser, permission: 'USERS_MANAGE' }];

    if (userDataProfile?.status === 'ACTIVE') {
      menuItems.unshift({
        name: 'De-activate',
        onClick: () => onChangeStatus('De-Active'),
        permission: 'USERS_MANAGE',
      });
    } else if (userDataProfile?.status === 'INACTIVE') {
      menuItems.unshift({ name: 'Activate', onClick: () => onChangeStatus('Active'), permission: 'USERS_MANAGE' });
    }

    return menuItems;
  };

  const handleChangeMfa = (e, flag) => {
    if (e.target.value === 'EMAIL' && !userDataProfile?.email && !flag) {
      dispatch(addToast({ error: true, text: 'Add Email Before Adding MFA' }));
      return;
    }

    if (e.target.value === 'SMS' && !userDataProfile?.phone && !flag) {
      dispatch(addToast({ error: true, text: 'Add Phone Number Before Adding MFA' }));
      return;
    }
    setMfaConfig(e.target.value);
    if (flag) {
      const targetType = e.target.value === 'EMAIL' ? 'EMAIL' : 'SMS';
      const filteredId = mfaConfigration.filter(config => config.type === targetType).map(config => config.id);
      dispatch(updateMFA({ id: filteredId[0], request: { is_default: true } })).then(response =>
        dispatch(addToast({ text: 'MFA Authentication Changed Successfully' })),
      );
    }

    setUpdateUserDetailMFA({
      type: e.target.value === 'EMAIL' ? 'email' : 'mobile',
      value:
        e.target.value === 'EMAIL'
          ? userDataProfile?.email
          : {
              countryCode: userDataProfile?.country_code ? userDataProfile?.country_code : '+1',
              mobile: userDataProfile?.phone ? userDataProfile?.phone : '',
            },
    });
  };
  return (
    <PrizeDrawDetailLeftWrapper className="border flex-column">
      <div className="flex items-center border-bottom px-6 py-4">
        <label className="flex-1 font-20 medium-text">{t(prfile_Id ? 'USER_PROFILE' : 'MY_PROFILE')}</label>
        {prfile_Id &&
          user?.user?.email !== userDataProfile?.email &&
          hasPermission('USERS_MANAGE') &&
          checkRoleTier(loggedInUserRole, userDataProfile?.role) && <Menu menuList={getMenuList()} />}
      </div>
      <div className="pxy-6 flex-column flex-1 overflow-scroll border-bottom">
        <div className="flex items-center">
          {isProfileUploading ? (
            <div className="loader-image flex items-center justify-center">
              <Loading />
            </div>
          ) : imageData?.url ? (
            imageContent
          ) : userDataProfile?.avatar?.url ? (
            imageAvater
          ) : (
            emptyImageContent
          )}
          {/* {imageData?.url ? imageContent : userDataProfile?.avatar?.url ? imageAvater : emptyImageContent} */}
          <div className="flex-column flex-1" style={{ maxWidth: 'calc(100% - 80px)' }}>
            {nameField}
            <span className="regular-text grey-text font-12 mt-1">{userDataProfile?.role?.name}</span>
          </div>
        </div>
        <div className="flex-column mt-6">
          <div className="flex-column row-gap-1">
            <label className="normal-text font-12 grey-text">{t('CONTACT')}</label>
            {emailField}
            {mobileEditField}
          </div>
        </div>
        <div className={`flex mt-6 ${updateUserDetail?.type === 'code' ? 'mb-6' : ''}`}>
          <div className="flex-1 flex-column">
            <span className="regular-text grey-text font-12">{t('STATUS')}</span>
            <div className={`flex items-center status-container mt-1`}>
              <Status
                withDot
                withDottedBorder
                status={userDataProfile?.status}
                statusText={capitalize(formatText(userDataProfile?.status))}
              />
            </div>
          </div>
          {userDataProfile?.employee_number && (
            <div className="flex-1 flex-column mr-4">
              <span className="regular-text grey-text font-12 mb-1">{t('EMPLOYEE_NUMBER')}</span>
              <span className="regular-text font-14">{userDataProfile?.employee_number}</span>
            </div>
          )}
          {userDataProfile?.badge_number && (
            <div className="flex-1 flex-column mr-4">
              <span className="regular-text grey-text font-12 mb-1">{t('BADGE_NUMBER')}</span>
              <span className="regular-text font-14">{userDataProfile?.badge_number}</span>
            </div>
          )}
        </div>
      </div>
      {(!prfile_Id || user?.user?.email === userDataProfile?.email) && (
        <div className="pxy-6 flex-column flex-1 overflow-scroll ">
          <div className="lighter-text font-14 medium-text">{t('MFA_CONFIGURATION')}</div>
          <div className="pt-4 flex-column gap-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center mfa-radio-button">
                <input
                  type="radio"
                  name="gender"
                  value="EMAIL"
                  checked={mfaConfig === 'EMAIL'}
                  onChange={e =>
                    handleChangeMfa(
                      e,
                      mfaConfigDefault?.some(config => config.type === 'EMAIL'),
                    )
                  }
                  className={`selected-radio`}
                />
                <label className="pl-2 font-14 regular-text">Email</label>
              </div>
            </div>
            {mfaConfig === 'EMAIL' && mfaConfigDefault && !mfaConfigDefault.some(config => config.type === 'EMAIL') && (
              <div className="">{FieldInputEmailMFA}</div>
            )}
            <div className="flex items-center justify-between">
              <div className="flex items-center mfa-radio-button">
                <input
                  type="radio"
                  name="gender"
                  value="SMS"
                  checked={mfaConfig === 'SMS'}
                  onChange={e =>
                    handleChangeMfa(
                      e,
                      mfaConfigDefault?.some(config => config.type === 'SMS'),
                    )
                  }
                  className={`selected-radio`}
                />
                <label className="pl-2 font-14 regular-text">{t('MOBILE')}</label>
              </div>
            </div>
            {mfaConfig === 'SMS' && mfaConfigDefault && !mfaConfigDefault.some(config => config.type === 'SMS') && (
              <div className="">{FieldInputMobileMFA}</div>
            )}
          </div>
        </div>
      )}
    </PrizeDrawDetailLeftWrapper>
  );
};

export default userLeft;

import React from 'react';
import { ReactComponent as AlignCenterIcon } from '../../../assets/images/align-center.svg';
import { ReactComponent as AlignJustifyIcon } from '../../../assets/images/align-justify.svg';
import { ReactComponent as AlignLeftIcon } from '../../../assets/images/align-left.svg';
import { ReactComponent as AlignRightIcon } from '../../../assets/images/align-right.svg';

export const AlignmentConfig = ({ component, setSelectedComponent = () => {} }) => {
  const { alignment } = component;

  const renderAlignmentType = (type, Icon, showRightBorder = true) => {
    return (
      <div
        className={`flex items-center justify-center cursor flex-1 py-2 ${showRightBorder && 'divider-right'} ${
          alignment === type ? 'zen-purple-text' : 'grey-text'
        }`}
        onClick={() => setSelectedComponent({ ...component, alignment: type })}>
        <Icon />
      </div>
    );
  };

  return (
    <div className="flex flex-1 divider-border radius-4 mt-6">
      {renderAlignmentType('LEFT', AlignLeftIcon)}
      {renderAlignmentType('RIGHT', AlignRightIcon)}
      {renderAlignmentType('CENTRE', AlignCenterIcon)}
      {renderAlignmentType('JUSTIFY', AlignJustifyIcon, false)}
    </div>
  );
};

import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import uuid from 'react-uuid';
import { initModal } from '../constant/InitialData';
import { OrganisationContext } from '../context/organisationContext';
import { duplicateFormVersion, updateFormVersionToRetire } from '../store/features/formsSlice';
import { addToast } from '../store/features/toastSlice';
import CommonPopup from './common-popup';
import { FormVersionActionsWrapper } from './modal.styled';

const FormVersionAction = () => {
  const dispatch = useDispatch();
  const { modal, setModal } = useContext(OrganisationContext);
  const { title, isDuplicate, isRetire, form, formVersion, onSuccess = () => {} } = modal.content;

  const [loading, setLoading] = useState(false);

  const onConfirmAction = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    if (isRetire) {
      dispatch(updateFormVersionToRetire({ formId: form.id, versionId: formVersion.id }))
        .then(() => {
          dispatch(addToast({ error: false, text: `V${formVersion?.version_no} has been retired`, id: uuid() }));
          onSuccess();
        })
        .catch(() => {
          dispatch(addToast({ error: true, text: `V${formVersion?.version_no} has not been retired`, id: uuid() }));
        })
        .finally(() => setModal(initModal));
    } else if (isDuplicate) {
      dispatch(duplicateFormVersion({ formId: form.id, versionId: formVersion.id }))
        .then(() => {
          dispatch(addToast({ error: false, text: `V${formVersion?.version_no} has been duplicated`, id: uuid() }));
          onSuccess();
        })
        .catch(() => {
          dispatch(addToast({ error: true, text: `V${formVersion?.version_no} has not been duplicated`, id: uuid() }));
        })
        .finally(() => setModal(initModal));
    }
  };

  return (
    <CommonPopup
      popupTitle={title}
      confirmButtonProps={{
        label: isDuplicate ? 'Duplicate' : isRetire ? 'Yes, retire!' : 'Confirm',
        className: isRetire ? 'negative' : 'primary',
      }}
      disabled={loading}
      onCancel={() => setModal(initModal)}
      onConfirm={onConfirmAction}>
      <FormVersionActionsWrapper className="flex-column items-start w-full pxy-4 radius-4 mt-6">
        <div className="flex items-start w-full">
          <div className="flex-column items- start flex-1">
            <label className="semibold-text font-16 fw-600">
              {form?.name} <span className="bold-text font-16 ml-2 zen-purple-text">V{formVersion?.version_no}</span>
            </label>
            <label className="regular-text lighter-text font-12 mt-2">
              {form?.prizedraw_client?.prizedraw_client_code}
            </label>
          </div>
          {form?.prizedraw?.image && (
            <div className="flex items-center h-full">
              <img className="form-image" src={form?.prizedraw?.image?.url} alt="form-image" />
            </div>
          )}
        </div>
        <div className="normal-text grey-text mt-4">{form?.description}</div>
      </FormVersionActionsWrapper>
    </CommonPopup>
  );
};

export default FormVersionAction;

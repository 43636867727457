import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ReactComponent as EditNoteIcon } from '../../../../assets/images/edit-note.svg';
import { OrganisationContext } from '../../../../context/organisationContext';
import { FormVersionLogWrapper } from '../../../../styles/components/formDetails/form-details.styled';
import Button from '../../../Elements/button/button';
import FormVersionDetailsHeader from '../form-version-details-header';

const FormVersionLog = () => {
  const { id, versionId } = useParams();
  const { setModal, hasPermission } = useContext(OrganisationContext);
  const { formDetails, formVersionDetails } = useSelector(state => state.forms);
  const { created_on, published_on, archived_on } = formVersionDetails || {};

  const handleFormEdit = () => {
    setModal({
      type: 'edit-form-version',
      content: {
        formId: id,
        formVersionId: versionId,
        prizedraw_client: formDetails?.prizedraw_client,
      },
    });
  };

  return (
    <FormVersionLogWrapper className="flex-column items-start justify-start w-full h-full">
      <FormVersionDetailsHeader
        title={'Share options'}
        actionContent={
          hasPermission('FORMS_CONFIGURE') ? (
            <div className="flex">
              <Button
                className="default-secondary"
                label={'Form editor'}
                size={'medium'}
                icon={<EditNoteIcon height={16} width={16} />}
                onClick={() => handleFormEdit()}
                disabled={created_on && published_on && !archived_on}
              />
            </div>
          ) : (
            <></>
          )
        }
      />
    </FormVersionLogWrapper>
  );
};

export default FormVersionLog;

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { addToast } from '../../../store/features/toastSlice';
import CommonPopup from '../../common-popup/index';

const AddLink = ({ modal, setModal }) => {
  const dispatch = useDispatch();
  const { onSuccess } = modal.content || {};

  const [linkData, setLinkData] = useState({ text: '', link: '' });
  const [error, setError] = useState({ text: '', link: '' });

  const { text, link } = linkData;

  const checkError = () => {
    if (!text?.trim() || !link?.trim()) {
      setError({
        link: !link?.trim(),
        text: !text?.trim(),
      });
      dispatch(addToast({ error: true, text: 'Please fill text and link.' }));
      return false;
    } else {
      return true;
    }
  };

  const onDone = () => {
    if (!checkError()) {
      return;
    }
    const formattedLink = `<a href=${link} target="_blank" rel="noopener noreferrer">${text}</a>`;
    onSuccess && onSuccess(formattedLink);
    setModal(null);
  };

  return (
    <CommonPopup
      popupTitle={'Add Link'}
      confirmButtonProps={{ label: 'Add' }}
      onCancel={() => setModal(null)}
      disabled={!text?.trim() || !link?.trim()}
      onConfirm={() => onDone()}>
      <AddLinkWrapper className="flex flex-column row-gap-6 mt-6 w-full">
        <div className="flex-column w-full">
          <label className="regular-text lighter-text font-12">Text</label>
          <input
            className={`input ${error?.text && 'error-border'}`}
            placeholder="Official rules"
            value={text}
            onChange={({ target: { value } }) => {
              setLinkData({ ...linkData, text: value });
              setError({ ...error, text: false });
            }}
          />
        </div>
        <div className="flex-column w-full">
          <label className="regular-text lighter-text font-12">Link</label>
          <input
            className={`input ${error?.link && 'error-border'}`}
            placeholder="https://"
            value={link}
            onChange={({ target: { value } }) => {
              setLinkData({ ...linkData, link: value });
              setError({ ...error, link: false });
            }}
          />
        </div>
      </AddLinkWrapper>
    </CommonPopup>
  );
};

const AddLinkWrapper = styled.div`
  .input {
    height: 40px;
  }
`;

export default AddLink;

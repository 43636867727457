import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { getAddressDetails, getAddressList } from '../../../store/features/groupsSlice';

import Dropdown from '../dropdown/index';

const Address = ({
  name,
  is_required,
  placeholder,
  font = null,
  fontFamily,
  showRequiredError,
  selectedValue,
  onAddressUpdate = () => {},
  sub_label = null,
}) => {
  const dispatch = useDispatch();

  const [inputValue, setInputValue] = useState(null);

  const loadOptions = inputValue => {
    return dispatch(getAddressList({ params: { search: inputValue } }))
      .then(data => {
        return data.map(d => ({ ...d, label: d.address, value: d.id }));
      })
      .catch(e => {
        return [];
      });
  };

  const onInputChange = (text, { action }) => action === 'input-change' && setInputValue(text);

  const onAddressSelect = address => {
    dispatch(getAddressDetails({ id: address.id }))
      .then(data => {
        onAddressUpdate(data);
      })
      .catch(e => {
        return '';
      });
  };

  return (
    <AddressWrapper className="flex items-start">
      <Dropdown
        name={name}
        is_required={is_required}
        placeholder={placeholder}
        font={font}
        fontFamily={fontFamily}
        isSearchable={true}
        showRequiredError={showRequiredError}
        loadOptions={loadOptions}
        onInputChange={onInputChange}
        inputValue={inputValue}
        onChange={onAddressSelect}
        selectedValue={selectedValue ? { label: selectedValue } : null}
        sub_label={sub_label}
      />
    </AddressWrapper>
  );
};

const AddressWrapper = styled('div')``;

export default Address;

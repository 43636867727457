import { createSlice } from '@reduxjs/toolkit';
import api from '../services';

export const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    user: JSON.parse(localStorage.getItem('user')),
  },
  reducers: {
    setUserDataProfile: (state, { payload }) => {
      state.userDataProfile = payload;
    },
    setConfigMaf: (state, { payload }) => {
      state.mfaConfigration = payload;
    },
    setRequiredFields: (state, { payload }) => {
      state.requiredFields = payload;
    },
    setUserImage: (state, { payload }) => {
      state.userImage = payload;
    },
  },
});

export const getDetailsProfileUser = payload => async dispatch => {
  const userDetails = JSON.parse(localStorage.getItem('user'));
  const userId = payload.id ? payload?.id : userDetails?.user?.id;
  try {
    const { data } = await api.get(`/users/${userId}`);
    dispatch(setUserDataProfile(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getMFAConfigurationDetails = payload => async dispatch => {
  try {
    const { data } = await api.get(`/users/multifactor`);
    dispatch(setConfigMaf(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getRequiredField = payload => async dispatch => {
  const userDetails = JSON.parse(localStorage.getItem('user'));
  const rolesId = userDetails.organizations[0]?.role?.id;
  const organizationId = userDetails.organizations[0].id;
  try {
    const { data } = await api.get(`/organizations/${organizationId}/roles/${rolesId}/required_fields`);
    setRequiredFields(data);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateprofileData = payload => async dispatch => {
  try {
    const { data } = await api.put(`/users/profile`, payload);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const verifyCode = payload => async dispatch => {
  try {
    const { data } = await api.post(`/users/profile/confirm`, payload);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateProfile = payload => async dispatch => {
  const { id, request } = payload;
  try {
    const { data } = await api.put(`/users/${id}`, request);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const userMfa = payload => async dispatch => {
  try {
    const { data } = await api.post(`/users/multifactor`, payload);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const userImage = payload => async dispatch => {
  try {
    dispatch(setUserImage(payload));
    return Promise.resolve(true);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const authenticateMFACpde = payload => async dispatch => {
  const { id, request } = payload;
  try {
    const { data } = await api.post(`/users/multifactor/${id}/confirm`, request);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateMFA = payload => async dispatch => {
  const { id, request } = payload;
  try {
    const { data } = await api.put(`/users/multifactor/${id}`, request);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const userConsent = payload => async dispatch => {
  try {
    const { request } = payload;
    const { data } = await api.put(`/users/consent`, request);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const { setUserDataProfile, setConfigMaf, setRequiredFields, setUserImage } = profileSlice.actions;
export default profileSlice.reducer;

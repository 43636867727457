import styled from 'styled-components';

const ToastWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: 24px;
  z-index: 50000;

  .toast {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    box-shadow: 2px 8px 30px rgba(5, 49, 73, 0.1);
    border-radius: 8px;
    min-height: 64px;
    min-width: 340px;
    user-select: none;
    margin-bottom: 8px;

    .toast-left {
      display: flex;
      flex-direction: row;

      .toast-color {
        width: 8px;
        min-height: 64px;
        background: #32e5c5;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      .toast-cotent {
        display: flex;
        flex-direction: column;
        margin-left: 16px;
        margin-right: 8px;
        padding: 4px 0;
        justify-content: center;

        .medium-text {
          font-size: 16px;
        }

        .regular-text {
          font-size: 12px;
          margin-top: 4px;
        }
      }
    }

    .close-btn {
      margin-right: 16px;
      margin-left: 24px;
      cursor: pointer;
    }
  }

  .fade-enter {
    opacity: 0.01;
  }

  .fade-enter-active {
    opacity: 1;
    transition: opacity 200ms ease-in;
  }

  .fade-exit {
    opacity: 1;
  }

  .fade-exit-active {
    opacity: 0.01;
    transition: opacity 200ms ease-in;
  }
`;
export default ToastWrapper;

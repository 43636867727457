import React from 'react';
import uuid from 'react-uuid';
import {
  drawStatusFilter,
  paymentFrequencyFilter,
  productPaymentStatusFilter,
} from '../../constant/filter-option-data';
import { capitalize, formatText } from '../../helpers/utils';
import DropDown from '../McDropdown';

const Item = ({ filter, setFilter }) => {
  const prizeDrawStatusFilter = [
    { id: '', name: 'ALL' },
    { id: 'ACTIVE', name: 'ACTIVE' },
    { id: 'INACTIVE', name: 'INACTIVE' },
  ];

  const userStatusFilter = [
    { id: '', name: 'All' },
    { id: 'ACTIVE', name: 'ACTIVE' },
    { id: 'INACTIVE', name: 'INACTIVE' },
    { id: 'INVITED', name: 'INVITED' },
    { id: 'EXPIRED', name: 'EXPIRED' },
  ];

  const prizeDrawFormFilter = [
    { id: '', name: 'ALL' },
    { id: 'DEFAULT', name: 'DEFAULT' },
    { id: 'CUSTOM', name: 'CUSTOM' },
  ];

  const getFilterProps = object => {
    if (object === 'STATUS') return { id: uuid(), name: 'STATUS', param: 'lifecycle', type: object };
    else if (object === 'OTHER') return { id: uuid(), name: 'OTHER', param: 'other', type: object };
    else if (object === 'TYPE') return { id: uuid(), name: 'TYPE', param: 'type', type: object };
    else if (object === 'PAYMENT_STATUS')
      return { id: uuid(), name: 'PAYMENT_STATUS', param: 'product_status', type: object };
    else if (object === 'DRAW_STATUS') return { id: uuid(), name: 'DRAW_STATUS', param: 'draw_status', type: object };
    else if (object === 'PRODUCT_FREQUENCY')
      return { id: uuid(), name: 'PRODUCT_FREQUENCY', param: 'product_frequency', type: object };
    else if (object === 'WIN_STATUS') return { id: uuid(), name: 'WIN_STATUS', param: 'win_status', type: object };
    else if (object === 'USER_STATUS') return { id: uuid(), name: 'STATUS', param: 'status', type: object };
    else return {};
  };

  const getValues = () => {
    if (filter.object.type === 'STATUS') return prizeDrawStatusFilter;
    else if (filter.object.type === 'TYPE') return prizeDrawFormFilter;
    else if (filter.object.type === 'PAYMENT_STATUS') return productPaymentStatusFilter;
    else if (filter.object.type === 'DRAW_STATUS') return drawStatusFilter;
    else if (filter.object.type === 'PRODUCT_FREQUENCY') return paymentFrequencyFilter;
    else if (filter.object.type === 'WIN_STATUS') return paymentFrequencyFilter;
    else if (filter.object.type === 'USER_STATUS') return userStatusFilter;
  };

  return (
    <div className="flex-column pxy-4 border-bottom">
      <DropDown
        options={filter.objects?.map(o => getFilterProps(o))}
        selected={filter.objects?.map(o => getFilterProps(o)).find(o => o.type === filter.object?.type)}
        setSelected={o => {
          setFilter({ ...filter, object: o, value: null });
        }}
        placeholder="Select object"
        allowTranslateOption
      />
      {filter.object && (
        <div className="flex-column  mt-4">
          <div className="flex-column">
            <DropDown
              options={getValues()}
              selected={filter.value}
              setSelected={o => setFilter({ ...filter, value: o })}
              placeholder={`Select ${capitalize(formatText(filter.object.name, ' '))}`}
              allowTranslateOption
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Item;

import React from 'react';
import { useCountdown } from '../../../helpers/countdown';

const DrawCountDown = ({ targetDate }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  return (
    <div className="flex items-center justify-center draw-count-down px-6 radius-3">
      <label className=" green-positive-text normal-text "> Countdown to next draw :</label>
      <label className=" green-positive-text normal-text ml-4"> {`${days}d`} </label>
      <label className=" green-positive-text normal-text ml-4"> :</label>
      <label className=" green-positive-text normal-text ml-4"> {`${hours}h`} </label>
      <label className=" green-positive-text normal-text ml-4"> :</label>
      <label className=" green-positive-text normal-text ml-4"> {`${minutes}m`} </label>
      <label className=" green-positive-text normal-text ml-4"> :</label>
      <label className=" green-positive-text normal-text ml-4"> {`${seconds}s`} </label>
    </div>
  );
};

export default DrawCountDown;

import React, { Fragment, useContext, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ReactComponent as AlignCenterIcon } from '../../../assets/images/align-center.svg';
import { ReactComponent as AlignLeftIcon } from '../../../assets/images/align-left.svg';
import { ReactComponent as AlignRightIcon } from '../../../assets/images/align-right.svg';
import { ReactComponent as LoadingIcon } from '../../../assets/images/loading.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/images/trash-red.svg';
import Button from '../../../components/Elements/button/button';
import DropDown from '../../../components/McDropdown';
import Switch from '../../../components/common/switch';
import FormBuilderContext from '../../../context/FormBuilderContext';
import { addToast } from '../../../store/features/toastSlice';
import { uploadMedia } from '../../../store/services/media';

const displayOnOptions = [
  { name: 'First page', value: 'FIRSTPAGE' },
  { name: 'Last page', value: 'LASTPAGE' },
  { name: 'All pages', value: 'ALLPAGES' },
];

export const WaterMarkSettings = () => {
  const { formAttributes, setFormAttributes } = useContext(FormBuilderContext);
  const { watermark } = formAttributes;
  const { display_watermark, alignment, watermark_location, image } = watermark || {};

  const dispatch = useDispatch();
  const inputRef = useRef();

  const [uploadingImage, setUploadingImage] = useState(false);

  const onDisplayWatermarkChange = () => {
    const watermark_defaults = display_watermark ? { alignment: null, watermark_location: null, image: null } : {};
    setFormAttributes({
      ...formAttributes,
      watermark: { ...formAttributes.watermark, display_watermark: !display_watermark, ...watermark_defaults },
    });
  };

  const renderAlignmentType = (type, Icon, showRightBorder = true) => {
    return (
      <div
        className={`flex items-center justify-center cursor flex-1 py-2 ${showRightBorder && 'divider-right'} ${
          alignment === type ? 'zen-purple-text' : 'grey-text'
        }`}
        onClick={() =>
          setFormAttributes({
            ...formAttributes,
            watermark: { ...formAttributes.watermark, alignment: type },
          })
        }>
        <Icon />
      </div>
    );
  };

  const onImageUpload = async file => {
    if (file) {
      try {
        setUploadingImage(true);
        const imageData = await uploadMedia(file);
        setFormAttributes({
          ...formAttributes,
          watermark: {
            ...formAttributes.watermark,
            image: {
              image: { url: imageData.url, id: imageData?.media_external_id },
              name: file.name,
              size: file.size,
            },
          },
        });
        setUploadingImage(false);
        inputRef.current.value = '';
      } catch (error) {
        dispatch(addToast({ error: true, text: 'Error while uploading image' }));
        setUploadingImage(false);
        inputRef.current.value = '';
      }
    }
  };

  return (
    <div className="px-4 py-8 flex-column border-bottom">
      <label className="medium-text">WATERMARK</label>
      <div className="flex mt-6">
        <label className="regular-text flex-1">Display watermark</label>
        <Switch enabled={display_watermark} onClick={onDisplayWatermarkChange} />
      </div>
      {display_watermark && (
        <Fragment>
          <div className="flex mt-6">
            <label className="regular-text flex-1">Display on</label>
            <DropDown
              className="flex-1"
              options={displayOnOptions}
              selected={displayOnOptions.find(doOption => doOption.value === watermark_location)}
              placeholder="Location"
              setSelected={option =>
                setFormAttributes({
                  ...formAttributes,
                  watermark: { ...formAttributes.watermark, watermark_location: option.value },
                })
              }
            />
          </div>
          <div className="flex-column justify-center mt-4">
            <label className="flex regular-text font-12 flex-1 mb-2">Image</label>
            {image?.image?.url ? (
              <div className="flex">
                <div className="flex-column flex-1">
                  {image.name && <label className="regular-text">{image.name}</label>}
                  {image.size && (
                    <label className="regular-text grey-text">{`${parseFloat(parseInt(image.size) / 1024).toFixed(
                      2,
                    )} kb`}</label>
                  )}
                </div>
                <div
                  className="pxy-1 radius-2 flex delete-widget h-fit-content cursor"
                  onClick={() =>
                    setFormAttributes({
                      ...formAttributes,
                      watermark: { ...formAttributes.watermark, image: null },
                    })
                  }>
                  <DeleteIcon width={14} height={14} />
                </div>
              </div>
            ) : (
              <div className="flex items-center mt-2 col-gap-4">
                <input
                  className="display-none"
                  type="file"
                  ref={inputRef}
                  onChange={e => {
                    const file = e.target.files[0];
                    onImageUpload(file);
                  }}
                />
                <Button
                  icon={uploadingImage ? <LoadingIcon height={14} /> : <Fragment />}
                  label={'Upload image'}
                  size="medium"
                  borderRadius="12px"
                  className="primary-white flex-1"
                  onClick={() => !uploadingImage && inputRef.current.click()}
                />
                <label className="flex-1 regular-text font-12">SVG, PNG, JPG or GIF (max. 000x000px)</label>
              </div>
            )}
          </div>
          <div className="flex flex-1 divider-border radius-4 mt-6">
            {renderAlignmentType('LEFT', AlignLeftIcon)}
            {renderAlignmentType('RIGHT', AlignRightIcon)}
            {renderAlignmentType('CENTER', AlignCenterIcon, false)}
          </div>
        </Fragment>
      )}
    </div>
  );
};

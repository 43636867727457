import isEquals from 'lodash.isequal';
import { getItemFromLocalStorage } from './localstorage';

const fontStyles = {
  THIN: { fontWeight: 100 },
  EXTRALIGHT: { fontWeight: 200 },
  LIGHT: { fontWeight: 300 },
  REGULAR: { fontWeight: 400 },
  MEDIUM: { fontWeight: 500 },
  SEMIBOLD: { fontWeight: 600 },
  BOLD: { fontWeight: 700 },
  EXTRABOLD: { fontWeight: 800 },
  BLACK: { fontWeight: 900 },
  THINITALIC: { fontWeight: 100, fontStyle: 'italic' },
  EXTRALIGHTITALIC: { fontWeight: 200, fontStyle: 'italic' },
  LIGHTITALIC: { fontWeight: 300, fontStyle: 'italic' },
  ITALIC: { fontWeight: 400, fontStyle: 'italic' },
  MEDIUMITALIC: { fontWeight: 500, fontStyle: 'italic' },
  SEMIBOLDITALIC: { fontWeight: 600, fontStyle: 'italic' },
  BOLDITALIC: { fontWeight: 700, fontStyle: 'italic' },
  EXTRABOLDITALIC: { fontWeight: 800, fontStyle: 'italic' },
  BLACKITALIC: { fontWeight: 900, fontStyle: 'italic' },
};

export const capitalize = str => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};
export function capitalizeFirstLetterOfWords(str) {
  return str.replace(/\b\w/g, char => char.toUpperCase());
}

export const formatText = (text, replaceWith = '-') => {
  if (!text) return '';
  return text.toLowerCase().replaceAll('_', replaceWith);
};

export const uppercase = str => {
  if (!str) return '';
  return str.toUpperCase();
};

export const lowerCase = str => {
  if (!str) return '';
  return str.toLowerCase();
};

export const camelCase = str => {
  let ans = str.toLowerCase();
  return ans.split(' ').reduce((s, c) => `${s} ` + (c.charAt(0).toUpperCase() + c.slice(1)));
};

export const replaceTextSymbol = (text, replaceString = '-', replaceWith = '_') => {
  if (!text) return '';
  return text.replaceAll(replaceString, replaceWith);
};

export const getUsersPermissions = () => {
  const user = getItemFromLocalStorage('user');
  return user?.api_tokens?.permissions || [];
};

export const getUserInitials = user => {
  const { forename, surname, name } = user;
  const userName = name?.split(' ') || null;

  return userName
    ? `${userName?.[0]?.charAt(0)?.toUpperCase() || ''}${userName?.[1]?.charAt(0)?.toUpperCase() || ''}`
    : forename || surname
    ? `${forename?.charAt(0)?.toUpperCase() || ''}${surname?.charAt(0)?.toUpperCase() || ''}`
    : '';
};

export const getInitialsSingleChar = user => {
  const { forename, surname, name } = user;
  const userName = name?.split(' ') || null;

  return userName
    ? `${userName?.[0]?.charAt(0)?.toUpperCase() || ''}`
    : forename
    ? `${forename?.charAt(0)?.toUpperCase() || ''}`
    : '';
};

export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
};

export const getContactValue = (contacts, contactType) => {
  const contact = contacts?.find(item => item?.contact_type === contactType);
  return contact?.contact_value || 'Not provided';
};

export const getStatus = (startDate, endDate) => {
  const currentDate = Date.now();

  if (startDate > currentDate) {
    return 'PENDING';
  } else {
    return 'CURRENT';
  }
};

export const getCurrencySymbol = currency => {
  switch (currency) {
    case 'USD':
    case 'usd':
      return '$';
    case 'GBP':
    case 'gbp':
      return '£';
    case 'EUR':
    case 'eur':
      return '€';
    default:
      return '$';
  }
};

export const getRecurringPeriod = period => {
  switch (period) {
    case 'YEAR':
      return 'Yearly';
    case 'MONTH':
      return 'Monthly';
    case 'WEEK':
      return 'Weekly';
    case 'DAY':
      return 'Daily';
  }
};

export const emailRegEx = /^[A-Za-z0-9][-A-Z-a-z0-9.!#$%&'*+-=?^_`{|}~\/]+@([-A-Z-a-z0-9]+\.)+[A-Za-z]{2,5}$/;
export const onlyNumbers = /^\d+$/;

export const getPaymentFrequencyText = data => {
  switch (data) {
    case 'YEAR':
      return 'Annually';
    case 'MONTH':
      return 'Monthly';
    case 'QUARTER':
      return 'Quarterly';
    case 'SINGLE':
      return 'Single';
    case 'DAY':
      return 'Daily';
    default:
      return '';
  }
};

export const getFontFamily = (font_thickness, font_name) => {
  const fontStyle = fontStyles[font_thickness] || fontStyles['REGULAR'];
  return { ...fontStyle, fontFamily: font_name || 'Poppins' };
};

export const getAlignment = alignment => {
  switch (alignment) {
    case 'LEFT':
      return { justifyContent: 'flex-start', textAlign: 'start' };
    case 'RIGHT':
      return { justifyContent: 'flex-end', textAlign: 'end' };
    case 'CENTRE':
      return { justifyContent: 'center', textAlign: 'center' };
    default:
      return { justifyContent: 'center', textAlign: 'unset' };
  }
};

export const handleCopyClick = async url => {
  try {
    await navigator.clipboard.writeText(url);
    return true;
  } catch (error) {
    console.error('Failed to copy:', error);
    return false;
  }
};

export const getPerformedByFromLog = (log = {}) => {
  const { performed_by } = log;
  const { user, api_key } = performed_by || {};
  return user?.employee_number || user?.id || api_key?.id || api_key?.name;
};

export const isDataChanged = (inputType, newValue, oldValue, confirmAction = () => {}, removeAction = () => {}) => {
  switch (inputType) {
    case 'INPUT': {
      if (newValue !== oldValue) {
        confirmAction();
        return true;
      } else {
        removeAction();
        return false;
      }
    }
    case 'DROPDOWN': {
      if (newValue?.id !== oldValue?.id) {
        confirmAction();
        return true;
      } else {
        removeAction();
        return false;
      }
    }
    case 'ADDRESS': {
      const { line1, line2, city, state, postcode } = newValue;
      const { line1: oldLine1, line2: oldLine2, city: oldCity, state: oldState, postcode: oldPostCode } = oldValue;
      if (
        !isEquals(line1, oldLine1) ||
        !isEquals(line2, oldLine2) ||
        !isEquals(city, oldCity) ||
        !isEquals(state, oldState) ||
        !isEquals(postcode, oldPostCode)
      ) {
        confirmAction();
        return true;
      } else {
        removeAction();
        return false;
      }
    }
    case 'MULTI_DROPDOWN': {
      const newValueIds = newValue.map(v => v.id);
      const oldValueIds = oldValue.map(v => v.id);
      if (!isEquals(newValueIds, oldValueIds)) {
        confirmAction();
        return true;
      } else {
        removeAction();
        return false;
      }
    }
    case 'MULTI_VALUE': {
      if (!isEquals(newValue, oldValue)) {
        confirmAction();
        return true;
      } else {
        removeAction();
        return false;
      }
    }
    case 'PRIZES': {
      const newValuePrizes = newValue.map(v => ({ amount: v.amount, entries: v.entries }));
      const oldValuePrizes = oldValue.map(v => ({ amount: v.amount, entries: v.entries }));
      if (!isEquals(newValuePrizes, oldValuePrizes)) {
        confirmAction();
        return true;
      } else {
        removeAction();
        return false;
      }
    }
    default:
      confirmAction();
      return true;
  }
};

export const isJson = str => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const characterLimitRangeError = character_limit => {
  return character_limit?.min && character_limit.max
    ? `Please enter characters in between ${character_limit.min} and ${character_limit.max}`
    : character_limit?.min && !character_limit?.max
    ? `Please enter characters greater than ${character_limit.min}`
    : !character_limit?.min && character_limit?.max
    ? `Please enter characters less than ${character_limit.max}`
    : '';
};

export const validateCharacterLimit = (character_limit, value) => {
  if (character_limit?.min && character_limit?.max) {
    return value.length >= character_limit.min && value.length <= character_limit.max;
  } else if (character_limit?.min && !character_limit?.max) {
    return value.length >= character_limit.min;
  } else if (!character_limit?.min && character_limit?.max) {
    return value.length <= character_limit.max;
  }
  return true;
};

export const getErrorDescription = (error, defaultErrorText = '') => {
  if (!error) return '';
  return error?.response?.data?.error_description || defaultErrorText;
};

export const checkRoleTier = (currentUserRole, otherUserRole) => {
  const { tier, is_superuser } = currentUserRole || {};
  const { tier: otherTier } = otherUserRole || {};

  if (is_superuser) return true;
  if (!tier || !otherTier) return false;
  return tier < otherTier;
};

export const countMatchesInRegex = (regex, str) => {
  console.log(`MATCH ${regex}`, ((str || '').match(regex) || []).length);
  return ((str || '').match(regex) || []).length;
};

export const operatorOptions = [
  {
    id: 'EQ',
    name: '=',
    value: 'EQ',
  },
  {
    id: 'LTE',
    name: '<=',
    value: 'LTE',
  },
  {
    id: 'GTE',
    name: '>=',
    value: 'GTE',
  },
  {
    id: 'LT',
    name: '<',
    value: 'LT',
  },
  {
    id: 'GT',
    name: '>',
    value: 'GT',
  },
];

export const getFormattedNumber = (
  number,
  currency,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2,
  style = 'currency',
) => {
  // Create our number formatter.
  const formatter = new Intl.NumberFormat('en-US', {
    style: style,
    currency: currency || 'GBP',
    minimumFractionDigits: minimumFractionDigits, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: maximumFractionDigits, // (causes 2500.99 to be printed as $2,501)
  });
  return formatter.format(number);
};

export const getFormattedNumberStyle = (
  number,
  style = 'currency',
  minimumFractionDigits,
  maximumFractionDigits,
  ...rest
) => {
  // Create our number formatter.
  const formatter = new Intl.NumberFormat('en-US', {
    style: style,
    minimumFractionDigits, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits, // (causes 2500.99 to be printed as $2,501)
    ...rest,
  });
  return formatter.format(number);
};

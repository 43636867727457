import React from 'react';
import { CSSTransition } from 'react-transition-group';
import Button from '../../components/Elements/button/button';
import CommonPopupWrapper from './common-popup.styled';

const defaultProps = {
  modalClassName: '',
  onConfirm: () => {},
  onCancel: () => {},
  disabled: false,
  cancelButtonProps: {
    label: 'Cancel',
    size: 'large',
    borderRadius: '16px',
    width: '100%',
    className: 'primary-white',
  },
  confirmButtonProps: {
    label: 'Confirm',
    size: 'large',
    borderRadius: '16px',
    width: '100%',
    className: 'primary',
  },
  popupTitle: '',
  popupSubTitle: '',
};

const CommonPopup = ({
  modalClassName = defaultProps.modalClassName,
  children,
  disabled = defaultProps.disabled,
  onConfirm = defaultProps.onConfirm,
  onCancel = defaultProps.onCancel,
  cancelButtonProps = defaultProps.cancelButtonProps,
  confirmButtonProps = defaultProps.confirmButtonProps,
  popupTitle = defaultProps.popupTitle,
  popupSubTitle = defaultProps.popupSubTitle,
  onlyConfirmButtonDisable = false,
}) => {
  const cancelButtonStyleProps = { ...defaultProps.cancelButtonProps, ...cancelButtonProps };
  const confirmButtonStyleProps = { ...defaultProps.confirmButtonProps, ...confirmButtonProps };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <CommonPopupWrapper className={modalClassName}>
        <label className="bold-text font-24 text-center mb-2">{popupTitle}</label>
        {popupSubTitle && <label className="regular-text font-14 text-center my-2 grey-text">{popupSubTitle}</label>}
        {children}
        <div className="flex justify-center w-full mt-10 col-gap-6">
          <Button {...cancelButtonStyleProps} disabled={!onlyConfirmButtonDisable && disabled} onClick={onCancel} />
          <Button {...confirmButtonStyleProps} disabled={disabled} onClick={onConfirm} />
        </div>
      </CommonPopupWrapper>
    </CSSTransition>
  );
};

export default CommonPopup;

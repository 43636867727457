import styled from 'styled-components';

export const MultipleFilterStyledWrapper = styled.div`
    width: 272px;
    z-index: 3;
    top: 50px;
    left: -95px;

    &::before {
      content: '';
      background: white;
      width: 10px;
      height: 10px;
      transform: translate(-50%, -50%) rotate(45deg);
      position: absolute;
      left: 50%;
      top: 0px;
    }

    .clear-all {
      border-radius: 8px;
      height: 24px;
      width: 72px;
    }

    .add-new-filter-btn {
      height: 32px;
      width: 100%;
      background: rgba(36, 203, 177, 0.1);
      border-radius: 10px;
    }

    .fromdate-container {
      .date-selector {
        top: 50px;
        left: 0px;
        width: 245px;
        padding: 20px 10px;
      }
    }

    .todate-container {
      .date-selector {
        top: 50px;
        right: 0px;
        width: 245px;
        padding: 20px 10px;
      }
    }
  }

  .remove-filter-icon {
    width: 24px;
    height: 24px;
    background: #f6f6f6;
    box-shadow: 2px 2px 15px rgba(5, 49, 73, 0.05);
    border-radius: 8px;
  }
`;

import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import uuid from 'react-uuid';
import { initModal } from '../constant/InitialData';
import { OrganisationContext } from '../context/organisationContext';
import { generateFormVersion } from '../store/features/formsSlice';
import { addToast } from '../store/features/toastSlice';
import CommonPopup from './common-popup';

const FormGenerateAction = () => {
  const dispatch = useDispatch();

  const { modal, setModal } = useContext(OrganisationContext);
  const { version, form, onSuccess = () => {} } = modal.content;
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const onConfirmAction = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    dispatch(generateFormVersion({ formId: form.id, versionId: version.id, body: JSON.parse(data) }))
      .then(response => {
        console.log(response);
        const file = new Blob([response], {
          type: 'application/pdf',
        });

        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        dispatch(addToast({ error: false, text: `From has been generated`, id: uuid() }));
      })
      .catch(() => {
        dispatch(addToast({ error: true, text: `Form has not been generated`, id: uuid() }));
      })
      .finally(() => setModal(initModal));
  };

  return (
    <CommonPopup
      popupTitle={'Generate version'}
      confirmButtonProps={{
        label: 'Generate',
        className: 'primary',
      }}
      disabled={loading}
      onCancel={() => setModal(initModal)}
      onConfirm={onConfirmAction}>
      <textarea
        className={`textarea w-full description-input`}
        onChange={e => {
          setData(e.target.value);
        }}
        placeholder="Enter event data here"
        rows={4}
        value={data || ''}
      />
    </CommonPopup>
  );
};

export default FormGenerateAction;

import React from 'react';
import { useTranslation } from 'react-i18next';
import DropDown from '../../McDropdown';
import { InputDropDownWrapper } from './inputDropDown.styled';
const InputDropDown = ({ options, selected, setSelected, inputValue, setInputValue }) => {
  const { t } = useTranslation();
  return (
    <InputDropDownWrapper className="flex flex-row gap-4">
      <div className="flex-column justify-start items-start w-50">
        <label className="lighter-text font-12 mb-2">{t('COUNT')}</label>
        <input
          className="input-field w-full px-4 font-14 regular-text"
          placeholder="Count"
          value={inputValue}
          onChange={({ target }) => {
            if (isNaN(target.value)) return;
            else setInputValue(target.value);
          }}
        />
      </div>
      <div className="flex-column justify-start items-start w-50">
        <label className="lighter-text font-12 mb-2">{t('INTERVAL')}</label>
        <DropDown
          className="provider-selector w-full"
          placeholder="Select"
          options={options}
          selected={selected}
          setSelected={setSelected}
        />
      </div>
    </InputDropDownWrapper>
  );
};

export default InputDropDown;

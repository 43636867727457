import React from 'react';
import { FormVersionDetailsRightWrapper } from '../../../styles/components/formDetails/form-details.styled';
import FormVersionLog from './form-version-log';

function FormVersionDetailsRight() {
  return (
    <FormVersionDetailsRightWrapper className="flex border flex-1 radius-4">
      <FormVersionLog />
    </FormVersionDetailsRightWrapper>
  );
}

export default FormVersionDetailsRight;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CommonPopup from '../../common-popup/index';
import { DeleteFormWidgetWrapper } from './index.styled';
const DeleteFormWidget = ({ modal, setModal }) => {
  const { widget, deleteWidget } = modal.content || {};
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const onDone = async () => {
    if (loading) {
      return;
    }
    try {
      setLoading(true);
      await deleteWidget();
      setModal(null);
      setLoading(false);
    } catch (e) {
      console.log('e', e);
      setLoading(false);
    }
  };
  return (
    <CommonPopup
      disabled={loading}
      popupTitle={'Are you sure you want to remove this widget?'}
      popupSubTitle={'Deleting this widget will also delete all components connected to the widget'}
      confirmButtonProps={{ label: 'Remove', className: 'negative' }}
      onCancel={() => setModal(null)}
      onConfirm={onDone}>
      <DeleteFormWidgetWrapper className="w-full radius-3 pxy-4 mt-6 flex-column">
        <label className="medium-text font-16">{widget.name}</label>
        {widget.components?.length > 0 && (
          <>
            <label className="medium-text font-12 lighter-text mt-4">{t('COMPONENTS_UC')}</label>
            <div className="flex-column gap-2 mt-2 overflow-scroll component-details-wrapper">
              {widget.components?.map(component => (
                <div className="flex px-4 py-2 radius-3 component-details">
                  <label className="regular-text one-line flex-1">{component.name}</label>
                </div>
              ))}
            </div>
          </>
        )}
      </DeleteFormWidgetWrapper>
    </CommonPopup>
  );
};

export default DeleteFormWidget;

import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import ArrowLeft from '../../../assets/images/chart/arrow-left.svg';
import ArrowRight from '../../../assets/images/chart/arrow-right.svg';
import './date-selector.scss';

const DateSelector = ({
  isForEndDatePicker = false,
  isFromDateRange = false,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  range,
  showCurrentMonth = true,
  isOnlyWeekDays = false,
  weekday = 0,
  disablePastDays = false,
  disablePastDaysOfDate = false,
  disablePastDayDate = null,
  disableFutureDaysOfDate = false,
  disableFutureDayDate = null,
  top,
}) => {
  const weekDays = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];

  const [value, setValue] = useState(
    showCurrentMonth ? moment().clone() : isForEndDatePicker ? endDate.clone() : startDate.clone(),
  );
  const [calendar, setCalendar] = useState([]);

  const startDay = value.clone().startOf('month').startOf('week');
  const endDay = value.clone().endOf('month').endOf('week');

  const currentDateRef = useRef(moment());
  const [disablePastDayDates, setDisablePastDayDate] = useState(
    disablePastDayDate ? moment(disablePastDayDate * 1000) : moment(),
  );
  const [disableFutuDayDates, setDisableFutuDayDates] = useState(
    disableFutureDayDate ? moment(disableFutureDayDate * 1000) : moment(),
  );
  useEffect(() => {
    setDisablePastDayDate(disablePastDayDate ? moment(disablePastDayDate * 1000) : moment());
  }, [disablePastDayDate]);

  useEffect(() => {
    setDisableFutuDayDates(disableFutureDayDate ? moment(disableFutureDayDate * 1000) : moment());
  }, [disableFutureDayDate]);

  useEffect(() => {
    const day = startDay.clone().subtract(1, 'day');
    const a = [];
    while (day.isBefore(endDay, 'day')) {
      a.push(
        Array(7)
          .fill(0)
          .map(() => day.add(1, 'day').clone()),
      );
    }
    setCalendar(a);
  }, [value]);

  const changeSelectedDate = day => {
    if (range) {
      setStartDate(isFromDateRange ? day.clone().startOf('day').unix() : day.clone().weekday(weekday).unix());
      const addedDays = isOnlyWeekDays ? 5 : weekday === 0 ? 6 : 7;
      setEndDate(isFromDateRange ? day.clone().endOf('day').unix() : day.clone().weekday(addedDays).unix());
    } else {
      setStartDate(day.clone().startOf('day').unix());
      setEndDate(day.clone().endOf('day').unix());
    }
  };

  return (
    <div className="date-selector send-calendar py-5 px-6 absolute" style={{ top: top ? top : '' }}>
      <div className="month-selector mx-3">
        <img
          className="pxy-2"
          onClick={e => {
            e.stopPropagation();
            setValue(value.subtract(1, 'month').clone());
          }}
          src={ArrowLeft}
        />
        <label className="month normal-text">{value.format('MMMM YYYY')}</label>
        <img
          className="pxy-2"
          onClick={e => {
            e.stopPropagation();
            setValue(value.add(1, 'month').clone());
          }}
          src={ArrowRight}
        />
      </div>
      <div className="week-days">
        {weekDays.map(wd => (
          <div key={wd} className="week-day">
            <label className="normal-text font-14">{wd}</label>
          </div>
        ))}
      </div>
      {calendar.map((week, j) => (
        <div key={j} className="week">
          {week.map((day, i) => (
            <div
              key={i}
              className={`day regular-text cursor ${!value.isSame(day, 'month') && 'fade'}
               ${
                 range &&
                 ((day.isAfter(startDate) && day.isBefore(endDate) && !day.isSame(endDate, 'day')) ||
                   day.isSame(startDate, 'day') ||
                   day.isSame(endDate, 'day')) &&
                 'range-day'
               } ${disablePastDays && !currentDateRef.current.isBefore(day, 'date') && 'fade'}
               ${disablePastDaysOfDate && !disablePastDayDates.isBefore(day, 'date') && 'fade'}
               ${disableFutureDaysOfDate && !disableFutuDayDates.isAfter(day, 'date') && 'fade'}`}
              onClick={e => {
                if (
                  (!disablePastDays || (disablePastDays && currentDateRef.current.isBefore(day, 'date'))) &&
                  (!disablePastDaysOfDate || (disablePastDaysOfDate && disablePastDayDates.isBefore(day, 'date'))) &&
                  (!disableFutureDaysOfDate || (disableFutureDaysOfDate && disableFutuDayDates.isAfter(day, 'date')))
                ) {
                  changeSelectedDate(day);
                } else {
                  e.stopPropagation();
                }
              }}>
              <div
                className={`w-full h-full flex items-center justify-center ${
                  isFromDateRange
                    ? `${day.isSame(startDate, 'day') || day.isSame(endDate, 'day') ? 'selected-day' : ''}`
                    : `${day.isSame(startDate, 'day') && !isForEndDatePicker && 'selected-day'} ${
                        range &&
                        day.isSame(endDate, 'day') &&
                        (!isFromDateRange || isForEndDatePicker) &&
                        'selected-day'
                      } `
                }`}>
                <label>{day.format('D')}</label>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default DateSelector;

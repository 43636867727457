import React from 'react';
import { useSelector } from 'react-redux';
import AccountActivation from '../../components/AccountActivation';
import { AccountActivationWrapper } from '../../styles/pages/activate.styled';

const Activate = () => {
  const { invite } = useSelector(state => state.user);

  return (
    <AccountActivationWrapper className="flex justify-center h-100-vh overflow-scroll">
      <AccountActivation />
    </AccountActivationWrapper>
  );
};

export default Activate;

import styled from 'styled-components';

export const DateRangeStyledWrapper = styled.div`
  width: 658px;
  height: 517px;
  z-index: 3;
  top: 50px;
  right: 0;

  .date-container-wrapper {
    min-height: 441px;
    max-height: 441px;
  }

  .date-selector {
    width: 280px;
    height: 365px;
    box-shadow: none;
    top: 0px;
    padding: 0px;
  }

  .bottom-panel {
    border-top: 1px solid ${({ theme }) => theme.colors.divider};
    max-height: 72px;
    min-height: 72px;

    .date-value-container {
      max-height: 40px;
      min-height: 40px;
      border: 1px solid ${({ theme }) => theme.colors.divider};
    }

    .action-btn {
      padding: 10px 16px;
    }
  }
`;

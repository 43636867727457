import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import ArrowDown from '../../../assets/images/arrow-down-2.svg';
import { ReactComponent as CalendarIcon } from '../../../assets/images/calendar_icon.svg';
import { ReactComponent as CalendarPurpleIcon } from '../../../assets/images/calender-icon-purple.svg';
import DateSelector from '../date-selector/date-selector';
import { DateSelectorElementWrapper } from './date-selector-element.styled';

const DateSelectorElement = ({
  selectedDate,
  setSelectedDate,
  placeholder,
  format = 'DD MMM YYYY',
  className = '',
  showDropdownArrow = true,
  disablePastDays = false,
  disablePastDaysOfDate = false,
  disablePastDayDate = null,
  showCurrentMonth = true,
  showOnlyIcon = false,
  isCustomFromChart = false,
  onSelectorClose = () => {},
  ...rest
}) => {
  const { startDate, endDate, onStartDateClick, onEndDateClick, dateToSet, isDateRangeSelected } = rest || {};
  const [showCalendar, setShowCalendar] = useState(false);

  const [startDateState, setStartDateState] = useState(
    isCustomFromChart && startDate ? moment(startDate).format('MM/DD/YYYY') : '',
  );
  const [endDateState, setEndDateState] = useState(
    isCustomFromChart && endDate ? moment(endDate).format('MM/DD/YYYY') : '',
  );

  const changeSelectedDate = date => {
    setSelectedDate(date);
    if (!isCustomFromChart) {
      setShowCalendar(false);
    } else {
      setShowCalendar(true);
    }
  };

  useEffect(() => {
    if (startDate || endDate) {
      setStartDateState(moment(startDate).format('MM/DD/YYYY'));
      setEndDateState(moment(endDate).format('MM/DD/YYYY'));
    }
  }, [startDate, endDate]);

  const onBlur = e => {
    e.preventDefault();
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setShowCalendar(false);
      onSelectorClose();
      setStartDateState(isCustomFromChart && startDate ? moment(startDate).format('MM/DD/YYYY') : '');
      setEndDateState(isCustomFromChart && endDate ? moment(endDate).format('MM/DD/YYYY') : '');
    }
  };

  return (
    <DateSelectorElementWrapper
      className={`date-selector-element flex flex-1 items-center justify-between px-4 cursor py-2 ${className}`}
      onBlur={onBlur}
      onClick={e => {
        e.stopPropagation();
        isCustomFromChart ? setShowCalendar(true) : setShowCalendar(!showCalendar);
      }}
      tabIndex={0}>
      {showOnlyIcon ? (
        showCalendar || isDateRangeSelected ? (
          <CalendarPurpleIcon />
        ) : (
          <CalendarIcon />
        )
      ) : (
        <Fragment>
          {selectedDate ? (
            <label className="regular-text font-14">{moment.unix(selectedDate).format(format)}</label>
          ) : (
            <label className="regular-text grey-text font-14">{placeholder}</label>
          )}
          {showDropdownArrow && <img src={ArrowDown} />}
        </Fragment>
      )}
      {showCalendar &&
        (!isCustomFromChart ? (
          <Fragment>
            <DateSelector
              setEndDate={() => {}}
              setStartDate={changeSelectedDate}
              startDate={moment.unix(selectedDate || moment().unix())}
              disablePastDays={disablePastDays}
              disablePastDaysOfDate={disablePastDaysOfDate}
              disablePastDayDate={disablePastDayDate}
              showCurrentMonth={showCurrentMonth}
              {...rest}
            />
          </Fragment>
        ) : (
          <div className="absolute flex-column custom-date-selector-element">
            <DateSelector
              setEndDate={() => {}}
              setStartDate={changeSelectedDate}
              startDate={moment.unix(selectedDate || moment().unix())}
              disablePastDays={disablePastDays}
              disablePastDaysOfDate={disablePastDaysOfDate}
              disablePastDayDate={disablePastDayDate}
              showCurrentMonth={showCurrentMonth}
              {...rest}
            />
            <div className="px-4 py-3 border-top flex items-center col-gap-2">
              <input
                type="text"
                className={`regular-text font-14 pxy-2 text-center border radius-3 ${
                  dateToSet === 'startDate' && 'selected'
                }`}
                value={startDateState}
                style={{ width: '112px' }}
                onFocus={() => onStartDateClick && onStartDateClick()}
                onChange={e => {
                  const value = e.target.value;
                  const newStartDate = moment(value, 'MM/DD/YYYY', true);
                  if (newStartDate.isValid()) {
                    onStartDateClick && onStartDateClick(newStartDate.unix());
                    changeSelectedDate(newStartDate.unix());
                  }
                  setStartDateState(value);
                }}
              />
              <label className="regular-text font-14">-</label>
              <input
                type="text"
                className={`regular-text font-14 pxy-2 text-center border radius-3  ${
                  dateToSet === 'endDate' && 'selected'
                }`}
                value={endDateState}
                style={{ width: '112px' }}
                onFocus={() => onEndDateClick && onEndDateClick()}
                onChange={e => {
                  const value = e.target.value;
                  const newEndDate = moment(value, 'MM/DD/YYYY', true);
                  if (newEndDate.isValid()) {
                    onEndDateClick && onEndDateClick(newEndDate.unix());
                    changeSelectedDate(newEndDate.unix());
                  }
                  setEndDateState(value);
                }}
              />
            </div>
          </div>
        ))}
    </DateSelectorElementWrapper>
  );
};

export default DateSelectorElement;

import React from 'react';
import InputElement from '../../../components/FormElements/input';

export const PaymentAdvanceConfig = ({ component, setSelectedComponent = () => {} }) => {
  const { lexicon } = component;

  return (
    <InputElement
      name="Lexicon"
      onChange={value => setSelectedComponent({ ...component, lexicon: value })}
      value={lexicon}
      label="Enter"
      className="w-full mt-6"
      font={{ size: 12 }}
    />
  );
};

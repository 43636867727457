import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import uuid from 'react-uuid';
import { ReactComponent as DragHandleIcon } from '../../../assets/images/drag-handle.svg';
import { ReactComponent as EditIcon } from '../../../assets/images/edit.svg';
import { ReactComponent as PlusIcon } from '../../../assets/images/plus.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/images/trash-red.svg';
import Button from '../../../components/Elements/button/button';
import Dropdown from '../../../components/FormElements/dropdown';
import UpdateAction from '../../../components/common/update-action';
import FormBuilderContext from '../../../context/FormBuilderContext';
import { addToast } from '../../../store/features/toastSlice';

export const ChooserOptionsConfig = ({ component, setSelectedComponent = () => {} }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { lists, getListDetail, updateListChoiceConfig, onAddChooserList } = useContext(FormBuilderContext);
  const { list } = component;
  const { choices, id, name } = list || {};

  const [newChoice, setNewChoice] = useState({});
  const [selectedChoice, setSelectedChoice] = useState({});

  useEffect(() => {
    setNewChoice({});
    setSelectedChoice({});
  }, [list?.id]);

  const onChangeList = async option => {
    let { choices, id, name } = option;
    if (id === 'ADD_BUTTON') {
      return;
    }
    if (!choices) {
      const listDetail = await getListDetail(id);
      choices = listDetail.choices;
    }
    setSelectedComponent({ ...component, list: { id, name, choices } });
  };

  const onUpdateListChoiceSync = (id, newChoices) => {
    updateListChoiceConfig(id, newChoices);
    setSelectedComponent({ ...component, list: { id, name, choices: newChoices } });
  };

  const onAddOption = async () => {
    if (!newChoice.name) {
      dispatch(addToast({ error: true, text: 'Please add choice name' }));
      return;
    }
    const newChoices = [...choices, { name: newChoice.name }].map((c, index) => ({
      ...c,
      priority: index,
    }));
    const listChoices = await updateListChoiceConfig(id, newChoices);
    setSelectedComponent({ ...component, list: { id, name, choices: listChoices } });
    setNewChoice({});
  };

  const onUpdateOption = () => {
    if (!selectedChoice.name) {
      dispatch(addToast({ error: true, text: 'Please add choice name' }));
      return;
    }
    const newChoices = [...choices].map((c, index) =>
      c.id === selectedChoice.id ? { ...selectedChoice, priority: index } : { ...c, priority: index },
    );
    onUpdateListChoiceSync(id, newChoices);
    setSelectedChoice({});
  };

  const onDeleteChoice = choice => {
    const newChoices = [...choices]
      .filter(c => c.id !== choice.id)
      .map((c, index) => ({
        ...c,
        priority: index,
      }));
    onUpdateListChoiceSync(id, newChoices);
  };

  return (
    <div className="flex-column my-6">
      <label className="flex-1 medium-text font-16">{t('OPTIONS_UC')}</label>
      <Dropdown
        className="mt-6"
        name="List type"
        placeholder="Select"
        options={lists}
        font={{ size: 12 }}
        selectedValue={lists.find(c => c.value === list?.id)}
        onChange={option => onChangeList(option)}
        showAddButtonInOption={true}
        onAddButton={onAddChooserList}
      />
      {id && (
        <div className="flex-column mt-6">
          <Button
            size="average"
            className="flex items-center justify-center background-primary specified-width add-option-btn w-full"
            label="Add new"
            icon={<PlusIcon />}
            borderRadius="16px"
            width="100%"
            onClick={() => {
              if (!newChoice.key) {
                const newChoice = { key: uuid() };
                setNewChoice(newChoice);
                setSelectedChoice(newChoice);
              }
            }}
          />
          {newChoice.key && (
            <div className={`flex items-center flex-1 border radius-4 px-4 py-2 mt-4 relative selected-choice`}>
              <DragHandleIcon />
              <input
                className="input choice-input"
                placeholder="New choice"
                value={newChoice?.name}
                onChange={({ target: { value } }) => setNewChoice({ ...newChoice, name: value })}
              />
              <UpdateAction
                className="update-action mt-1"
                onCancel={() => setNewChoice({})}
                onUpdate={() => onAddOption()}
              />
            </div>
          )}
          {choices?.map(choice => (
            <div
              className={`flex items-center flex-1 border radius-4 px-4 py-2 mt-4 cursor relative choice-wrapper ${
                !newChoice.key && 'edit-hover'
              } ${selectedChoice.id && selectedChoice.id === choice.id && 'selected-choice'}`}
              onClick={() => {
                if (!newChoice.key) {
                  setSelectedChoice(choice);
                }
              }}>
              <DragHandleIcon />
              {selectedChoice.id && selectedChoice.id === choice.id ? (
                <Fragment>
                  <input
                    className="input choice-input"
                    placeholder="Choice Name"
                    value={selectedChoice?.name}
                    autoFocus
                    onChange={e => {
                      e.stopPropagation();
                      setSelectedChoice({ ...selectedChoice, name: e.target.value });
                    }}
                  />
                  <UpdateAction
                    className="update-action mt-1"
                    onCancel={e => {
                      e.stopPropagation();
                      setSelectedChoice({});
                    }}
                    onUpdate={e => {
                      e.stopPropagation();
                      onUpdateOption();
                    }}
                  />
                </Fragment>
              ) : (
                <Fragment>
                  <label className="flex-1 ml-4 regular-text font-16 one-line">{choice.name}</label>
                  <div className="flex display-none choice-actions">
                    <EditIcon />
                    <DeleteIcon
                      className="ml-2"
                      onClick={e => {
                        e.stopPropagation();
                        onDeleteChoice(choice);
                      }}
                    />
                  </div>
                </Fragment>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

import React from 'react';
import { ReactComponent as InviteExpireIcon } from '../../assets/images/invite-expire.svg';
import { ReactComponent as LogoIcon } from '../../assets/images/zenterprize-logo-text.svg';
import { InviteWrapper } from '../../styles/components/invite/invite.styled';

const InviteExpire = () => {
  return (
    <InviteWrapper className="flex flex-column items-center radius-6 h-fit-content m-auto">
      <LogoIcon />
      <div className="bold-text font-20 pt-6 px-16 text-center">Whoops, looks like this invitation has expired</div>
      <div className="regular-text font-14 pt-2 lighter-text">Please ask your Organization to re-send the invite.</div>
      <div className="pt-10">
        <InviteExpireIcon />
      </div>
    </InviteWrapper>
  );
};

export default InviteExpire;

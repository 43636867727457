import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PrizeDrawDetailWrapper } from '../../styles/components/prize-draw-detail/prize-draw-detail.styled';
import UserProfileLeft from './user-profile-left';
import UserProfleRight from './user-profile-right';

const ProfileUser = ({ prfile_Id }) => {
  return (
    <PrizeDrawDetailWrapper className="flex col-gap-6 flex-1 px-10 pt-2 pb-6">
      <Routes>
        <Route
          element={
            <>
              <UserProfileLeft prfile_Id={prfile_Id} />
              <UserProfleRight />
            </>
          }
          path="/*"
        />
      </Routes>
    </PrizeDrawDetailWrapper>
  );
};

export default ProfileUser;

import React, { Fragment, useContext } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import FormBuilderContext from '../../context/FormBuilderContext';
import { LeftPanelWrapper } from './edit-form-version.styled';

const LeftPanel = ({ previewForm }) => {
  const { widgetGroupList } = useContext(FormBuilderContext);

  return (
    <LeftPanelWrapper className="px-4 h-full overflow-scroll border-right">
      <Droppable droppableId="widget-list" isDropDisabled={true}>
        {provided => (
          <div id="widget-list" className="characters" {...provided.droppableProps} ref={provided.innerRef}>
            {widgetGroupList.map(({ name, widgets, id }, index) => (
              <div key={id}>
                <label className="bold-text mb-4 flex">{name}</label>
                {widgets.map((widget, i) => (
                  <Draggable key={widget.id} draggableId={widget.id} index={i} isDragDisabled={previewForm}>
                    {(provided, snapshot) => (
                      <Fragment>
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="flex items-center px-4 py-3 widget-container radius-3 mb-2 cursor"
                          style={{
                            ...provided.draggableProps.style,
                            transform: snapshot.isDragging
                              ? provided.draggableProps.style?.transform
                              : 'translate(0px, 0px)',
                          }}>
                          {widget.icon?.inactive_url && (
                            <img src={widget.icon.inactive_url} className="mr-2 widget-icon" width={14} height={14} />
                          )}
                          <label className="semibold-text">{widget.name}</label>
                        </div>
                        {snapshot.isDragging && (
                          <div
                            className="flex items-center px-4 py-3 widget-container radius-3 mb-2 cursor"
                            style={{ transform: 'none !important' }}>
                            {widget.icon?.inactive_url && (
                              <img src={widget.icon.inactive_url} className="mr-2 widget-icon" width={14} height={14} />
                            )}
                            <label className="semibold-text">{widget.name}</label>
                          </div>
                        )}
                      </Fragment>
                    )}
                  </Draggable>
                ))}
                {index < widgetGroupList.length - 1 && <hr className="hr-line" />}
              </div>
            ))}
          </div>
        )}
      </Droppable>
    </LeftPanelWrapper>
  );
};

export default LeftPanel;

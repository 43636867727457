import React, { useEffect, useState } from 'react';
import { DeviceFrameset } from 'react-device-frameset';
import 'react-device-frameset/styles/marvel-devices.min.css';
import styled from 'styled-components';
import { ReactComponent as ModeLandscape } from '../../../assets/images/mode-landscape.svg';
import { ReactComponent as ModePortrait } from '../../../assets/images/mode-portrait.svg';
import ContentPreview from './content-preview';

const devices = {
  MONITOR: 'MacBook Pro',
  TABLET: 'iPad Mini',
  MOBILE: 'iPhone X',
};

const FormPreview = ({ selectedDevice }) => {
  const [landscape, setLandscape] = useState(false);

  useEffect(() => {
    setLandscape(false);
  }, [selectedDevice]);

  return (
    <FormPreviewWrapper className="h-full flex flex-1 justify-center">
      <div className="flex flex-1 justify-center overflow-scroll px-10 py-14">
        <DeviceFrameset device={devices[selectedDevice]} color="black" landscape={landscape}>
          <ContentPreview selectedDevice={selectedDevice} landscape={landscape} />
        </DeviceFrameset>
      </div>
      {selectedDevice !== 'MONITOR' && (
        <div className="flex col-gap-4 h-fit-content mr-10 mt-14">
          <div
            className={`pxy-3 cursor radius-2 border flex items-center justify-center card`}
            onClick={() => setLandscape(true)}>
            <ModePortrait className={landscape ? 'zen-purple-text' : 'Regular-text'} />
          </div>
          <div
            className={`pxy-3 cursor radius-2 border flex items-center justify-center card`}
            onClick={() => setLandscape(false)}>
            <ModeLandscape className={!landscape ? 'zen-purple-text' : 'Regular-text'} />
          </div>
        </div>
      )}
    </FormPreviewWrapper>
  );
};

const FormPreviewWrapper = styled.div`
  .screen {
    max-width: 100%;
  }
`;

export default FormPreview;

import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import InputElement from '../../../components/FormElements/input';
import Switch from '../../../components/common/switch';
import { MultichoiceAdvanceConfig } from './multichoice-advance-config';
import { PaymentAdvanceConfig } from './payment-advance-config';

export const AdvancedConfig = ({ component, setSelectedComponent = () => {} }) => {
  const { component_type, lexicon, is_confidential, is_autofill, is_visible, requires_confirmation } = component;
  const { t } = useTranslation();
  return (
    <div className="flex-column mt-8">
      <label className="flex-1 medium-text font-16">{t('ADVANCED_UC')}</label>
      {component_type === 'PAYMENT' ? (
        <PaymentAdvanceConfig component={component} setSelectedComponent={setSelectedComponent} />
      ) : component_type === 'MULTICHOICE' ? (
        <MultichoiceAdvanceConfig component={component} setSelectedComponent={setSelectedComponent} />
      ) : (
        <Fragment>
          {component_type !== 'HEADER' && component_type !== 'IMAGE' && (
            <Fragment>
              <InputElement
                name="Lexicon"
                onChange={value => setSelectedComponent({ ...component, lexicon: value })}
                value={lexicon}
                label="Enter"
                className="w-full mt-6"
                font={{ size: 12 }}
              />
              <div className="flex mt-6">
                <label className="regular-text flex-1">{t('CONFIDENTIAL')}</label>
                <Switch
                  enabled={is_confidential}
                  onClick={() => setSelectedComponent({ ...component, is_confidential: !is_confidential })}
                />
              </div>
              <div className="flex mt-6">
                <label className="regular-text flex-1">Autofill</label>
                <Switch
                  enabled={is_autofill}
                  onClick={() => setSelectedComponent({ ...component, is_autofill: !is_autofill })}
                />
              </div>
              {component_type !== 'ADDRESS' &&
                component_type !== 'PAYMENT' &&
                component_type !== 'MULTICHOICE' &&
                component_type !== 'SIGNATURE' && (
                  <div className="flex mt-6">
                    <label className="regular-text flex-1">{t('REQUIRES_CONFIRMATION')}</label>
                    <Switch
                      enabled={requires_confirmation}
                      onClick={() =>
                        setSelectedComponent({ ...component, requires_confirmation: !requires_confirmation })
                      }
                    />
                  </div>
                )}
            </Fragment>
          )}
          <div className="flex mt-6">
            <label className="regular-text flex-1">{t('VISIBLE')}</label>
            <Switch
              enabled={is_visible}
              onClick={() => setSelectedComponent({ ...component, is_visible: !is_visible })}
            />
          </div>
        </Fragment>
      )}
    </div>
  );
};

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Editor from '../../../components/common/editor';
import Switch from '../../../components/common/switch';
import FormBuilderContext from '../../../context/FormBuilderContext';

export const MultichoiceGeneralConfig = ({ component, setSelectedComponent = () => {} }) => {
  const { setInsideModal } = useContext(FormBuilderContext);

  const { name, is_required } = component;
  const { t } = useTranslation();

  const onLink = editorRef => {
    setInsideModal({
      type: 'add-link',
      content: {
        onSuccess: data => {
          if (editorRef) {
            const editorContents = editorRef?.getEditorContents();
            editorRef.setEditorContents(editorRef.getEditor(), `${editorContents} ${data}`);
          }
        },
      },
    });
  };

  return (
    <div className="flex-column my-8">
      <label className="flex-1 medium-text font-16">{t('GENERAL_UC')}</label>
      <div className="flex-column mt-6">
        <label className="regular-text flex-1">{t('NAME')}</label>
        <Editor
          placeholder="Enter"
          value={name}
          setValue={value => setSelectedComponent({ ...component, name: value })}
          showLink={true}
          onLink={onLink}
        />
      </div>
      <div className="flex mt-6">
        <label className="regular-text flex-1">{t('REQUIRED')}</label>
        <Switch
          enabled={is_required}
          onClick={() => setSelectedComponent({ ...component, is_required: !is_required })}
        />
      </div>
    </div>
  );
};

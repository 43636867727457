import React from 'react';
import { FormDetailsHeaderWrapper } from '../../../styles/components/formDetails/form-details.styled';

const FormVersionDetailsHeader = ({ title, titleContent = null, actionContent = null }) => {
  return (
    <FormDetailsHeaderWrapper className="items-center justify-between w-full pl-6 pr-4 py-4">
      <div className="flex items-center justify-start mr-2">
        <label className="medium-text fw-500 font-20 nowrap">{title}</label>
        {titleContent}
      </div>
      {actionContent}
    </FormDetailsHeaderWrapper>
  );
};

export default FormVersionDetailsHeader;

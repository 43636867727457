import React from 'react';
import StripeWrapper from './stripe-element';

const Payment = ({
  selectedProduct,
  backgroundColor = '',
  setPaymentDetails = () => {},
  onSubmitForm = () => {},
  selectedAddress = {},
  integration,
  selectedFormPanel,
}) => {
  const { option: subscription, component } = selectedProduct || {};

  return (
    <div className="flex-column mt-6 ignore-click">
      <StripeWrapper
        backgroundColor={backgroundColor}
        subscription={subscription}
        setPaymentDetails={setPaymentDetails}
        onSubmitForm={onSubmitForm}
        selectedAddress={selectedAddress}
        productComponentName={component?.name || 'Payment Frequency'}
        integration={integration}
        selectedFormPanel={selectedFormPanel}
      />
    </div>
  );
};

export default Payment;

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import FormBuilderContext from '../../../context/FormBuilderContext';
export const ProgressbarSettings = () => {
  const { formAttributes, setFormAttributes, onConfirmationPageToggle } = useContext(FormBuilderContext);
  const { progress_display } = formAttributes;
  const progressBarTabList = [
    { name: 'None', key: 'NONE' },
    { name: 'Top', key: 'TOP' },
    { name: 'Bottom', key: 'BOTTOM' },
  ];
  const { t } = useTranslation();

  return (
    <div className="px-4 py-8 flex-column border-bottom">
      <label className="medium-text">{t('PROGRESS_BAR_UC')}</label>
      <div className="flex pxy-1 mt-6 radius-3 divider-border progress-bar-settings-container">
        {progressBarTabList.map(({ name, key }) => (
          <label
            key={key}
            className={`regular-text flex items-center justify-center flex-1 cursor progress-bar-tab ${
              progress_display === key && 'medium-text selected-progress-bar-tab'
            }`}
            onClick={() => setFormAttributes({ ...formAttributes, progress_display: key })}>
            {name}
          </label>
        ))}
      </div>
    </div>
  );
};

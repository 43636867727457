import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { addToast } from '../../../store/features/toastSlice';
import CommonPopup from '../../common-popup/index';
import { AddChooserListWrapper } from './index.styled';

const AddChooserList = ({ modal, setModal }) => {
  const dispatch = useDispatch();
  const { addChooserList } = modal.content || {};
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState({});
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const onDone = async () => {
    if (!list.name) {
      dispatch(addToast({ error: true, text: 'Please add list name' }));
      setError({ name: true });
      return;
    }
    if (loading) {
      return;
    }
    try {
      setLoading(true);
      await addChooserList(list);
      setModal(null);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <CommonPopup
      disabled={loading}
      popupTitle={'Add new list'}
      confirmButtonProps={{ label: 'Add', className: 'primary' }}
      onCancel={() => setModal(null)}
      onConfirm={onDone}>
      <AddChooserListWrapper className="w-full radius-3 mt-6">
        <div className="flex-column">
          <label className="regular-text lighter-text font-12">{t('NAME')}</label>
          <input
            className={`input ${error?.name && 'error-border'}`}
            placeholder="New list"
            value={list.name}
            onChange={({ target: { value } }) => {
              setList({ ...list, name: value });
              setError({ name: false });
            }}
          />
        </div>
        <div className="flex-column mt-6">
          <label className="regular-text lighter-text font-12">{t('DESCRIPTION')}</label>
          <textarea
            className="textarea"
            placeholder="Enter description"
            value={list.description}
            onChange={({ target: { value } }) => setList({ ...list, description: value })}
          />
        </div>
      </AddChooserListWrapper>
    </CommonPopup>
  );
};

export default AddChooserList;

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import DropDown from '../../../components/McDropdown';
import { fontStyleOptions } from '../../../constant/optionData';
import FormBuilderContext from '../../../context/FormBuilderContext';
import { ColorPicker } from '../common/color-picker';

export const FontSettings = ({ colorPicker, setColorPicker }) => {
  const { formAttributes, setFormAttributes, fonts } = useContext(FormBuilderContext);
  const { name, style, size, color } = formAttributes.font;
  const { t } = useTranslation();
  return (
    <div className="px-4 py-8 border-bottom flex-column">
      <label className="medium-text">{t('FONT_UC')}</label>
      <div className="flex items-center mt-6">
        <label className="flex regular-text flex-1">{t('DEFAULT_FONT')}</label>
        <DropDown
          className="flex-1"
          options={fonts}
          selected={fonts.find(f => f.name === name)}
          placeholder="Font"
          setSelected={option =>
            setFormAttributes({ ...formAttributes, font: { ...formAttributes.font, name: option.name } })
          }
        />
      </div>
      <div className="flex items-center mt-4">
        <label className="flex regular-text flex-1">{t('FONT_STYLE')}</label>
        <DropDown
          className="flex-1"
          options={fontStyleOptions}
          selected={fontStyleOptions.find(fs => fs.value === style)}
          placeholder="Style"
          setSelected={option =>
            setFormAttributes({ ...formAttributes, font: { ...formAttributes.font, style: option.value } })
          }
        />
      </div>
      <div className="flex items-center mt-4">
        <label className="flex regular-text flex-1">Size</label>
        <input
          className="input general-settings-input w-full"
          placeholder="Size"
          type="number"
          value={size}
          onChange={({ target: { value } }) =>
            setFormAttributes({ ...formAttributes, font: { ...formAttributes.font, size: value } })
          }
        />
      </div>
      <ColorPicker
        className="flex items-centers mt-4"
        label={'Default font color'}
        settingKey={'default_font_color'}
        selectedColor={color}
        onChangeColor={(settingKey, color) =>
          setFormAttributes({ ...formAttributes, font: { ...formAttributes.font, color: color } })
        }
        colorPicker={colorPicker}
        setColorPicker={setColorPicker}
      />
    </div>
  );
};

import { createSlice } from '@reduxjs/toolkit';
import { getItemFromLocalStorage } from '../../helpers/localstorage';
import api from '../services';

export const usersManagementSlice = createSlice({
  name: 'UsersManagement',
  initialState: {
    users: [],
    userRoles: [],
  },
  reducers: {
    setUsers: (state, { payload }) => {
      if (payload.merge) {
        const mergeData = { ...payload?.data, content: [...state?.users?.content, ...payload?.data?.content] };
        state.users = mergeData;
      } else {
        state.users = payload.data;
      }
    },
    updateUsersList: (state, { payload }) => {
      state.users.content = [
        ...state.users.content.map(user =>
          user.id === payload.id
            ? {
                ...user,
                is_active: !user.is_active,
                status:
                  user.status === 'ACTIVE' || user.status === 'INACTIVE'
                    ? user.is_active
                      ? 'INACTIVE'
                      : 'ACTIVE'
                    : user.status,
              }
            : { ...user },
        ),
      ];
    },
    removeUserFromList: (state, { payload }) => {
      state.users.content = [...state.users.content.filter(user => user.id !== payload.id)];
    },
    setUserRoles: (state, { payload }) => {
      state.userRoles = payload.data;
    },
  },
});

export const getUsers = payload => async dispatch => {
  try {
    const { params, merge } = payload;
    const { data } = await api.get(`/users`, { params: params });
    dispatch(setUsers({ data: data, merge: merge }));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getUserRoles = payload => async dispatch => {
  const userLocal = getItemFromLocalStorage('user');
  const { data } = await api.get(`/organizations/${userLocal.organizations[0].id}/roles`);
  dispatch(setUserRoles({ data: data }));
};

export const checkRequiredField = payload => async dispatch => {
  try {
    const userLocal = getItemFromLocalStorage('user');
    const { data } = await api.get(
      `/organizations/${userLocal.organizations[0].id}/roles/${payload.role.id}/required_fields`,
    );
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addUsers = payload => async dispatch => {
  try {
    const { isInviteUserAPI, page } = payload;
    const org = getItemFromLocalStorage('user').organizations[0];
    const { data } = await api.post(`/organizations/${org.id}/users `, payload.user);
    if (isInviteUserAPI) {
      await dispatch(inviteUser({ organization_id: org.id, user: data }));
    }
    dispatch(getUsers({ params: { page: page, size: 13 } }));
    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const inviteUser = payload => async dispatch => {
  try {
    const { organization_id, user } = payload;
    const { data } = await api.post(`/organizations/${organization_id}/users/${user?.id}/invite`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateUsersStatus = payload => async () => {
  try {
    const { id, params } = payload;
    const { data } = await api.put(`/users/${id}/active`, params);
    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteUser = payload => async dispatch => {
  try {
    const { data } = await api.delete(`/users/${payload.id}`);
    dispatch(removeUserFromList(payload));
    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const { setUsers, updateUsersList, removeUserFromList, setUserRoles } = usersManagementSlice.actions;
export default usersManagementSlice.reducer;

import styled from 'styled-components';

export const DateSelectorElementWrapper = styled.div`
  height: 32px;
  border: 1px solid ${({ theme }) => theme.colors.ZenGray2};
  box-sizing: border-box;
  border-radius: 12px;

  .date-selector {
    top: 230px;
  }
`;

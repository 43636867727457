import React from 'react';
import { useTranslation } from 'react-i18next';
import { MinMaxInputWrapper } from '../min-max-input/min-max.styled';
const MinMaxIntValueInput = ({
  minValue,
  maxValue,
  setMinValue,
  setMaxValue,
  minPlaceholder = '',
  maxPlaceholder = '',
  typefilter,
}) => {
  const { t } = useTranslation();
  const isInteger = value => /^\d*$/.test(value);

  const handleMinChange = event => {
    const value = event.target.value;
    if (isInteger(value)) {
      setMinValue(value);
    }
  };

  const handleMaxChange = event => {
    const value = event.target.value;
    if (isInteger(value)) {
      setMaxValue(value);
    }
  };

  return (
    <MinMaxInputWrapper className="flex justify-start items-center w-full gap-6">
      <div className="flex-column justify-start items-start w-50">
        <label className="lighter-text font-12 mb-2">{t('MIN')}</label>
        <div className="input-field flex item-center">
          <input
            className="input-border w-full px-1 font-14 regular-text"
            value={minValue}
            onChange={handleMinChange}
            placeholder={minPlaceholder}
          />
        </div>
      </div>
      <div className="flex-column justify-start items-start w-50">
        <label className="lighter-text font-12 mb-2">{t('MAX')}</label>
        <div className="input-field flex item-center">
          <input
            className="input-border w-full px-1 font-14 regular-text"
            value={maxValue}
            onChange={handleMaxChange}
            placeholder={maxPlaceholder}
          />
        </div>
      </div>
    </MinMaxInputWrapper>
  );
};

export default MinMaxIntValueInput;

import React from 'react';
import { useTranslation } from 'react-i18next';

const NoResultComponent = () => {
  const { t } = useTranslation();
  return (
    <div className="flex-column items-center justify-center h-full w-full">
      <label className="medium-text font-16 ">{t('NO_RESULT')}</label>
      <label className="medium-text grey-text font-16">{t('NO_RESULT_FOUND')}</label>
    </div>
  );
};

export default NoResultComponent;

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Switch from '../../../components/common/switch';
import FormBuilderContext from '../../../context/FormBuilderContext';

export const ConfirmationPageSettings = () => {
  const { formAttributes, onConfirmationPageToggle, onConfirmationPageWatermarkToggle } =
    useContext(FormBuilderContext);
  const { confirmation_panel, watermark } = formAttributes;
  const { display_watermark } = watermark || {};
  const { id: confirmation_panel_id, display_watermark: confirmation_panel_watermark } = confirmation_panel || {};
  const { t } = useTranslation();
  return (
    <div className="px-4 py-8 flex-column">
      <label className="medium-text">{t('CONFIRMATION_PAGE_UC')}</label>
      <div className="flex mt-6">
        <label className="regular-text flex-1">{t('CONFIRMATION_PAGE')}</label>
        <Switch
          enabled={confirmation_panel_id}
          onClick={() => onConfirmationPageToggle(confirmation_panel_id ? false : true)}
        />
      </div>
      {confirmation_panel_id && display_watermark && (
        <div className="flex mt-6">
          <label className="regular-text flex-1">{t('DISPLAY_WATERMARK')}</label>
          <Switch
            enabled={confirmation_panel_watermark}
            onClick={() => onConfirmationPageWatermarkToggle(!confirmation_panel_watermark)}
          />
        </div>
      )}
    </div>
  );
};

import React from 'react';
import { dayOptions, monthOptions, yearOptions } from '../../../constant/optionData';
import Dropdown from '../dropdown/index';

const DateOfBirthDayMonthYear = ({
  value = {},
  setValue = () => {},
  name = '',
  is_required = false,
  font = {},
  fontFamily = {},
}) => {
  return (
    <div className="flex-column w-full">
      {name && (
        <div className="mb-1">
          <span
            className="regular-text lighter-text"
            style={{ fontSize: `${font.size || 14}px`, color: font?.color || '#6B7280', ...fontFamily }}>
            {name}
          </span>
          {is_required && <span className="required-star ml-1">*</span>}
        </div>
      )}
      <div className="flex w-full col-gap-2">
        <Dropdown
          options={dayOptions}
          placeholder={'Day'}
          selectedValue={dayOptions.find(option => option.value === value.day)}
          onChange={option => setValue({ ...value, day: option.value })}
        />
        <Dropdown
          options={monthOptions}
          placeholder={'Month'}
          selectedValue={monthOptions.find(option => option.value === value.month)}
          onChange={option => setValue({ ...value, month: option.value })}
        />
        <Dropdown
          options={yearOptions}
          placeholder={'Year'}
          selectedValue={yearOptions.find(option => option.value === value.year)}
          onChange={option => setValue({ ...value, year: option.value })}
        />
      </div>
    </div>
  );
};

export default DateOfBirthDayMonthYear;

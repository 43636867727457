import React from 'react';
import MaskedInput from 'react-text-mask';
import styled from 'styled-components';
import { ReactComponent as FlagUS } from '../../../assets/images/flag-us.svg';

const PhoneInput = ({ phone, setPhone, className = '' }) => {
  return (
    <PhoneInputWrapper className={`flex relative ${className}`}>
      <div className="flex absolute h-full items-center ml-4">
        <label className="regular-text font-16 mr-2">+1</label>
        <FlagUS />
      </div>
      <MaskedInput
        mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        value={phone}
        className="input w-full phone-input"
        placeholder={'000-000-0000'}
        guide={false}
        onChange={({ target: { value } }) => setPhone(value)}
      />
    </PhoneInputWrapper>
  );
};

const PhoneInputWrapper = styled('div')`
  .phone-input-dropdown {
    .organisations-border {
      border: none;
      height: 100%;
      width: 110px;
    }

    .option-name,
    .selected-name {
      font-size: 16px;
    }

    .mc-option-item:hover label {
      color: ${({ theme }) => theme.colors.RegularText};
    }
  }

  .phone-input {
    padding-left: 100px !important;
  }

  .error-border {
    border-color: ${({ theme }) => theme.colors.ZenNegative} !important;
  }
`;

export default PhoneInput;

import { getItemFromLocalStorage } from '../helpers/localstorage';
import { capitalize, formatText } from '../helpers/utils';
import { paymentDeclineCode } from './paymentDeclineCode';
const user = getItemFromLocalStorage('user');

export const multipleFilterTypeEnum = {
  entrants: 'ENTRANTS',
  formsList: 'FORMS_LIST',
  prizeDrawForms: 'PRIZE_DRAW_FORM',
  billingFees: 'BILLING_FEES',
  logs: 'LOGS',
  SaasOperators: 'SAAS_OPERATORS',
  Client: 'CLIENT',
  MarketingCompany: 'MARKETING_COMPANY',
  Agency: 'AGENCY',
  entarntSAASOperator: 'SAAS_OPERATOR',
  entrantClient: 'ENTRANT_CLIENT',
  entrantMarketingCompany: 'ENTRANT_MARKETING_COMPANY',
};

export const filterChildRenderComponent = {
  dropDown: 'DROPDOWN',
  searchableDropdown: 'SEARCHABLE_DROPDOWN',
  minMax: 'MIN_MAX_RANGE',
  dateRange: 'DATE_RANGE',
  infiniteScrollDropdown: 'INFINITE_SCROLL_DROPDOWN',
  inputDropDown: 'INPUT_DROPDOWN',
  infiniteScrollMultiDropdown: 'INFINITE_SCROLL_MULTI_DROPDOWN',
};

export const entrantsFilterOptions = [
  {
    name: 'PAYMENT_STATUS',
    param: 'product_status',
    type: 'PAYMENT_STATUS',
    object: 'PAYMENT_STATUS',
    childRenderComponent: filterChildRenderComponent.dropDown,
    allowTranslateOption: true,
  },
  {
    name: 'DRAW_STATUS',
    param: 'draw_status',
    type: 'DRAW_STATUS',
    object: 'DRAW_STATUS',
    childRenderComponent: filterChildRenderComponent.dropDown,
    allowTranslateOption: true,
  },
  {
    name: 'CREATED_DATE',
    minParam: 'created_date.min',
    maxParam: 'created_date.max',
    type: 'CREATED_DATE',
    object: 'CREATED_DATE',
    childRenderComponent: filterChildRenderComponent.dateRange,
  },
  {
    name: 'PRODUCT_FREQUENCY',
    countParam: 'interval_count',
    param: 'interval',
    type: 'PRODUCT_FREQUENCY',
    object: 'PRODUCT_FREQUENCY',
    childRenderComponent: filterChildRenderComponent.inputDropDown,
  },
  {
    name: 'WIN_STATUS',
    param: 'win_status',
    type: 'WIN_STATUS',
    object: 'WIN_STATUS',
    childRenderComponent: filterChildRenderComponent.dropDown,
  },
  {
    name: 'ENTRIES',
    minParam: 'entries.min',
    maxParam: 'entries.max',
    type: 'ENTRIES',
    object: 'ENTRIES',
    childRenderComponent: filterChildRenderComponent.minMax,
  },
  {
    name: 'OUT_OF_DRAW_DATE',
    minParam: 'out_of_draw.min',
    maxParam: 'out_of_draw.max',
    type: 'OUT_OF_DRAW',
    object: 'OUT_OF_DRAW',
    childRenderComponent: filterChildRenderComponent.dateRange,
  },
  {
    name: 'CANCELLATION_DATE',
    minParam: 'cancellation_date.min',
    maxParam: 'cancellation_date.max',
    type: 'CANCELLATION_DATE',
    object: 'CANCELLATION_DATE',
    childRenderComponent: filterChildRenderComponent.dateRange,
  },
  {
    name: 'LAST_SUCCESSFUL_PAYMENT_DATE',
    minParam: 'last_successful_payment.min',
    maxParam: 'last_successful_payment.max',
    type: 'LAST_SUCCESSFUL_PAYMENT_DATE',
    object: 'LAST_SUCCESSFUL_PAYMENT_DATE',
    childRenderComponent: filterChildRenderComponent.dateRange,
  },
  {
    name: 'NEXT_PAYMENT_DATE',
    minParam: 'next_payment.min',
    maxParam: 'next_payment.max',
    type: 'NEXT_PAYMENT_DATE',
    object: 'NEXT_PAYMENT_DATE',
    childRenderComponent: filterChildRenderComponent.dateRange,
  },
  {
    name: 'PAYMENT_METHOD',
    param: 'payment_method',
    type: 'PAYMENT_METHOD',
    object: 'PAYMENT_METHOD',
    childRenderComponent: filterChildRenderComponent.dropDown,
  },
  {
    name: 'PAYMENT_TYPE',
    param: 'payment_type',
    type: 'PAYMENT_TYPE',
    object: 'PAYMENT_TYPE',
    childRenderComponent: filterChildRenderComponent.dropDown,
    allowTranslateOption: true,
  },
  {
    name: 'DECLINE_CODE',
    param: 'decline_code',
    type: 'DECLINE_CODE',
    object: 'DECLINE_CODE',
    childRenderComponent: filterChildRenderComponent.dropDown,
  },
  {
    name: 'NET_PAYMENT',
    minParam: 'net_payments.min',
    maxParam: 'net_payments.max',
    type: 'NET_PAYMENT',
    object: 'NET_PAYMENT',
    childRenderComponent: filterChildRenderComponent.minMax,
  },
  {
    name: 'PAYMENT_AMOUNT',
    minParam: 'product_price.min',
    maxParam: 'product_price.max',
    type: 'PAYMENT_AMOUNT',
    object: 'PAYMENT_AMOUNT',
    childRenderComponent: filterChildRenderComponent.minMax,
  },
  {
    name: 'WIN_COUNT',
    minParam: 'win_count.min',
    maxParam: 'win_count.max',
    type: 'WIN_COUNT',
    object: 'WIN_COUNT',
    childRenderComponent: filterChildRenderComponent.minMax,
  },
  {
    name: 'ENTRY_FORM',
    param: 'entry_form',
    type: 'ENTRY_FORM',
    object: 'ENTRY_FORM',
    childRenderComponent: filterChildRenderComponent.infiniteScrollDropdown,
    isSearchable: false,
    isPaged: true,
  },
  {
    name: 'FUNDRAISER',
    param: 'fundraiser_id',
    type: 'FUNDRAISER',
    object: 'FUNDRAISER',
    childRenderComponent: filterChildRenderComponent.infiniteScrollDropdown,
    isSearchable: true,
    isPaged: true,
  },
  {
    name: 'FUNDRAISER_BADGE',
    param: 'fundraiser_id',
    type: 'FUNDRAISER_BADGE',
    object: 'FUNDRAISER_BADGE',
    childRenderComponent: filterChildRenderComponent.infiniteScrollDropdown,
    isSearchable: true,
    isPaged: true,
  },
  {
    name: 'MARKETING_COMPANY',
    param: 'marketing_company_id',
    subParam: 'fundraiser_id',
    type: 'MARKETING_COMPANY',
    object: 'MARKETING_COMPANY',
    childRenderComponent: filterChildRenderComponent.infiniteScrollMultiDropdown,
    isSearchable: true,
    isPaged: true,
  },
];

export const entrantsFilterOptionsForAgency = [
  {
    name: 'MARKETING_COMPANY',
    param: 'marketing_company_id',
    subParam: 'fundraiser_id',
    type: 'MARKETING_COMPANY_AGENCY',
    object: 'MARKETING_COMPANY_AGENCY',
    childRenderComponent: filterChildRenderComponent.infiniteScrollMultiDropdown,
    isSearchable: true,
    isPaged: true,
  },
];

export const entrantsFilterOptionsForSAASOperator = [
  {
    name: 'AGENCY',
    param: 'agency_id',
    type: 'AGENCY',
    object: 'AGENCY',
    childRenderComponent: filterChildRenderComponent.infiniteScrollMultiDropdown,
    isSearchable: false,
    isPaged: true,
  },
];

export const entrantsFilterOptionsForClient = [
  {
    name: 'PAYMENT_STATUS',
    param: 'product_status',
    type: 'PAYMENT_STATUS',
    object: 'PAYMENT_STATUS',
    childRenderComponent: filterChildRenderComponent.dropDown,
    allowTranslateOption: true,
  },
  {
    name: 'DRAW_STATUS',
    param: 'draw_status',
    type: 'DRAW_STATUS',
    object: 'DRAW_STATUS',
    childRenderComponent: filterChildRenderComponent.dropDown,
    allowTranslateOption: true,
  },
  {
    name: 'CREATED_DATE',
    minParam: 'created_date.min',
    maxParam: 'created_date.max',
    type: 'CREATED_DATE',
    object: 'CREATED_DATE',
    childRenderComponent: filterChildRenderComponent.dateRange,
  },
  {
    name: 'PRODUCT_FREQUENCY',
    countParam: 'interval_count',
    param: 'interval',
    type: 'PRODUCT_FREQUENCY',
    object: 'PRODUCT_FREQUENCY',
    childRenderComponent: filterChildRenderComponent.inputDropDown,
  },
  {
    name: 'WIN_STATUS',
    param: 'win_status',
    type: 'WIN_STATUS',
    object: 'WIN_STATUS',
    childRenderComponent: filterChildRenderComponent.dropDown,
  },
  {
    name: 'ENTRIES',
    minParam: 'entries.min',
    maxParam: 'entries.max',
    type: 'ENTRIES',
    object: 'ENTRIES',
    childRenderComponent: filterChildRenderComponent.minMax,
  },
  {
    name: 'OUT_OF_DRAW_DATE',
    minParam: 'out_of_draw.min',
    maxParam: 'out_of_draw.max',
    type: 'OUT_OF_DRAW',
    object: 'OUT_OF_DRAW',
    childRenderComponent: filterChildRenderComponent.dateRange,
  },
  {
    name: 'CANCELLATION_DATE',
    minParam: 'cancellation_date.min',
    maxParam: 'cancellation_date.max',
    type: 'CANCELLATION_DATE',
    object: 'CANCELLATION_DATE',
    childRenderComponent: filterChildRenderComponent.dateRange,
  },
  {
    name: 'LAST_SUCCESSFUL_PAYMENT_DATE',
    minParam: 'last_successful_payment.min',
    maxParam: 'last_successful_payment.max',
    type: 'LAST_SUCCESSFUL_PAYMENT_DATE',
    object: 'LAST_SUCCESSFUL_PAYMENT_DATE',
    childRenderComponent: filterChildRenderComponent.dateRange,
  },
  {
    name: 'NEXT_PAYMENT_DATE',
    minParam: 'next_payment.min',
    maxParam: 'next_payment.max',
    type: 'NEXT_PAYMENT_DATE',
    object: 'NEXT_PAYMENT_DATE',
    childRenderComponent: filterChildRenderComponent.dateRange,
  },
  {
    name: 'PAYMENT_METHOD',
    param: 'paymenth_method',
    type: 'PAYMENT_METHOD',
    object: 'PAYMENT_METHOD',
    childRenderComponent: filterChildRenderComponent.dropDown,
  },
  {
    name: 'PAYMENT_TYPE',
    param: 'payment_type',
    type: 'PAYMENT_TYPE',
    object: 'PAYMENT_TYPE',
    childRenderComponent: filterChildRenderComponent.dropDown,
    allowTranslateOption: true,
  },
  {
    name: 'DECLINE_CODE',
    param: 'decline_code',
    type: 'DECLINE_CODE',
    object: 'DECLINE_CODE',
    childRenderComponent: filterChildRenderComponent.dropDown,
  },
  {
    name: 'NET_PAYMENT',
    minParam: 'net_payments.min',
    maxParam: 'net_payments.max',
    type: 'NET_PAYMENT',
    object: 'NET_PAYMENT',
    childRenderComponent: filterChildRenderComponent.minMax,
  },
  {
    name: 'PAYMENT_AMOUNT',
    minParam: 'product_price.min',
    maxParam: 'product_price.max',
    type: 'PAYMENT_AMOUNT',
    object: 'PAYMENT_AMOUNT',
    childRenderComponent: filterChildRenderComponent.minMax,
  },
  {
    name: 'WIN_COUNT',
    minParam: 'win_count.min',
    maxParam: 'win_count.max',
    type: 'WIN_COUNT',
    object: 'WIN_COUNT',
    childRenderComponent: filterChildRenderComponent.minMax,
  },
  {
    name: 'ENTRY_FORM',
    param: 'entry_form',
    type: 'ENTRY_FORM',
    object: 'ENTRY_FORM',
    childRenderComponent: filterChildRenderComponent.infiniteScrollDropdown,
    isSearchable: false,
    isPaged: true,
  },
  {
    name: 'FUNDRAISER_BADGE',
    param: 'fundraiser_id',
    type: 'FUNDRAISER_BADGE',
    object: 'FUNDRAISER_BADGE',
    childRenderComponent: filterChildRenderComponent.infiniteScrollDropdown,
    isSearchable: true,
    isPaged: true,
  },
  {
    name: 'MARKETING_COMPANY',
    param: 'marketing_company_id',
    subParam: 'fundraiser_id',
    type: 'MARKETING_COMPANY',
    object: 'MARKETING_COMPANY',
    childRenderComponent: filterChildRenderComponent.infiniteScrollMultiDropdown,
    isSearchable: true,
    isPaged: true,
  },
];

export const paymentMethodFilter = [
  // { id: 'BANK', name: 'ACH Direct Debit' },
  { id: 'CARD', name: 'Card' },
  { id: 'DIRECT_DEBIT', name: 'Direct Debit' },
];

export const declineCodeFilter = Object.entries(paymentDeclineCode).map(([key, value]) => {
  return { id: key, name: capitalize(formatText(key, ' ')) };
});

export const paymentTypeFilter = [
  { id: 'VISA', name: 'VISA' },
  { id: 'MASTERCARD', name: 'MASTERCARD' },
  { id: 'AMEX', name: 'AMERICAN_EXPRESS' },
  // { id: 'USBANK', name: 'US Bank' },
];

export const prizeDrawStatusFilter = [
  { id: '', name: 'All' },
  { id: 'ACTIVE', name: 'Active' },
  { id: 'INACTIVE', name: 'In-active' },
];

export const statusFilter = [
  { id: 'ACTIVE', name: 'ACTIVE' },
  { id: 'ARCHIVED', name: 'RETIRED' },
];
export const prizeDrawFormFilter = [
  { id: '', name: 'All' },
  { id: 'DEFAULT', name: 'Default' },
  { id: 'CUSTOM', name: 'Custom' },
];

export const productPaymentStatusFilter = [
  { id: 'ACTIVE', name: 'ACTIVE' },
  { id: 'CANCELED', name: 'CANCELED' },
  { id: 'PAST_DUE', name: 'PAST_DUE' },
  { id: 'UNPAID', name: 'UNPAID' },
  { id: 'PAUSED', name: 'PAUSED' },
  { id: 'INCOMPLETE', name: 'INCOMPLETE' },
  { id: 'INCOMPLETE_EXPIRED', name: 'INCOMPLETE_EXPIRED' },
  { id: 'CANCEL_PENDING', name: 'CANCEL_PENDING' },
];

export const drawStatusFilter = [
  { id: 'IN_DRAW', name: 'IN_DRAW' },
  { id: 'OUT_OF_DRAW', name: 'OUT_OF_DRAW' },
];

export const paymentFrequencyFilter = [
  { id: 'DAY', name: '$00 Daily' },
  { id: 'YEAR', name: '$00 Annually' },
  { id: 'MONTH ', name: '$00 Monthly' },
  { id: 'QUARTER ', name: '$00 Quarterly' },
];

export const inputPaymentFrequencyFilter = [
  { id: 'DAY', name: 'Daily' },
  { id: 'YEAR', name: 'Annually' },
  { id: 'MONTH ', name: 'Monthly' },
  { id: 'WEEK ', name: 'Weekly' },
  { id: 'BI_ANNUALLY', name: 'Bi Annually' },
  { id: 'QUARTERLY', name: 'Quarterly ' },
];

export const winStatusFilter = [
  { id: 'WINNER', name: 'Winner' },
  { id: 'ENTRANT ', name: 'Entrant' },
];

export const formListFilterOptions = [
  {
    name: 'PRIZEDRAW',
    param: 'prizedraw',
    type: 'PRIZEDRAW',
    object: 'PRIZEDRAW',
    childRenderComponent: filterChildRenderComponent.infiniteScrollDropdown,
    isPaged: true,
    isSearchable: true,
  },
  {
    name: 'STATUS',
    param: 'status',
    type: 'STATUS',
    object: 'STATUS',
    childRenderComponent: filterChildRenderComponent.dropDown,
    allowTranslateOption: true,
  },
];

export const prizeDrawFormsFilterOptions = [
  {
    name: 'STATUS',
    param: 'status',
    type: 'STATUS',
    object: 'STATUS',
    childRenderComponent: filterChildRenderComponent.dropDown,
    allowTranslateOption: true,
  },
];

export const prizeDrawFormStatusFilter = [
  { id: 'ACTIVE', name: 'ACTIVE' },
  { id: 'ARCHIVED', name: 'RETIRED' },
];

export const billingFeesFilterOptions = [
  {
    name: 'PRIZEDRAW',
    param: 'prizedraw_id',
    type: 'PRIZEDRAW',
    object: 'PRIZEDRAW',
    childRenderComponent: filterChildRenderComponent.infiniteScrollDropdown,
    isPaged: true,
  },
  {
    name: 'FREQUENCY',
    param: 'recurring_period',
    type: 'FREQUENCY',
    object: 'FREQUENCY',
    childRenderComponent: filterChildRenderComponent.dropDown,
  },
  {
    name: 'CLIENT',
    param: 'organization_id',
    type: 'CLIENT',
    object: 'CLIENT',
    childRenderComponent: filterChildRenderComponent.infiniteScrollDropdown,
    isSearchable: false,
    isPaged: true,
  },
  {
    name: 'BILLING_TYPE',
    param: 'billing_type',
    type: 'BILLING_TYPE',
    object: 'BILLING_TYPE',
    childRenderComponent: filterChildRenderComponent.dropDown,
  },
  {
    name: 'AMOUNT',
    minParam: 'amount.min',
    maxParam: 'amount.max',
    type: 'AMOUNT',
    object: 'AMOUNT',
    childRenderComponent: filterChildRenderComponent.minMax,
  },
];

export const dashboardSassFilterOptions = [
  {
    name: 'PAYMENT_STATUS',
    param: 'product_status',
    type: 'PAYMENT_STATUS',
    object: 'PAYMENT_STATUS',
    childRenderComponent: filterChildRenderComponent.dropDown,
    allowTranslateOption: true,
  },
  {
    name: 'DRAW_STATUS',
    param: 'draw_status',
    type: 'DRAW_STATUS',
    object: 'DRAW_STATUS',
    childRenderComponent: filterChildRenderComponent.dropDown,
    allowTranslateOption: true,
  },
  {
    name: 'WIN_STATUS',
    param: 'win_status',
    type: 'WIN_STATUS',
    object: 'WIN_STATUS',
    childRenderComponent: filterChildRenderComponent.dropDown,
  },
  {
    name: 'CREATED_DATE',
    minParam: 'created_date.min',
    maxParam: 'created_date.max',
    type: 'CREATED_DATE',
    object: 'CREATED_DATE',
    childRenderComponent: filterChildRenderComponent.dateRange,
  },
  {
    name: 'PAYMENT_AMOUNT',
    minParam: 'product_price.min',
    maxParam: 'product_price.max',
    type: 'PAYMENT_AMOUNT',
    object: 'PAYMENT_AMOUNT',
    childRenderComponent: filterChildRenderComponent.minMax,
  },
  {
    name: 'DONATION_FREQUENCY',
    countParam: 'interval_count',
    param: 'interval',
    type: 'DONATION_FREQUENCY',
    object: 'DONATION_FREQUENCY',
    childRenderComponent: filterChildRenderComponent.inputDropDown,
  },
  {
    name: 'PAYMENT_TYPE',
    param: 'payment_type',
    type: 'PAYMENT_TYPE',
    object: 'PAYMENT_TYPE',
    childRenderComponent: filterChildRenderComponent.dropDown,
    allowTranslateOption: true,
  },
  {
    name: 'AGENCY',
    param: 'agency_id',
    type: 'AGENCY',
    object: 'AGENCY',
    childRenderComponent: filterChildRenderComponent.infiniteScrollMultiDropdown,
    isSearchable: false,
    isPaged: true,
  },
  {
    name: 'MARKETING_COMPANY',
    param: 'marketing_company_id',
    subParam: 'fundraiser_id',
    type: 'MARKETING_COMPANY_DASHBOARD',
    object: 'MARKETING_COMPANY_DASHBOARD',
    childRenderComponent: filterChildRenderComponent.infiniteScrollMultiDropdown,
    isSearchable: true,
    isPaged: true,
  },
  {
    name: 'PRIZE_DRAW',
    param: 'prizedraw_id',
    type: 'PRIZEDRAW',
    object: 'PRIZEDRAW',
    childRenderComponent: filterChildRenderComponent.infiniteScrollDropdown,
    isPaged: true,
  },
  {
    name: 'FUNDRAISER',
    param: 'fundraiser_id',
    type: 'FUNDRAISER',
    object: 'FUNDRAISER',
    childRenderComponent: filterChildRenderComponent.infiniteScrollDropdown,
    isSearchable: true,
    isPaged: true,
  },
  {
    name: 'FUNDRAISER_BADGE',
    param: 'fundraiser_id',
    type: 'FUNDRAISER_BADGE',
    object: 'FUNDRAISER_BADGE',
    childRenderComponent: filterChildRenderComponent.infiniteScrollDropdown,
    isSearchable: true,
    isPaged: true,
  },
];
export const dashboardClientFilterOptions = [
  {
    name: 'PAYMENT_STATUS',
    param: 'product_status',
    type: 'PAYMENT_STATUS',
    object: 'PAYMENT_STATUS',
    childRenderComponent: filterChildRenderComponent.dropDown,
    allowTranslateOption: true,
  },
  {
    name: 'DRAW_STATUS',
    param: 'draw_status',
    type: 'DRAW_STATUS',
    object: 'DRAW_STATUS',
    childRenderComponent: filterChildRenderComponent.dropDown,
    allowTranslateOption: true,
  },
  {
    name: 'WIN_STATUS',
    param: 'win_status',
    type: 'WIN_STATUS',
    object: 'WIN_STATUS',
    childRenderComponent: filterChildRenderComponent.dropDown,
  },
  {
    name: 'CREATED_DATE',
    minParam: 'created_date.min',
    maxParam: 'created_date.max',
    type: 'CREATED_DATE',
    object: 'CREATED_DATE',
    childRenderComponent: filterChildRenderComponent.dateRange,
  },
  {
    name: 'PAYMENT_TYPE',
    param: 'payment_type',
    type: 'PAYMENT_TYPE',
    object: 'PAYMENT_TYPE',
    childRenderComponent: filterChildRenderComponent.dropDown,
    allowTranslateOption: true,
  },
  {
    name: 'PAYMENT_AMOUNT',
    minParam: 'product_price.min',
    maxParam: 'product_price.max',
    type: 'PAYMENT_AMOUNT',
    object: 'PAYMENT_AMOUNT',
    childRenderComponent: filterChildRenderComponent.minMax,
  },
  {
    name: 'DONATION_FREQUENCY',
    countParam: 'interval_count',
    param: 'interval',
    type: 'DONATION_FREQUENCY',
    object: 'DONATION_FREQUENCY',
    childRenderComponent: filterChildRenderComponent.inputDropDown,
  },
  {
    name: 'PRIZE_DRAW',
    param: 'prizedraw_id',
    type: 'PRIZEDRAW',
    object: 'PRIZEDRAW',
    childRenderComponent: filterChildRenderComponent.infiniteScrollDropdown,
    isPaged: true,
  },
];
export const dashboardMarketingCompanyFilterOptions = [
  {
    name: 'PAYMENT_STATUS',
    param: 'product_status',
    type: 'PAYMENT_STATUS',
    object: 'PAYMENT_STATUS',
    childRenderComponent: filterChildRenderComponent.dropDown,
    allowTranslateOption: true,
  },
  {
    name: 'DRAW_STATUS',
    param: 'draw_status',
    type: 'DRAW_STATUS',
    object: 'DRAW_STATUS',
    childRenderComponent: filterChildRenderComponent.dropDown,
    allowTranslateOption: true,
  },
  {
    name: 'WIN_STATUS',
    param: 'win_status',
    type: 'WIN_STATUS',
    object: 'WIN_STATUS',
    childRenderComponent: filterChildRenderComponent.dropDown,
  },
  {
    name: 'CREATED_DATE',
    minParam: 'created_date.min',
    maxParam: 'created_date.max',
    type: 'CREATED_DATE',
    object: 'CREATED_DATE',
    childRenderComponent: filterChildRenderComponent.dateRange,
  },
  {
    name: 'PAYMENT_TYPE',
    param: 'payment_type',
    type: 'PAYMENT_TYPE',
    object: 'PAYMENT_TYPE',
    childRenderComponent: filterChildRenderComponent.dropDown,
    allowTranslateOption: true,
  },
  {
    name: 'PAYMENT_AMOUNT',
    minParam: 'product_price.min',
    maxParam: 'product_price.max',
    type: 'PAYMENT_AMOUNT',
    object: 'PAYMENT_AMOUNT',
    childRenderComponent: filterChildRenderComponent.minMax,
  },
  {
    name: 'FUNDRAISER',
    param: 'fundraiser_id',
    type: 'FUNDRAISER',
    object: 'FUNDRAISER',
    childRenderComponent: filterChildRenderComponent.infiniteScrollDropdown,
    isSearchable: true,
    isPaged: true,
  },
  {
    name: 'PRIZE_DRAW',
    param: 'prizedraw_id',
    type: 'PRIZEDRAW',
    object: 'PRIZEDRAW',
    childRenderComponent: filterChildRenderComponent.infiniteScrollDropdown,
    isPaged: true,
  },
];
export const dashboardAgencyFilterOptions = [
  {
    name: 'PAYMENT_STATUS',
    param: 'product_status',
    type: 'PAYMENT_STATUS',
    object: 'PAYMENT_STATUS',
    childRenderComponent: filterChildRenderComponent.dropDown,
    allowTranslateOption: true,
  },
  {
    name: 'DRAW_STATUS',
    param: 'draw_status',
    type: 'DRAW_STATUS',
    object: 'DRAW_STATUS',
    childRenderComponent: filterChildRenderComponent.dropDown,
    allowTranslateOption: true,
  },
  {
    name: 'WIN_STATUS',
    param: 'win_status',
    type: 'WIN_STATUS',
    object: 'WIN_STATUS',
    childRenderComponent: filterChildRenderComponent.dropDown,
  },
  {
    name: 'CREATED_DATE',
    minParam: 'created_date.min',
    maxParam: 'created_date.max',
    type: 'CREATED_DATE',
    object: 'CREATED_DATE',
    childRenderComponent: filterChildRenderComponent.dateRange,
  },
  {
    name: 'PAYMENT_TYPE',
    param: 'payment_type',
    type: 'PAYMENT_TYPE',
    object: 'PAYMENT_TYPE',
    childRenderComponent: filterChildRenderComponent.dropDown,
    allowTranslateOption: true,
  },
  {
    name: 'PAYMENT_AMOUNT',
    minParam: 'product_price.min',
    maxParam: 'product_price.max',
    type: 'PAYMENT_AMOUNT',
    object: 'PAYMENT_AMOUNT',
    childRenderComponent: filterChildRenderComponent.minMax,
  },
  {
    name: 'MARKETING_COMPANY',
    param: 'marketing_company_id',
    subParam: 'fundraiser_id',
    type: 'MARKETING_COMPANY_DASHBOARD',
    object: 'MARKETING_COMPANY_DASHBOARD',
    childRenderComponent: filterChildRenderComponent.infiniteScrollMultiDropdown,
    isSearchable: true,
    isPaged: true,
  },
  {
    name: 'PRIZE_DRAW',
    param: 'prizedraw_id',
    type: 'PRIZEDRAW',
    object: 'PRIZEDRAW',
    childRenderComponent: filterChildRenderComponent.infiniteScrollDropdown,
    isPaged: true,
  },
];

export const frequencyFilter = [
  { id: 'YEAR', name: 'Annually' },
  { id: 'MONTH', name: 'Monthly' },
];

export const billingTypeFilter = [
  { id: 'VARIABLE', name: 'CONTINUAL_BILLING' },
  { id: 'FIXED', name: 'Cohort billing' },
];
if (
  user &&
  user?.organizations &&
  !['Agency User', 'Marketing Company Admin', 'Marketing Company Manager'].includes(
    (user?.organizations || [])[0]?.role?.name || '',
  )
) {
  dashboardAgencyFilterOptions.push({
    name: 'CLIENT_DRAW_CODE',
    param: 'prizedraw_client_code',
    type: 'CLIENT_DRAW_CODE',
    object: 'CLIENT_DRAW_CODE',
    childRenderComponent: filterChildRenderComponent.dropDown,
  });
  entrantsFilterOptions.push({
    name: 'CLIENT_DRAW_CODE',
    param: 'prizedraw_client_code',
    type: 'CLIENT_DRAW_CODE',
    object: 'CLIENT_DRAW_CODE',
    childRenderComponent: filterChildRenderComponent.dropDown,
  });
  entrantsFilterOptionsForSAASOperator.push({
    name: 'CLIENT',
    param: 'organization_id',
    type: 'CLIENT',
    object: 'CLIENT',
    childRenderComponent: filterChildRenderComponent.infiniteScrollDropdown,
    isSearchable: false,
    isPaged: true,
  });
  // formListFilterOptions.push({
  //   name: 'Client',
  //   param: 'organization_id',
  //   type: 'CLIENT',
  //   object: 'CLIENT',
  //   childRenderComponent: filterChildRenderComponent.infiniteScrollDropdown,
  //   isSearchable: false,
  //   isPaged: true,
  // });
  billingFeesFilterOptions.push({
    name: 'CLIENT_DRAW_CODE',
    param: 'prizedraw_client_code',
    type: 'CLIENT_DRAW_CODE',
    object: 'CLIENT_DRAW_CODE',
    childRenderComponent: filterChildRenderComponent.dropDown,
  });
  dashboardSassFilterOptions.push({
    name: 'CLIENT_DRAW_CODE',
    param: 'prizedraw_client_code',
    type: 'CLIENT_DRAW_CODE',
    object: 'CLIENT_DRAW_CODE',
    childRenderComponent: filterChildRenderComponent.dropDown,
  });
  dashboardSassFilterOptions.push({
    name: 'CLIENT',
    param: 'organization_id',
    type: 'CLIENT',
    object: 'CLIENT',
    childRenderComponent: filterChildRenderComponent.infiniteScrollDropdown,
    isSearchable: false,
    isPaged: true,
  });
  dashboardMarketingCompanyFilterOptions.push({
    name: 'CLIENT_DRAW_CODE',
    param: 'prizedraw_client_code',
    type: 'CLIENT_DRAW_CODE',
    object: 'CLIENT_DRAW_CODE',
    childRenderComponent: filterChildRenderComponent.dropDown,
  });
  dashboardClientFilterOptions.push({
    name: 'CLIENT_DRAW_CODE',
    param: 'prizedraw_client_code',
    type: 'CLIENT_DRAW_CODE',
    object: 'CLIENT_DRAW_CODE',
    childRenderComponent: filterChildRenderComponent.dropDown,
  });
}

if (user && user?.organizations && !['Agency User'].includes(user?.organizations[0]?.role?.name)) {
  formListFilterOptions.push(
    {
      name: 'LABEL',
      param: 'form_type_id',
      type: 'FORM_TYPE',
      object: 'FORM_TYPE',
      childRenderComponent: filterChildRenderComponent.infiniteScrollDropdown,
    },
    {
      name: 'CLIENT',
      param: 'organization_id',
      type: 'CLIENT',
      object: 'CLIENT',
      childRenderComponent: filterChildRenderComponent.infiniteScrollDropdown,
      isSearchable: false,
      isPaged: true,
    },
  );
  prizeDrawFormsFilterOptions.push(
    {
      name: 'LABEL',
      param: 'form_type_id',
      type: 'FORM_TYPE',
      object: 'FORM_TYPE',
      childRenderComponent: filterChildRenderComponent.infiniteScrollDropdown,
    },
    {
      name: 'CLIENT',
      param: 'organization_id',
      type: 'CLIENT',
      object: 'CLIENT',
      childRenderComponent: filterChildRenderComponent.infiniteScrollDropdown,
      isSearchable: false,
      isPaged: true,
    },
  );
}

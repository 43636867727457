import React, { Fragment, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowRightIcon } from '../../../assets/images/arrow-right.svg';
import { ReactComponent as DragHandleIcon } from '../../../assets/images/drag-handle.svg';
import { ReactComponent as EditIcon } from '../../../assets/images/edit.svg';
import { ReactComponent as PlusIcon } from '../../../assets/images/plus.svg';
import Button from '../../../components/Elements/button/button';
import Dropdown from '../../../components/FormElements/dropdown';
import InputElement from '../../../components/FormElements/input';
import Switch from '../../../components/common/switch';
import UpdateAction from '../../../components/common/update-action';
import FormBuilderContext from '../../../context/FormBuilderContext';
import { PaymentConfigWrapper } from '../edit-form-version.styled';

const ProductsConfig = ({ component, setSelectedComponent = () => {}, integration, isSameComponent }) => {
  const { onAddProductsList, onUpdateProduct, selectedIntegrationInComponent } = useContext(FormBuilderContext);
  const { products: componentProducts } = component;
  const { t } = useTranslation();
  const products = isSameComponent ? componentProducts : selectedIntegrationInComponent?.products;

  const [showPrizes, setShowPrizes] = useState({});
  const [updatePrize, setUpdatePrize] = useState({});

  const onUpdatePrizeName = async selectedProduct => {
    const newProducts = products.map(s =>
      s.external_reference === selectedProduct.external_reference
        ? {
            ...selectedProduct,
            prices: selectedProduct.prices?.map(p =>
              p.external_reference === updatePrize.external_reference
                ? { ...p, pricing: { ...p.pricing, display_as: updatePrize.name } }
                : p,
            ),
          }
        : s,
    );
    await onUpdateProduct(newProducts, component);
    setSelectedComponent({ ...component, products: newProducts });
    setUpdatePrize({});
  };

  return (
    <PaymentConfigWrapper className="flex-column my-6">
      <hr />
      <label className="flex-1 medium-text font-16 mb-4">{t('PRODUCTS_UC')}</label>
      <Button
        size="average"
        className="flex items-center justify-center background-primary specified-width add-option-btn w-full"
        label="Add product"
        icon={<PlusIcon />}
        disabled={!isSameComponent}
        borderRadius="16px"
        width="100%"
        onClick={() => onAddProductsList(integration, component)}
      />
      {products?.length > 0 && (
        <div className="flex-column mt-4">
          {products?.map(product => (
            <div
              className={`flex-column border radius-4 py-2 px-3 justify-center mb-4 ${
                showPrizes === product.external_reference ? 'show-prize' : 'hide-prize'
              }`}
              key={`selected-product-${product.external_reference}`}>
              <div className="flex items-center">
                <DragHandleIcon className="mr-2" />
                <div
                  className="flex cursor flex-1 items-center"
                  onClick={() =>
                    setShowPrizes(product.external_reference === showPrizes ? null : product.external_reference)
                  }>
                  <label className="regular-text font-16 flex-1">{product.name}</label>
                  <ArrowRightIcon
                    className={`cursor mr-1 ${showPrizes === product.external_reference && 'rotate-90'}`}
                    onClick={() => setShowPrizes(product.external_reference)}
                  />
                </div>
              </div>
              {product.prices?.length > 0 &&
                product.prices.map(price => (
                  <div className="flex ml-6 radius-3 prize-container cursor hover-edit">
                    {updatePrize.external_reference === price.external_reference ? (
                      <div className="flex flex-1 relative mb-1">
                        <input
                          className="input radius-2 font-14 flex-1 prize-input"
                          placeholder="Prize name"
                          value={updatePrize.name}
                          onChange={({ target: { value } }) => setUpdatePrize({ ...updatePrize, name: value })}
                        />
                        <label className="regular-text ml-1">${price?.pricing?.price}</label>
                        <UpdateAction
                          className="update-action"
                          onCancel={e => {
                            e.stopPropagation();
                            setUpdatePrize({});
                          }}
                          onUpdate={e => {
                            e.stopPropagation();
                            onUpdatePrizeName(product);
                          }}
                        />
                      </div>
                    ) : (
                      <Fragment>
                        <div
                          className="flex flex-1"
                          onClick={() =>
                            isSameComponent &&
                            setUpdatePrize({
                              ...price,
                              name: price?.pricing?.display_as || price?.pricing?.recurring_period,
                            })
                          }>
                          <label className="regular-text lighter-text">
                            {price?.pricing?.display_as || price?.pricing?.recurring_period}:
                          </label>
                          <label className="regular-text ml-1">${price?.pricing?.price}</label>
                        </div>
                        {isSameComponent && <EditIcon className="edit-icon" />}
                      </Fragment>
                    )}
                  </div>
                ))}
            </div>
          ))}
        </div>
      )}
    </PaymentConfigWrapper>
  );
};

export const ProductGeneralConfig = ({ component, setSelectedComponent = () => {} }) => {
  const { integrations, getIntegrationDetails, selectedIntegrationInComponent } = useContext(FormBuilderContext);
  const { name, integration: componentIntegration, label, sub_label, is_required } = component;
  const { t } = useTranslation();
  const isSameComponent =
    (selectedIntegrationInComponent && selectedIntegrationInComponent?.id === component?.id) ||
    !selectedIntegrationInComponent;
  const integration = isSameComponent ? componentIntegration : selectedIntegrationInComponent?.integration;

  const onChangeOption = async option => {
    let { public_key, id, name } = option;
    if (!public_key) {
      const listDetail = await getIntegrationDetails(id);
      public_key = listDetail.public_key;
    }
    setSelectedComponent({ ...component, integration: { id, name, public_key } });
  };

  return (
    <div className="flex-column my-8">
      <label className="flex-1 medium-text font-16">{t('GENERAL_UC')}</label>
      <InputElement
        name="Name"
        onChange={value => setSelectedComponent({ ...component, name: value })}
        value={name}
        label="Enter"
        className="w-full mt-6"
        font={{ size: 12 }}
        type={'TEXTAREA'}
        showRequiredError={true}
      />
      <InputElement
        name="Label"
        onChange={value => setSelectedComponent({ ...component, label: value })}
        value={label}
        label="Enter"
        className="w-full mt-6"
        font={{ size: 12 }}
      />
      <InputElement
        name="Sub-label"
        onChange={value => setSelectedComponent({ ...component, sub_label: value })}
        value={sub_label}
        label="Enter"
        className="w-full mt-6"
        font={{ size: 12 }}
      />
      <Dropdown
        className="mt-6"
        name="Integration"
        placeholder="Select"
        options={integrations}
        font={{ size: 12 }}
        isDisabled={!isSameComponent}
        selectedValue={integrations.find(c => c.value === integration?.id)}
        onChange={option => onChangeOption(option)}
      />
      <div className="flex mt-6">
        <label className="regular-text flex-1">{t('REQUIRED')}</label>
        <Switch
          enabled={is_required}
          onClick={() => setSelectedComponent({ ...component, is_required: !is_required })}
        />
      </div>
      {integration && (
        <ProductsConfig
          isSameComponent={isSameComponent}
          component={component}
          setSelectedComponent={setSelectedComponent}
          integration={integration}
        />
      )}
    </div>
  );
};

import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ReactComponent as ArrowRightIcon } from '../../../assets/images/arrow-right-black.svg';
import { ReactComponent as EditIcon } from '../../../assets/images/edit.svg';
import { ReactComponent as Loading } from '../../../assets/images/loading.svg';
import { ReactComponent as MinusIcon } from '../../../assets/images/minus.svg';
import { ReactComponent as PlusIcon } from '../../../assets/images/plus.svg';
import UpdateAction from '../../../components/common/update-action';
import { addToast } from '../../../store/features/toastSlice';
import api from '../../../store/services/index';
import CommonPopup from '../../common-popup/index';
import { AddProductsListWrapper } from './index.styled';

const AddProductsList = ({ modal, setModal }) => {
  const dispatch = useDispatch();
  const { addProductList, integration } = modal.content || {};
  const [loading, setLoading] = useState(false);
  const [isFetchingProducts, setIsFetchingProducts] = useState(false);
  const [products, setProducts] = useState([]);
  const [unSelectedProducts, setUnSelectedProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [showPrizes, setShowPrizes] = useState('');
  const [updatePrize, setUpdatePrize] = useState({});

  useEffect(() => {
    const getProducts = async () => {
      try {
        setIsFetchingProducts(true);
        const { data } = await api.get(`/products`, { params: { integration_id: integration.id } });
        const newProducts = data?.content?.map(p => ({ ...p, pricing: p.pricing?.[0] })) || [];
        setProducts(newProducts);
        setUnSelectedProducts(newProducts);
        setSelectedProducts([]);
        setIsFetchingProducts(false);
      } catch (error) {
        setIsFetchingProducts(false);
      }
    };
    getProducts();
  }, []);

  useEffect(() => {
    const selectedProductIds = selectedProducts.map(sp => sp.external_reference);
    const unSelectedProducts = products.filter(p => !selectedProductIds.includes(p.external_reference));
    setUnSelectedProducts(unSelectedProducts);
  }, [selectedProducts]);

  const onDone = async () => {
    if (!selectedProducts.length) {
      dispatch(addToast({ error: true, text: 'Please select products' }));
      return;
    }
    if (loading) {
      return;
    }
    try {
      setLoading(true);
      const request = selectedProducts.map(({ id, sku, pricing, ...rest }) => {
        const { recurring_period, ...restPricing } = pricing;
        return {
          ...rest,
          integration: {
            id: integration.id,
          },
          pricing: [
            {
              ...restPricing,
              display_as: restPricing.display_as || rest.name,
              recurring: { period_type: recurring_period, period: restPricing.trial.period || 0 },
            },
          ],
        };
      });
      await addProductList(request);
      setModal(null);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const onUpdatePrizeName = selected => {
    const newProducts = selectedProducts.map(s =>
      s.external_reference === selected.external_reference
        ? { ...selected, pricing: { ...selected.pricing, display_as: updatePrize.name } }
        : s,
    );
    setSelectedProducts(newProducts);
    setUpdatePrize({});
  };

  return (
    <CommonPopup
      disabled={loading}
      popupTitle={'Add products'}
      confirmButtonProps={{ label: 'Add', className: 'primary' }}
      onCancel={() => setModal(null)}
      onConfirm={onDone}>
      <AddProductsListWrapper className="w-full radius-3 mt-6">
        {isFetchingProducts ? (
          <div className="item flex items-center justify-center my-4">
            <Loading height={32} width={32} />
          </div>
        ) : (
          <Fragment>
            {unSelectedProducts.map(product => (
              <div
                className={`flex-column border radius-4 py-2 px-3 justify-center mb-2 ${
                  showPrizes === product.external_reference ? 'show-prize' : 'hide-prize'
                }`}
                key={`product-${product.external_reference}`}>
                <div className="flex items-center">
                  <div
                    className="flex cursor radius-1 items-center justify-center plus divider-border mr-2"
                    onClick={() => setSelectedProducts([...selectedProducts, product])}>
                    <PlusIcon width={10} height={10} />
                  </div>
                  <div
                    className="flex cursor flex-1 items-center"
                    onClick={() =>
                      setShowPrizes(product.external_reference === showPrizes ? null : product.external_reference)
                    }>
                    <label className="regular-text font-16 flex-1">{product.name}</label>
                    <ArrowRightIcon className={`cursor ${showPrizes === product.external_reference && 'rotate-90'}`} />
                  </div>
                </div>
                {product.pricing && (
                  <div className="flex ml-6 radius-3 prize-container">
                    <label className="regular-text lighter-text">{product.pricing.display_as || product.name}:</label>
                    <label className="regular-text ml-1">${product.pricing.price}</label>
                  </div>
                )}
              </div>
            ))}
            {selectedProducts.length > 0 && (
              <Fragment>
                <hr className="flex my-6" />
                <label className="flex regular-text font-16 mb-2">{`Selected (${selectedProducts.length})`}</label>
                {selectedProducts.map(product => (
                  <div
                    className={`flex-column border radius-4 py-2 px-3 justify-center mb-2 ${
                      showPrizes === product.external_reference ? 'show-prize' : 'hide-prize'
                    }`}
                    key={`selected-product-${product.external_reference}`}>
                    <div className="flex items-center">
                      <div
                        className="flex cursor radius-1 items-center justify-center minus divider-border mr-2"
                        onClick={() =>
                          setSelectedProducts(
                            selectedProducts.filter(sp => sp.external_reference !== product.external_reference),
                          )
                        }>
                        <MinusIcon width={10} height={10} />
                      </div>
                      <div
                        className="flex cursor flex-1 items-center"
                        onClick={() =>
                          setShowPrizes(product.external_reference === showPrizes ? null : product.external_reference)
                        }>
                        <label className="regular-text font-16 flex-1">{product.name}</label>
                        <ArrowRightIcon
                          className={`cursor ${showPrizes === product.external_reference && 'rotate-90'}`}
                          onClick={() => setShowPrizes(product.external_reference)}
                        />
                      </div>
                    </div>
                    {product.pricing && (
                      <div className="flex ml-6 radius-3 prize-container cursor hover-edit">
                        {updatePrize.external_reference === product.pricing.external_reference ? (
                          <div className="flex flex-1 relative">
                            <input
                              className="input radius-2 font-14 flex-1 prize-input"
                              placeholder="Prize name"
                              value={updatePrize.name}
                              onChange={({ target: { value } }) => setUpdatePrize({ ...updatePrize, name: value })}
                            />
                            <label className="regular-text ml-1">${product.pricing.price}</label>
                            <UpdateAction
                              className="update-action"
                              onCancel={e => {
                                e.stopPropagation();
                                setUpdatePrize({});
                              }}
                              onUpdate={e => {
                                e.stopPropagation();
                                onUpdatePrizeName(product);
                              }}
                            />
                          </div>
                        ) : (
                          <Fragment>
                            <div
                              className="flex flex-1"
                              onClick={() => setUpdatePrize({ ...product.pricing, name: product.name })}>
                              <label className="regular-text lighter-text">
                                {product.pricing.display_as || product.name}:
                              </label>
                              <label className="regular-text ml-1">${product.pricing.price}</label>
                            </div>
                            <EditIcon className="edit-icon" />
                          </Fragment>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </Fragment>
            )}
          </Fragment>
        )}
      </AddProductsListWrapper>
    </CommonPopup>
  );
};

export default AddProductsList;

import styled from 'styled-components';

export const AutomationDetailsWrapper = styled.div`
  max-height: 100vh;
  overflow: hidden;
`;

export const FormDetailsWrapper = styled.div`
  max-height: 100vh;
  overflow: hidden;
`;

import React, { useEffect } from 'react';
import { CustomPicker } from 'react-color';
import { Hue, Saturation } from 'react-color/lib/components/common';
import MaskedInput from 'react-text-mask';
import styled from 'styled-components';

function Picker() {
  return (
    <div
      style={{
        width: 16,
        height: 16,
        borderRadius: 20,
        background: 'rgba(255,255,255,0.2)',
        border: '2px solid white',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
        boxSizing: 'border-box',
      }}
    />
  );
}

const CustomColorPicker = ({ hex, hsl, hsv, onChange, setColor, close }) => {
  const defaultColors = ['#FFFFFF', '#7BB2FD', '#E2E7EF', '#8927EF'];

  useEffect(() => {
    const colorPickerContainer = document.getElementById('color-picker-container');
    if (colorPickerContainer) {
      colorPickerContainer.focus();
    }
  }, []);

  const onBlur = e => {
    e.preventDefault();
    if (!e.currentTarget.contains(e.relatedTarget)) {
      close();
    }
  };

  return (
    <>
      <CustomColorPickerWrapper
        className="flex-column card absolute"
        onBlur={onBlur}
        tabIndex={-1}
        id="color-picker-container">
        <div className="flex-column">
          <div className="flex pxy-4">
            <div className="cp-saturation relative">
              <Saturation hsl={hsl} hsv={hsv} onChange={onChange} pointer={Picker} />
            </div>
            <div className="cp-hue relative">
              <Hue hsl={hsl} onChange={onChange} direction="vertical" pointer={Picker} />
            </div>
          </div>
          <div className="flex items-center justify-between px-4 pb-4 border-bottom">
            <label className="regular-text font-12">Custom color</label>
            <MaskedInput
              mask={[
                '#',
                /[A-Z,a-z,0-9]/,
                /[A-Z,a-z,0-9]/,
                /[A-Z,a-z,0-9]/,
                /[A-Z,a-z,0-9]/,
                /[A-Z,a-z,0-9]/,
                /[A-Z,a-z,0-9]/,
              ]}
              guide={false}
              className="input custom-color-input"
              value={hex}
              onChange={e => setColor(e.target.value)}
            />
          </div>
          <div className="flex items-center pxy-4">
            {defaultColors.map(c => (
              <div
                className="default-color mr-2 cursor radius-1 divider-border"
                style={{ background: c }}
                onClick={() => setColor(c)}
              />
            ))}
          </div>
        </div>
      </CustomColorPickerWrapper>
    </>
  );
};

const CustomColorPickerWrapper = styled.div`
  z-index: 5;
  top: 35px;
  right: 0;
  width: 225px;
  box-shadow: 0px 6px 30px rgba(5, 49, 73, 0.14);

  &::before {
    content: '';
    background: white;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%) rotate(45deg);
    position: absolute;
    left: 61%;
    top: 0px;
  }

  .cp-saturation {
    width: 170px;
    height: 170px;

    .saturation-black,
    .saturation-white {
      border-radius: 8px;
    }

    & > div {
      border-radius: 8px;
    }
  }

  .cp-hue {
    width: 16px;
    height: 168px;

    .hue-vertical {
      right: -8px;
      border-radius: 100px;
    }
  }

  .default-color {
    width: 16px;
    height: 16px;
  }

  .custom-color-input {
    height: 24px;
    font-size: 12px;
    width: 88px;
    padding: 0 12px;
  }
`;

export default CustomPicker(CustomColorPicker);

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as HamburgerIcon } from '../../../../assets/images/burger.svg';
import { OrganisationContext } from '../../../../context/organisationContext';
import { handleCopyClick } from '../../../../helpers/utils';
import { addToast } from '../../../../store/features/toastSlice';
import { FormLogWrapper } from '../../../../styles/components/formDetails/form-details.styled';
import Button from '../../../Elements/button/button';
import Menu from '../../../common/menu';
import FormDetailsHeader from '../form-details-header';

const ShareOptions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, form_id } = useParams();
  const { hasPermission } = useContext(OrganisationContext);
  const { t } = useTranslation();
  const onCopyClick = async (data, key) => {
    const response = await handleCopyClick(data);
    if (response) {
      dispatch(
        addToast({
          text: `${key} copied to your clipboard`,
        }),
      );
    }
  };

  const onFormLog = () => {
    navigate('../form-log');
  };

  const onShareOption = () => {
    navigate('../share-options');
  };

  const menuItemsWithPermission = [
    { name: 'Form log', onClick: () => onFormLog(), key: 'FORMS_VERSIONS_VIEW' },
    { name: 'Share options', onClick: () => onShareOption(), key: 'FORM_SHAREOPTIONS_VIEW' },
  ];

  const filteredMenuItems = menuItemsWithPermission.filter(permission => hasPermission(permission.key));

  const formIdFromUrl = form_id ? form_id : id;

  return (
    <FormLogWrapper className="flex-column items-start justify-start w-full h-full">
      <FormDetailsHeader
        title={'Share options'}
        actionContent={
          <>
            <Menu menuClassName="ml-4" Icon={HamburgerIcon} menuList={[...filteredMenuItems]} />
          </>
        }
      />
      <div className="flex-column flex-1 pxy-4 w-full gap-6 h-full overflow-scroll">
        <div className="border flex-column radius-3 pb-6 pt-4 pr-6">
          <div className="flex items-center header-border-left relative">
            <div className="flex items-center pl-5 flex-1">
              <div className="dot ml-1" />
              <label className="medium-text font-16 ml-2">{t('SHARE')}</label>
            </div>
          </div>
          <div className="border mt-1 px-3 py-4 radius-2 ml-6">
            <label className="regular-text no-select" style={{ letterSpacing: '0.2px' }}>
              {`https://forms.${process.env.REACT_APP_BASE_DOMAIN}/#/launch/${id}`}
            </label>
          </div>
          {hasPermission('COPY_SHAREOPTIONS') && (
            <div className="flex flex-1 justify-end mt-2">
              <Button
                className="default-secondary"
                label={'Copy'}
                size={'medium'}
                onClick={() =>
                  onCopyClick(
                    `https://forms.${process.env.REACT_APP_BASE_DOMAIN}/#/launch/${formIdFromUrl}`,
                    'Form link',
                  )
                }
              />
            </div>
          )}
        </div>

        <div className="border flex-column radius-3 pb-6 pt-4 pr-6">
          <div className="flex items-center header-border-left relative">
            <div className="flex items-center pl-5 flex-1">
              <div className="dot ml-1" />
              <label className="medium-text font-16 ml-2">{t('IFRAME')}</label>
            </div>
          </div>
          <div
            className="border mt-1 px-3 py-4 radius-2 ml-6 iframe-text-truncate"
            style={{ background: '#16192c', overflow: 'scroll', height: '160px' }}>
            <label
              className="regular-text no-select  "
              style={{ letterSpacing: '0.2px', lineHeight: '21px', color: '#fff' }}>
              {`<iframe id=forms-${formIdFromUrl} title="Form" onload="window.parent.scrollTo(0,0)" allowtransparency="true" allowfullscreen="true" src="https://forms.${process.env.REACT_APP_BASE_DOMAIN}/#/launch/${formIdFromUrl}" frameborder="0" style="min-width:100%;max-width:100%;height:100dvh;border:none;" scrolling="no"></iframe>
              `}
            </label>
          </div>
          {hasPermission('COPY_SHAREOPTIONS') && (
            <div className="flex flex-1 justify-end mt-2">
              <Button
                className="default-secondary"
                label={'Copy'}
                size={'medium'}
                onClick={() =>
                  onCopyClick(
                    `<iframe id=forms-${formIdFromUrl} title="Form" onload="window.parent.scrollTo(0,0)" allowtransparency="true" allowfullscreen="true" src="https://forms.${process.env.REACT_APP_BASE_DOMAIN}/#/launch/${formIdFromUrl}" frameborder="0" style="min-width:100%;max-width:100%;height:100dvh;border:none;" scrolling="no"></iframe>`,
                    'Iframe link',
                  )
                }
              />
            </div>
          )}
        </div>
      </div>
    </FormLogWrapper>
  );
};

export default ShareOptions;

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Dropdown from '../../../components/FormElements/dropdown';
import InputElement from '../../../components/FormElements/input';
import FormBuilderContext from '../../../context/FormBuilderContext';

export const PaymentGeneralConfig = ({ component, setSelectedComponent = () => {} }) => {
  const { integrations, getIntegrationDetails, selectedIntegrationInComponent } = useContext(FormBuilderContext);
  const { name, integration: componentIntegration } = component;
  const { t } = useTranslation();
  const isSameComponent =
    (selectedIntegrationInComponent && selectedIntegrationInComponent?.id === component?.id) ||
    !selectedIntegrationInComponent;
  const integration = isSameComponent ? componentIntegration : selectedIntegrationInComponent?.integration;

  const onChangeOption = async option => {
    let { public_key, id, name } = option;
    if (!public_key) {
      const listDetail = await getIntegrationDetails(id);
      public_key = listDetail.public_key;
    }
    setSelectedComponent({ ...component, integration: { id, name, public_key } });
  };

  return (
    <div className="flex-column my-8">
      <label className="flex-1 medium-text font-16">{t('GENERAL_UC')}</label>
      <InputElement
        name="Name"
        onChange={value => setSelectedComponent({ ...component, name: value })}
        value={name}
        label="Enter"
        className="w-full mt-6"
        font={{ size: 12 }}
        type={'TEXTAREA'}
        showRequiredError={true}
      />
      <Dropdown
        className="mt-6"
        name="Integration"
        placeholder="Select"
        options={integrations}
        font={{ size: 12 }}
        isDisabled={!isSameComponent}
        selectedValue={integrations.find(c => c.value === integration?.id)}
        onChange={option => onChangeOption(option)}
      />
    </div>
  );
};

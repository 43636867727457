import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import uuid from 'react-uuid';
import styled from 'styled-components';
import Address from '../../../components/FormElements/address';
import Button from '../../../components/FormElements/button/button';
import Checkbox from '../../../components/FormElements/checkbox';
import DateOfBirthDayMonthYear from '../../../components/FormElements/date-of-birth/DayMonthYear';
import DateSelectorElement from '../../../components/FormElements/date-selector';
import Dropdown from '../../../components/FormElements/dropdown';
import FormPhoneInput from '../../../components/FormElements/form-phone-input';
import HeaderElement from '../../../components/FormElements/header';
import ImageElement from '../../../components/FormElements/image-element';
import InputElement from '../../../components/FormElements/input';
import Payment from '../../../components/FormElements/payment';
import Signature from '../../../components/FormElements/signature';
import FormBuilderContext from '../../../context/FormBuilderContext';
import { getItemFromLocalStorage } from '../../../helpers/localstorage';
import { getAlignment, getFontFamily } from '../../../helpers/utils';
import { addToast } from '../../../store/features/toastSlice';
import ProgressBar from '../progress-bar';
import ProgressSteps from '../progress-steps';

const ContentPreview = ({ selectedDevice, landscape }) => {
  const dispatch = useDispatch();
  const user = getItemFromLocalStorage('user');
  const api_key = '';

  const widgetInSingleLine =
    (selectedDevice === 'MOBILE' || (selectedDevice === 'TABLET' && !landscape)) && 'widget-single-line';

  const { panelConfig, formAttributes, panelWidgets } = useContext(FormBuilderContext);

  const [formPanel, setFormPanel] = useState([]);
  const [formWidget, setFormWidget] = useState([]);
  const [selectedFormPanel, setSelectedFormPanel] = useState({});
  const [values, setValues] = useState({});
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [showRequiredError, setShowRequiredError] = useState(false);
  const [showValidDataError, setShowValidDataError] = useState(false);
  const [showRequireConfirmationError, setShowRequireConfirmationError] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState({ isConfirmed: false, isCollectPayment: false });
  const [showCharacterLimitError, setShowCharacterLimitError] = useState(false);
  const [selectedIntegrationInComponent, setSelectedIntegrationInComponent] = useState(null);
  const [formSessionData, setFormSessionData] = useState(null);

  const getIntegrationIdFromProductComponent = panelWidgets => {
    const widgetComponents = panelWidgets
      .map(p => p.widgets)
      .filter(x => x)
      .flat()
      .map(w => w.components)
      .filter(x => x)
      .flat();
    const productComponents = widgetComponents.filter(c => c.component_type === 'PRODUCT');
    const productComponent =
      productComponents.find(c => c.products?.length > 0 || c.integration?.id) || productComponents?.[0] || null;
    setSelectedIntegrationInComponent(productComponent);
  };

  const prepareSubmissionData = widget => {
    const request = widget
      .map(w => w.components)
      .flat()
      .filter(c => c.component_type !== 'PAYMENT')
      .map(c => ({
        panel_widget_component: {
          id: c.id,
        },
        submitted_data: c.component_type === 'PRODUCT' ? values[c.id] || '' : '',
        lexicon: c.lexicon,
      }))
      .filter(data => data.submitted_data || data.image);
    return request;
  };

  useEffect(() => {
    const widgets = panelWidgets?.reduce((acc, curr) => {
      const panelId = curr.id;
      if (!acc[panelId]) {
        acc[panelId] = [...(curr.widgets || [])];
      }
      return acc;
    }, {});
    const panelWithWidgets = panelConfig?.map(panel => ({ ...panel, widgets: widgets[panel.id] }));

    setFormPanel(panelWithWidgets);
    setSelectedFormPanel(panelWithWidgets?.[0]);
    getIntegrationIdFromProductComponent(panelWithWidgets);
  }, [panelConfig, panelWidgets]);

  useEffect(() => {
    if (selectedFormPanel) {
      setFormWidget(selectedFormPanel.widgets);
    }
  }, [selectedFormPanel?.id]);

  const checkRequiredFieldError = widget => {
    const requiredComponents = widget
      .map(w => w.components)
      .flat()
      .filter(c => c.is_required && c.is_visible);
    const isError = requiredComponents.some(c =>
      c.is_autofill
        ? !autoFillLookUp(c.lexicon)
        : c.component_type === 'PHONE'
        ? !values[c.id]?.phone
        : c.component_type === 'DOB' && c.component_layout === 'DAYMONTHYEAR'
        ? !values[c.id]?.day || !values[c.id]?.month || !values[c.id]?.year
        : !values[c.id],
    );
    return isError;
  };

  const checkConfirmFieldError = widget => {
    const requireConfirmationComponents = widget
      .map(w => w.components)
      .flat()
      .filter(c => c.requires_confirmation && c.is_visible);
    const isError = requireConfirmationComponents.some(
      c => (values[c.id] || values[`${c.id}-confirm`]) && values[c.id] !== values[`${c.id}-confirm`],
    );
    return isError;
  };

  const checkValidFieldError = widget => {
    const requiredComponents = widget
      .map(w => w.components)
      .flat()
      .filter(c => c.is_visible && c.component_type === 'DOB' && c.component_layout === 'DAYMONTHYEAR');
    const isError = requiredComponents.some(
      c => values[c.id] && (!values[c.id]?.day || !values[c.id]?.month || !values[c.id]?.year),
    );
    return isError;
  };

  const checkCharacterLimitError = widget => {
    const componentTypeArray = ['ALPHA', 'NUMERIC', 'ALPHANUMERIC'];
    const requiredComponents = widget
      .map(w => w.components)
      .flat()
      .filter(
        c =>
          c.is_visible &&
          componentTypeArray.includes(c.component_type) &&
          (c.character_limit?.min || c.character_limit?.max),
      );
    const isError = requiredComponents.some(c => {
      const value = values[c.id] || '';
      return (
        (c.character_limit?.min && value.length < c.character_limit?.min) ||
        (c.character_limit?.max && value.length > c.character_limit?.max)
      );
    });
    return isError;
  };

  const changeSelectedPanel = (panel, isPreviousStep = false) => {
    if (panel.is_confirmation_panel) {
      setSelectedFormPanel(panel);
      return;
    }
    if (!isPreviousStep && checkRequiredFieldError(formWidget)) {
      dispatch(addToast({ error: true, text: 'Please fill required fields', id: uuid() }));
      setShowRequiredError(true);
      return;
    }
    if (!isPreviousStep && checkConfirmFieldError(formWidget)) {
      dispatch(addToast({ error: true, text: 'Fields do not match', id: uuid() }));
      setShowRequireConfirmationError(true);
      return;
    }
    if (!isPreviousStep && checkValidFieldError(formWidget)) {
      dispatch(addToast({ error: true, text: 'Please fill valid data', id: uuid() }));
      setShowValidDataError(true);
      return;
    }
    if (!isPreviousStep && checkCharacterLimitError(formWidget)) {
      dispatch(addToast({ error: true, text: 'Please enter characters in range', id: uuid() }));
      setShowCharacterLimitError(true);
      return;
    }
    const request = prepareSubmissionData(formWidget);
    const paymentWidgetComponentIds = request.map(r => r?.panel_widget_component?.id);
    const formSessionDataNew = [
      ...(formSessionData || [])?.filter(s => !paymentWidgetComponentIds?.includes(s.panel_widget_component?.id)),
      ...request,
    ];
    setFormSessionData(formSessionDataNew);
    setShowRequiredError(false);
    setShowValidDataError(false);
    setShowRequireConfirmationError(false);
    setSelectedFormPanel(panel);
  };

  const onAddressUpdate = (address, widget) => {
    const addressValue = widget.components
      .filter(c => c.lexicon?.includes('contact.address'))
      .reduce((acc, { lexicon, id }) => {
        acc[id] = address[lexicon.split('contact.address.')[1]] || '';
        return acc;
      }, {});
    setSelectedAddress(address);
    return addressValue;
  };

  const onSubmitForm = () => {
    const confirmationPanel = formPanel.find(p => p.is_confirmation_panel);
    if (confirmationPanel) {
      changeSelectedPanel(confirmationPanel);
    } else {
      dispatch(addToast({ error: false, text: 'Form is submitted successfully' }));
      setValues({});
      setSelectedFormPanel(formPanel?.[0]);
    }
  };

  const getProductOptions = (options = []) => {
    return options
      .filter(option => option.pricing?.[0].currency_code === 'USD')
      .map(({ pricing, id, external_reference }) => ({
        label: pricing?.[0].display_as,
        value: pricing?.[0].external_reference,
        priceAmountLabel: `${pricing?.[0].currency_code === 'GBP' ? '£' : '$'}${pricing?.[0].price}`,
        amount: parseFloat(pricing?.[0].price) * 100,
        type: pricing?.[0]?.recurring_period,
        currency: pricing?.[0]?.currency_code,
        id: id,
        product_id: external_reference,
        price_id: pricing?.[0].external_reference,
      }));
  };

  const autoFillLookUpInFormData = lexicon => {
    let autoFillDataFromSession = formSessionData?.find(data => data.lexicon === lexicon) || null;
    return autoFillDataFromSession ? autoFillDataFromSession?.submitted_data || '' : '';
  };

  const autoFillLookUp = lexicon => {
    const { organizations } = user;
    const userOrganization = organizations?.[0] || {};
    const userData = {};
    if (lexicon === 'organizations.employee_number') {
      return userOrganization.employee_number || '123';
    } else if (lexicon === 'organizations.badge_number') {
      return userOrganization.badge_number || '123';
    } else if (lexicon === 'organizations.name') {
      return userOrganization?.name;
    } else if (lexicon === 'user.name') {
      return `${userData.forename || ''} ${userData.surname || ''}`;
    }
    return autoFillLookUpInFormData(lexicon);
  };

  const groupByRow = components => {
    return components.reduce((acc, curr) => ({ ...acc, [curr.row]: (acc[curr.row] || []).concat(curr) }), {});
  };

  const getFormAttributeValue = field => {
    return formAttributes[field] || null;
  };

  const getFormAttributFonts = () => {
    return {
      fontFamily: getFontFamily(getFormAttributeValue('font')?.style, getFormAttributeValue('font')?.name),
      font: getFormAttributeValue('font'),
    };
  };

  const renderWatermark = (panel, panelIndex, panelConfig) => {
    const { display_watermark, alignment, watermark_location, image } = formAttributes?.watermark || {};
    const { display_watermark: confirmation_panel_watermark } = formAttributes?.confirmation_panel || {};

    const panelConfigWithoutConfirmationPanel = panelConfig.filter(p => !p.is_confirmation_panel);
    if (!display_watermark) {
      return;
    }
    if (panel.is_confirmation_panel && !confirmation_panel_watermark) {
      return;
    }
    if (watermark_location === 'FIRSTPAGE' && panelIndex !== 0 && !panel.is_confirmation_panel) {
      return;
    }
    if (
      watermark_location === 'LASTPAGE' &&
      panelConfigWithoutConfirmationPanel.length - 1 !== panelIndex &&
      !panel.is_confirmation_panel
    ) {
      return;
    }
    if (!image?.image?.url) {
      return;
    }
    return (
      <div
        className={`flex my-4 mx-10 ${
          alignment === 'LEFT'
            ? 'items-start justify-start'
            : alignment === 'RIGHT'
            ? 'items-start justify-end'
            : 'items-end justify-center'
        }`}>
        <img height={64} width={200} src={image?.image?.url} alt="watermark" className="watermark" />
      </div>
    );
  };

  const formWidgets = widget => {
    return (
      <div className="w-full">
        {Object.values(groupByRow(widget.components)).map(c => (
          <div className={`flex flex-1 w-full ${widgetInSingleLine && 'widget-single-line'}`}>
            {c.map(
              component =>
                component.is_visible && (
                  <>
                    <div className="px-1 mt-4 w-full" style={{ flex: `1 1 calc(100%/${c.length})` }}>
                      {component.component_type === 'IMAGE' && (
                        <ImageElement
                          key={component.id}
                          src={component?.image?.image?.url}
                          alt={component.name}
                          media={component?.image?.media}
                          size={getFormAttributeValue('pane_width')}
                          width={component?.image?.width}
                          height={component?.image?.height}
                        />
                      )}
                      {component.component_type === 'HEADER' && (
                        <HeaderElement
                          key={component.id}
                          name={component.name}
                          font={component.font}
                          fontFamily={getFontFamily(component?.font?.style, component?.font?.name)}
                          alignment={getAlignment(component.alignment)}
                        />
                      )}
                      {component.component_type === 'CHOOSER' && (
                        <Dropdown
                          {...component}
                          {...getFormAttributFonts()}
                          key={component.id}
                          is_required={component.is_required}
                          showRequiredError={showRequiredError && component.is_required}
                          name={component.name}
                          placeholder={component.label}
                          is_autofill={component.is_autofill}
                          sub_label={component.sub_label}
                          autoFillValue={autoFillLookUp(component.lexicon)}
                          options={component.list?.choices?.map(c => ({ ...c, label: c.name, value: c.id }))}
                          selectedValue={values[component.id]}
                          onChange={option => {
                            setValues({ ...values, [component.id]: option });
                          }}
                        />
                      )}
                      {component.component_type === 'PHONE' && (
                        <FormPhoneInput
                          {...getFormAttributFonts()}
                          key={component.id}
                          is_required={component.is_required}
                          showRequiredError={showRequiredError && component.is_required}
                          name={component.name}
                          is_autofill={component.is_autofill}
                          autoFillValue={autoFillLookUp(component.lexicon)}
                          sub_label={component.sub_label}
                          phoneValue={values[component.id]}
                          onChange={value => {
                            setValues({ ...values, [component.id]: value });
                          }}
                        />
                      )}
                      {(component.component_type === 'ALPHA' ||
                        component.component_type === 'NUMERIC' ||
                        component.component_type === 'ALPHANUMERIC') && (
                        <div className={`flex ${widgetInSingleLine && 'widget-single-line'}`}>
                          <InputElement
                            {...getFormAttributFonts()}
                            {...component}
                            showCharacterLimitError={showCharacterLimitError}
                            className="w-full"
                            key={component.id}
                            is_required={component.is_required}
                            showRequiredError={showRequiredError && component.is_required}
                            showRequireConfirmationError={
                              component.requires_confirmation &&
                              showRequireConfirmationError &&
                              values[component.id] !== values[`${component.id}-confirm`]
                            }
                            name={component.name}
                            label={component.label}
                            sub_label={component.sub_label}
                            is_autofill={component.is_autofill}
                            autoFillValue={autoFillLookUp(component.lexicon)}
                            type={component.component_type}
                            value={values[component.id]}
                            onChange={value => {
                              setValues({ ...values, [component.id]: value });
                            }}
                          />
                          {component.requires_confirmation && (
                            <InputElement
                              {...getFormAttributFonts()}
                              {...component}
                              showCharacterLimitError={showCharacterLimitError}
                              className={`w-full ${widgetInSingleLine ? 'mt-4' : 'pl-2'}`}
                              is_required={component.is_required}
                              showRequiredError={showRequiredError && component.is_required}
                              showRequireConfirmationError={
                                showRequireConfirmationError &&
                                values[component.id] !== values[`${component.id}-confirm`]
                              }
                              name={`Confirm ${component.name}`}
                              label={component.label}
                              sub_label={component.sub_label}
                              is_autofill={component.is_autofill}
                              type={component.component_type}
                              value={values[`${component.id}-confirm`]}
                              onChange={value => {
                                setValues({ ...values, [`${component.id}-confirm`]: value });
                              }}
                            />
                          )}
                        </div>
                      )}
                      {component.component_type === 'DOB' && component.component_layout === 'DAYMONTHYEAR' && (
                        <DateOfBirthDayMonthYear
                          {...getFormAttributFonts()}
                          key={component.id}
                          is_required={component.is_required}
                          showRequiredError={showRequiredError && component.is_required}
                          showValidDataError={showValidDataError}
                          name={component.name}
                          placeholder={component.label}
                          sub_label={component.sub_label}
                          is_autofill={component.is_autofill}
                          autoFillValue={autoFillLookUp(component.lexicon)}
                          value={values[component.id]}
                          setValue={value => {
                            setValues({ ...values, [component.id]: value });
                          }}
                        />
                      )}
                      {component.component_type === 'DOB' &&
                        (component.component_layout === 'DATEPICKER' || !component.component_layout) && (
                          <DateSelectorElement
                            {...getFormAttributFonts()}
                            key={component.id}
                            is_required={component.is_required}
                            showRequiredError={showRequiredError && component.is_required}
                            name={component.name}
                            placeholder={component.label}
                            sub_label={component.sub_label}
                            is_autofill={component.is_autofill}
                            autoFillValue={autoFillLookUp(component.lexicon)}
                            selectedDate={values[component.id]}
                            setSelectedDate={value => {
                              setValues({ ...values, [component.id]: value });
                            }}
                          />
                        )}
                      {component.component_type === 'DATE' && (
                        <DateSelectorElement
                          {...getFormAttributFonts()}
                          key={component.id}
                          is_required={component.is_required}
                          showRequiredError={showRequiredError && component.is_required}
                          name={component.name}
                          placeholder={component.label}
                          sub_label={component.sub_label}
                          is_autofill={component.is_autofill}
                          autoFillValue={autoFillLookUp(component.lexicon)}
                          selectedDate={values[component.id]}
                          setSelectedDate={value => {
                            setValues({ ...values, [component.id]: value });
                          }}
                        />
                      )}
                      {component.component_type === 'MULTICHOICE' && (
                        <Checkbox
                          {...getFormAttributFonts()}
                          key={component.id}
                          is_required={component.is_required}
                          showRequiredError={showRequiredError && component.is_required}
                          name={component.name}
                          is_autofill={component.is_autofill}
                          autoFillValue={autoFillLookUp(component.lexicon)}
                          checked={values[component.id]}
                          onChange={value => {
                            setValues({ ...values, [component.id]: value });
                          }}
                        />
                      )}
                      {component.component_type === 'SIGNATURE' && (
                        <Signature
                          {...getFormAttributFonts()}
                          key={component.id}
                          is_required={component.is_required}
                          showRequiredError={showRequiredError && component.is_required}
                          name={component.name}
                          sub_label={component.sub_label}
                          signatureValue={values[component.id]}
                          onChange={value => {
                            setValues({ ...values, [component.id]: value });
                          }}
                        />
                      )}
                      {component.component_type === 'ADDRESS' && (
                        <Address
                          {...getFormAttributFonts()}
                          key={component.id}
                          is_required={component.is_required}
                          showRequiredError={showRequiredError && component.is_required}
                          name={component.name}
                          sub_label={component.sub_label}
                          is_autofill={component.is_autofill}
                          autoFillValue={autoFillLookUp(component.lexicon)}
                          placeholder={component.label}
                          selectedValue={values[component.id]}
                          onAddressUpdate={address => {
                            setValues({ ...values, ...onAddressUpdate(address, widget) });
                          }}
                        />
                      )}
                      {component.component_type === 'PRODUCT' && (
                        <Dropdown
                          {...component}
                          {...getFormAttributFonts()}
                          options={
                            selectedIntegrationInComponent?.id !== component.id
                              ? getProductOptions(selectedIntegrationInComponent?.products)
                              : getProductOptions(component.products_display || component.products)
                          }
                          key={component.id}
                          is_required={component.is_required}
                          showRequiredError={showRequiredError && component.is_required}
                          name={component.name}
                          placeholder={component.label}
                          sub_label={component.sub_label}
                          is_autofill={component.is_autofill || selectedIntegrationInComponent?.id !== component.id}
                          autoFillValue={autoFillLookUp(component.lexicon)}
                          selectedValue={values[component.id]}
                          onChange={option => {
                            setValues({ ...values, [component.id]: option });
                            setSelectedProduct({ component, option });
                          }}
                        />
                      )}
                      {component.component_type === 'PAYMENT' && (
                        <Fragment>
                          <Payment
                            {...getFormAttributFonts()}
                            key={component.id}
                            name={component.name}
                            placeholder={component.label}
                            integration={component.integration}
                            selectedProduct={selectedProduct}
                            selectedAddress={selectedAddress}
                            backgroundColor={getFormAttributeValue('color')?.button}
                            setPaymentDetails={setPaymentDetails}
                            onSubmitForm={onSubmitForm}
                            api_key={api_key}
                            selectedFormPanel={selectedFormPanel}
                          />
                        </Fragment>
                      )}
                    </div>
                  </>
                ),
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <ContentPreviewWrapper
      className="flex-column items-center flex-1 overflow-auto h-full"
      style={{ backgroundColor: getFormAttributeValue('background')?.color }}>
      <div className="flex-column items-center py-10 px-8 max-w-100">
        {!selectedFormPanel?.is_confirmation_panel && getFormAttributeValue('progress_display') === 'TOP' && (
          <ProgressBar
            currentStep={formPanel?.filter(p => !p.is_confirmation_panel).findIndex(f => f.id === selectedFormPanel.id)}
            totalSteps={formPanel?.filter(p => !p.is_confirmation_panel).length}
            formPanel={formPanel?.filter(p => !p.is_confirmation_panel)}
            changeSelectedPanel={changeSelectedPanel}
            isTop
          />
        )}
        <div
          className="card form-wrapper flex-column"
          style={{
            maxWidth: '100%',
            width: `${getFormAttributeValue('panel_width')}px`,
            backgroundColor: selectedFormPanel?.panel_color || getFormAttributeValue('color')?.page || '#FFFFFF',
          }}>
          <div className="form-content flex-1">
            {formWidget?.map(widget => (
              <>{formWidgets(widget)}</>
            ))}
          </div>
          {!selectedFormPanel?.is_confirmation_panel && (
            <div
              className="flex col-gap-10 form-action-wrapper"
              style={{ borderColor: getFormAttributeValue('color')?.button || '#8927EF' }}>
              {formPanel
                ?.filter(p => !p.is_confirmation_panel)
                ?.slice()
                .sort((a, b) => b.priority - a.priority)
                .find(f => f.priority < selectedFormPanel.priority) && (
                <Button
                  size="large"
                  width="100%"
                  borderRadius="16px"
                  label={'Previous'}
                  disabled={isFormSubmitting}
                  className={`${isFormSubmitting && 'disabled'}`}
                  borderColor={getFormAttributeValue('color')?.button || '#8927EF'}
                  bgColor={getFormAttributeValue('color')?.button || '#8927EF'}
                  color="#ffffff"
                  onClick={() =>
                    changeSelectedPanel(
                      formPanel
                        ?.slice()
                        .sort((a, b) => b.priority - a.priority)
                        .find(f => f.priority < selectedFormPanel.priority),
                      true,
                    )
                  }
                />
              )}
              {formPanel?.filter(p => !p.is_confirmation_panel).find(f => f.priority > selectedFormPanel.priority) ? (
                <Button
                  size="large"
                  width="100%"
                  borderRadius="16px"
                  label={'Next'}
                  disabled={isFormSubmitting}
                  className={`${isFormSubmitting && 'disabled'}`}
                  borderColor={getFormAttributeValue('color')?.button || '#8927EF'}
                  bgColor={getFormAttributeValue('color')?.button || '#8927EF'}
                  color="#ffffff"
                  onClick={() => changeSelectedPanel(formPanel.find(f => f.priority > selectedFormPanel.priority))}
                />
              ) : (
                !selectedFormPanel?.submit_onpayment && (
                  <Button
                    size="large"
                    width="100%"
                    borderRadius="16px"
                    label={'Submit'}
                    disabled={!paymentDetails.isConfirmed || isFormSubmitting}
                    className={`${(!paymentDetails.isConfirmed || isFormSubmitting) && 'disabled'}`}
                    borderColor={getFormAttributeValue('color')?.button || '#8927EF'}
                    bgColor={getFormAttributeValue('color')?.button || '#8927EF'}
                    color="#ffffff"
                    onClick={() => onSubmitForm()}
                  />
                )
              )}
            </div>
          )}
          {renderWatermark(
            selectedFormPanel,
            formPanel?.findIndex(f => f.id === selectedFormPanel.id),
            formPanel,
          )}
        </div>
        {!selectedFormPanel?.is_confirmation_panel && getFormAttributeValue('progress_display') === 'BOTTOM' && (
          <ProgressBar
            currentStep={formPanel?.filter(p => !p.is_confirmation_panel).findIndex(f => f.id === selectedFormPanel.id)}
            totalSteps={formPanel?.filter(p => !p.is_confirmation_panel).length}
            formPanel={formPanel?.filter(p => !p.is_confirmation_panel)}
            changeSelectedPanel={changeSelectedPanel}
          />
        )}
        {!selectedFormPanel?.is_confirmation_panel && (
          <ProgressSteps
            currentStep={formPanel
              ?.filter(p => !p.is_confirmation_panel)
              ?.findIndex(f => f.id === selectedFormPanel.id)}
            totalSteps={formPanel?.filter(p => !p.is_confirmation_panel).length}
            bgColor={getFormAttributeValue('field_accent_color') || '#FFFFFF'}
          />
        )}
      </div>
    </ContentPreviewWrapper>
  );
};

const ContentPreviewWrapper = styled.div`
  .form-wrapper {
    width: 613px;
    height: auto;
    /* margin: auto; */
    border-radius: 24px;
  }

  .form-content {
    margin: 8px 24px 40px 24px;
  }

  .form-action-wrapper {
    padding: 40px;
    border-top: 1px solid;
  }

  .content-container {
    padding-top: 80px;
  }

  .widget-single-line {
    flex-direction: column;
  }
`;

export default ContentPreview;

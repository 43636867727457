import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { FormDetailsHeaderWrapper } from '../../../styles/components/formDetails/form-details.styled';

const FormDetailsHeader = ({ title, titleContent = null, actionContent = null }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <FormDetailsHeaderWrapper className="items-center justify-between w-full pl-6 pr-4 py-4 divider-bottom">
      <div className="flex items-center justify-start mr-2">
        <label className="medium-text fw-500 font-20 nowrap">{title}</label>
        {titleContent}
      </div>
      {actionContent}
    </FormDetailsHeaderWrapper>
  );
};

export default FormDetailsHeader;

import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import uuid from 'react-uuid';
import { ReactComponent as SortingRight } from '../assets/images/sorting-right.svg';
import Status from '../components/common/status';
import { initModal } from '../constant/InitialData';
import { OrganisationContext } from '../context/organisationContext';
import { publishFormVersion, rePublishFormVersion } from '../store/features/formsSlice';
import { addToast } from '../store/features/toastSlice';
import CommonPopup from './common-popup';
import { PublishFormVersionWrapper } from './modal.styled';

const PublishFormVersion = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { modal, setModal } = useContext(OrganisationContext);
  const { form, formVersionToPublish, affectedFormVersion, isRePublish, title, onSuccess = () => {} } = modal.content;

  const [loading, setLoading] = useState(false);

  const onConfirmAction = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    if (isRePublish) {
      dispatch(rePublishFormVersion({ formId: form.id, versionId: formVersionToPublish.id }))
        .then(() => {
          dispatch(
            addToast({ error: false, text: `V${formVersionToPublish?.version_no} has been republished`, id: uuid() }),
          );
          onSuccess();
        })
        .catch(() => {
          dispatch(
            addToast({
              error: true,
              text: `V${formVersionToPublish?.version_no} has not been republished`,
              id: uuid(),
            }),
          );
        })
        .finally(() => setModal(initModal));
    } else {
      dispatch(publishFormVersion({ formId: form.id, versionId: formVersionToPublish.id }))
        .then(() => {
          dispatch(
            addToast({ error: false, text: `V${formVersionToPublish?.version_no} has been published`, id: uuid() }),
          );
          onSuccess();
        })
        .catch(() => {
          dispatch(
            addToast({ error: true, text: `V${formVersionToPublish?.version_no} has not been published`, id: uuid() }),
          );
        })
        .finally(() => setModal(initModal));
    }
  };

  return (
    <CommonPopup
      popupTitle={title}
      confirmButtonProps={{
        label: isRePublish ? 'Republish' : 'Publish',
      }}
      disabled={loading}
      onCancel={() => setModal(initModal)}
      onConfirm={onConfirmAction}>
      <PublishFormVersionWrapper className="w-full">
        {isRePublish && (
          <p className="regular-text grey-text text-center mx-2 py-2">
            Republishing this version will update its status to published.
          </p>
        )}
        <div className="flex-column items-start w-full pxy-4 radius-4 mt-6 form-detail-wrapper">
          <div className="flex items-start w-full">
            <div className="flex-column items- start flex-1">
              <label className="semibold-text font-16 fw-600">
                {form?.name}
                <span className="bold-text font-16 ml-2 zen-purple-text">V{formVersionToPublish?.version_no}</span>
              </label>
              <label className="regular-text lighter-text font-12 mt-2">
                {form?.prizedraw_client?.prizedraw_client_code}
              </label>
            </div>
            {form?.prizedraw?.image && (
              <div className="flex items-center h-full">
                <img className="form-image" src={form?.prizedraw?.image?.url} alt="form-image" />
              </div>
            )}
          </div>
          <div className="flex mt-4">
            <div>
              <span className="flex regular-text lighter-text font-12 mb-1">{t('CURRENT')}:</span>
              <Status withDot status={isRePublish ? 'RETIRED' : 'DRAFT'} />
            </div>
            <div className="mx-2 my-1 flex items-end">
              <SortingRight />
            </div>
            <div>
              <span className="flex regular-text lighter-text font-12 mb-1">{t('NEW')}:</span>
              <Status withDot status="PUBLISHED" />
            </div>
          </div>
        </div>
        {affectedFormVersion && (
          <>
            <hr className="hr-line" />
            <div>
              {isRePublish ? (
                <p className="regular-text grey-text text-center mx-2">
                  As a result the below version will update its status back to draft.
                </p>
              ) : (
                <p className="regular-text grey-text text-center">
                  As a result the below version will update its status to retired.
                </p>
              )}
            </div>
            <div className="flex-column items-start w-full pxy-4 radius-4 mt-6 form-detail-wrapper">
              <div className="flex items-start w-full">
                <div className="flex-column items- start flex-1">
                  <label className="semibold-text font-16 fw-600">
                    {form?.name}
                    <span className="bold-text font-16 ml-2 zen-purple-text">V{affectedFormVersion?.version_no}</span>
                  </label>
                  <label className="regular-text lighter-text font-12 mt-2">
                    {form?.prizedraw_client?.prizedraw_client_code}
                  </label>
                </div>
                {form?.prizedraw?.image && (
                  <div className="flex items-center h-full">
                    <img className="form-image" src={form?.prizedraw?.image?.url} alt="form-image" />
                  </div>
                )}
              </div>
              <div className="flex mt-4">
                <div>
                  <span className="flex regular-text lighter-text font-12 mb-1">{t('CURRENT')}:</span>
                  <Status withDot status="PUBLISHED" />
                </div>
                <div className="mx-2 my-1 flex items-end">
                  <SortingRight />
                </div>
                <div>
                  <span className="flex regular-text lighter-text font-12 mb-1">{t('NEW')}:</span>
                  <Status withDot status={isRePublish ? 'DRAFT' : 'RETIRED'} />
                </div>
              </div>
            </div>
          </>
        )}
      </PublishFormVersionWrapper>
    </CommonPopup>
  );
};

export default PublishFormVersion;

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import useDebounce from '../../../helpers/useDebounceHook';
import { addToast } from '../../../store/features/toastSlice';
import { MinMaxInputWrapper } from './min-max.styled';

const MinMaxInput = ({ minValue, maxValue, setMinValue, setMaxValue, minPlaceholder = '', maxPlaceholder = '' }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const debouncedMinValue = useDebounce(minValue, 500);
  const debouncedMaxValue = useDebounce(maxValue, 500);

  useEffect(() => {
    validateMinMaxInputs();
  }, [debouncedMinValue, debouncedMaxValue]);

  const validateMinMaxInputs = () => {
    const min = parseFloat(minValue);
    const max = parseFloat(maxValue);

    if (isNaN(min) || isNaN(max)) {
      setMinValue(null);
      setMaxValue(null);
    } else if (min > max) {
      dispatch(addToast({ error: true, text: 'Min value must be less than or equal to Max value.' }));
    } else {
      setMinValue(min);
      setMaxValue(max);
    }
  };

  const handleMinChange = ({ target }) => {
    setMinValue(target.value);
  };

  const handleMaxChange = ({ target }) => {
    setMaxValue(target.value);
  };

  return (
    <MinMaxInputWrapper className="flex justify-start items-center w-full gap-6">
      <div className="flex-column justify-start items-start w-50">
        <label className="lighter-text font-12 mb-2">{t('MIN')}</label>
        <div className="input-field flex item-center">
          <span className="input-field-prefix">$</span>
          <input
            type="number"
            className="input-border w-full px-1 font-14 regular-text"
            value={minValue}
            onChange={handleMinChange}
            placeholder={minPlaceholder}
          />
        </div>
      </div>
      <div className="flex-column justify-start items-start w-50">
        <label className="lighter-text font-12 mb-2">{t('MAX')}</label>
        <div className="input-field flex item-center">
          <span className="input-field-prefix">$</span>
          <input
            type="number"
            className="input-border w-full px-1 font-14 regular-text"
            value={maxValue}
            onChange={handleMaxChange}
            placeholder={maxPlaceholder}
          />
        </div>
      </div>
    </MinMaxInputWrapper>
  );
};

export default MinMaxInput;

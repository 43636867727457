import styled from 'styled-components';

export const MainWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  background: #ffffff;

  .main {
    display: flex;
    flex: 1;

    .content {
      display: flex;
      flex: 1;
    }
  }
`;

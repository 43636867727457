import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import uuid from 'react-uuid';
import { ReactComponent as PlusIcon } from '../../assets/images/plus-black.svg';
import { useWindowSize } from '../../hooks/useWindowSize';
import { addToast } from '../../store/features/toastSlice';
import Button from '../Elements/button/button';
import FilterItem from './filter-item';
import { MultipleFilterStyledWrapper } from './multiple-filter.styled';

const MultipleFilter = ({ filters = [{}], setFilters, filterFor, className = '', needToCheckFilterVal = true }) => {
  const filterItemRef = useRef();
  const filterEndItemRef = useRef();
  const { height } = useWindowSize();
  const [isScroll, setScroll] = useState(true);
  const dispatch = useDispatch();
  const [filtername, setFilterName] = useState([]);
  const { t } = useTranslation();
  const handleScroll = () => {
    setScroll(filterItemRef?.current?.clientHeight < height - 360 ? false : true);
  };
  useEffect(() => {
    setTimeout(() => handleScroll(), 1000);
  }, [height, filterItemRef?.current?.clientHeight]);

  const scrollToBottom = () => {
    filterEndItemRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [filters, isScroll]);

  useEffect(() => {
    const filtersData = filters.map(data => {
      setFilterName(data);
    });
  }, [filters]);

  return (
    <MultipleFilterStyledWrapper className={`flex-column card absolute ${className}`}>
      <div className="flex items-center justify-between w-full pxy-4 ">
        <label className="normal-text font-16">{t('FILTER')}</label>
        <div className="flex items-center justify-center cursor clear-all no-select" onClick={() => setFilters([{}])}>
          <label className="normal-text" style={{ color: '#FF406E' }}>
            {t('CLEAR')}
          </label>
        </div>
      </div>
      <div
        className="filter-item-container"
        style={{ maxHeight: `${height - 360}px`, overflowY: `${isScroll ? 'auto' : 'unset'}` }}
        ref={filterItemRef}>
        {filters.length > 0 &&
          filters.map((data, index) => (
            <div ref={filterEndItemRef}>
              <FilterItem
                filterFor={filterFor}
                index={index}
                filter={data}
                setFilter={uf => {
                  const updatedFilterList = filters.map(item =>
                    item.ids === data.ids ? { ...item, ...uf } : { ...item },
                  );
                  setFilters([...updatedFilterList]);
                }}
                removeFilter={rmf => {
                  const updatedFilters = filters.filter(item => item.ids !== rmf.ids);
                  setFilters(updatedFilters.length === 0 ? [{ id: uuid() }] : [...updatedFilters]);
                }}
              />
            </div>
          ))}
      </div>
      <div className="flex pxy-4 items-center justify-center w-full border-top">
        <Button
          className={'primary-grey mr-6'}
          label={t('ADD_NEW_FILTER')}
          borderRadius={'12px'}
          size={'medium'}
          icon={<PlusIcon height={16} width={16} />}
          onClick={() => {
            if (
              needToCheckFilterVal &&
              (!filters[filters.length - 1].value ||
                (filters[filters.length - 1].childRenderComponent === 'INFINITE_SCROLL_MULTI_DROPDOWN' &&
                  !filters[filters.length - 1].value2))
            ) {
              dispatch(
                addToast({
                  error: true,
                  text: t('PLEASE_ENTER_PREVIOUS_FILTER'),
                  id: uuid(),
                }),
              );
              return;
            }

            if (filtername?.name) {
              setFilters([...filters, { id: uuid() }]);
            } else {
              dispatch(
                addToast({
                  error: true,
                  text: t('PLEASE_ENTER_PREVIOUS_FILTER'),
                  id: uuid(),
                }),
              );
            }
          }}
        />
      </div>
    </MultipleFilterStyledWrapper>
  );
};

export default MultipleFilter;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { PrizeDrawFormWrapper } from '../../styles/components/prize-draw-detail/prize-draw-detail.styled';
import ComingSoonComponent from '../common/coming-soon';
import ProfileDetailHeader from './Profile-detail-header';

const ProfileDetail = () => {
  const { t } = useTranslation();

  return (
    <PrizeDrawFormWrapper className="flex-column items-start justify-start w-full h-full">
      <ProfileDetailHeader title={t('DETAILS')} />
      <ComingSoonComponent />
    </PrizeDrawFormWrapper>
  );
};

export default ProfileDetail;

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { FormDetailsComponentWrapper } from '../../styles/components/formDetails/form-details.styled';
import FormDetailsLeft from './form-details-left';
import FormDetailsRight from './form-details-right';
import FormVersionDetailsLeft from './form-version-details-left';
import FormVersionDetailsRight from './form-version-details-right';

const FormDetailsComponent = ({ formId }) => {
  return (
    <FormDetailsComponentWrapper className={`flex col-gap-6 flex-1 ${!formId && `px-10 pt-2 pb-6`}`}>
      <Routes>
        <Route
          element={
            <>
              <FormDetailsLeft />
              <FormDetailsRight />
            </>
          }
          path="*"
        />
        <Route
          element={
            <>
              <FormVersionDetailsLeft />
              <FormVersionDetailsRight />
            </>
          }
          path="/form-version/:versionId"
        />
      </Routes>
    </FormDetailsComponentWrapper>
  );
};

export default FormDetailsComponent;

import api from '../services/index';

const getFileData = (chunk, length, folder, resize, chunk_number, session_id) => {
  const url = chunk_number === 0 ? '/files' : `/files/${session_id}`;
  const formData = new FormData();

  if (chunk_number === 0) {
    formData.append('file', chunk);
    formData.append('folder', folder);
    formData.append('backend', '');
    formData.append('resize', resize);
    formData.append('length', length);
  } else {
    formData.append('file', chunk);
    formData.append('offset', chunk_number);
  }

  return { formData, url };
};

const uploadFile = async ({
  file,
  folder = 'files',
  resize = '',
  onSuccess = () => {},
  onError = () => {},
  setProgress = () => {},
}) => {
  let data = null;
  const chunkSize = 256 * 1024 * 1024; // (adjust based on your requirements)
  const totalChunks = Math.floor(file.size / chunkSize) + 1;
  const chunkProgress = 100 / totalChunks;
  let chunkNumber = 0;
  let start = 0;
  let end = chunkSize;

  const uploadNextChunk = async () => {
    if (chunkNumber < totalChunks) {
      try {
        let chunk = file.slice(start, end);
        chunk = new File([chunk], file.name, { type: file.type, lastModified: file.lastModified });

        const { formData, url } = getFileData(chunk, file.size, folder, resize, start, data?.session_id);

        const response = await api.post(url, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          timeout: 50000,
        });
        data = response.data;
        chunkNumber++;
        start = end;
        end = start + chunkSize;
        setProgress(Number((chunkNumber + 1) * chunkProgress));
        await uploadNextChunk();
      } catch (error) {
        onError(error);
      }
    } else {
      onSuccess(data);
    }
  };

  await uploadNextChunk();
};

export const uploadMedia = async file => {
  try {
    let data = {};
    await uploadFile({
      file,
      folder: 'zp',
      onSuccess: res => {
        data = res;
      },
      onError: err => {
        throw err;
      },
      setProgress: () => {},
    });
    return { ...data, id: data.media_id, media_external_id: data.media_id };
  } catch (error) {
    return error;
  }
};

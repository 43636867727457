import React, { useState } from 'react';
import CommonPopup from '../../common-popup/index';
import { DeletePagePanelWrapper } from './index.styled';

const DeletePagePanel = ({ modal, setModal }) => {
  const { page, deletePagePanel } = modal.content || {};
  const [loading, setLoading] = useState(false);

  const onDone = async () => {
    if (loading) {
      return;
    }
    try {
      setLoading(true);
      await deletePagePanel(page);
      setModal(null);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };
  return (
    <CommonPopup
      disabled={loading}
      popupTitle={'Are you sure you want to delete this page?'}
      popupSubTitle={'Doing this will delete all widgets configured on this page'}
      confirmButtonProps={{ label: 'Delete', className: 'negative' }}
      onCancel={() => setModal(null)}
      onConfirm={onDone}>
      <DeletePagePanelWrapper className="w-full radius-3 py-2 px-4 mt-6">
        <label className="medium-text">{page.name}</label>
      </DeletePagePanelWrapper>
    </CommonPopup>
  );
};

export default DeletePagePanel;

import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ExportIcon } from '../../assets/images/download.svg';
import { ReactComponent as FilterIcon } from '../../assets/images/filter.svg';
import { ReactComponent as PlusIcon } from '../../assets/images/plus.svg';
import Button from '../../components/Elements/button/button';
import Filter from '../../components/filter';

import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { ReactComponent as CalenderIcon } from '../../assets/images/calendar_icon.svg';
import { ReactComponent as CalenderIconPurple } from '../../assets/images/calender-icon-purple.svg';
import { ReactComponent as RightArrowIconPurple } from '../../assets/images/sorting-right-purple.svg';
import { ReactComponent as RightArrowIcon } from '../../assets/images/sorting-right.svg';
import { OrganisationContext } from '../../context/organisationContext';
import { PageHeaderWrapper } from '../../styles/components/page-header/page-header.styled';
import DateRangeComponent from '../common/date-range';
import DrawCountDown from '../common/draw-count-down';
import InputSearch from '../common/input-search';
import MultipleFilter from '../multiple-filter';
import Pagination from '../pagination/pagination';

const PageHeader = ({
  names = [],
  filter = {},
  setFilter = () => {},
  searchText,
  onSearchChange,
  showFilter,
  showSearch,
  showAddNewBtn,
  showDrawCount = false,
  filterObject = {},
  showPagination = false,
  pagination = {},
  onAddClick = () => {},
  addBtnPermission = '',
  title = '',
  className = '',
  showMultipleFilter = false,
  multiFilter = [],
  setMultiFilter = () => {},
  multiFilterFor = '',
  targetDate = '',
  showExportButton = false,
  exportButtonPermission = '',
  onExportClick = () => {},
  showDateRangeFilter = false,
  startDate = '',
  endDate = '',
  setStartDate = () => {},
  setEndDate = () => {},
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { hasPermission, checkPermission } = useContext(OrganisationContext);

  const [openFilter, setOpenFilter] = useState(false);
  const [openMultiFilter, setOpenMultiFilter] = useState(false);
  const [openDateRange, setOpenDateRange] = useState(false);
  const [dateRangeSelected, setDateRangeSelected] = useState(false);
  const onBlur = e => {
    e.preventDefault();
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setOpenFilter(false);
    }
  };

  const handleMultipleOnBlur = e => {
    e.preventDefault();
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setOpenMultiFilter(false);
    }
  };

  const onDateRangeBlur = e => {
    e.preventDefault();
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setOpenDateRange(false);
    }
  };

  return (
    <PageHeaderWrapper className={`flex items-center w-full ${className}`}>
      {names && names.length > 0 && (
        <div className="flex flex-1">
          {names.map((name, i) => (
            <div key={i} className="flex items-center">
              <label
                className={`${i < names.length - 1 ? 'regular-text grey-text' : 'regular-text'} cursor`}
                onClick={() => (name.onClick ? name.onClick() : navigate(name.path))}>
                {name.text}
              </label>
              {i < names.length - 1 && (
                <span className={`regular-text ml-1 mr-1 ${i < names.length - 2 ? 'grey-text' : 'zen-default-text'}`}>
                  /
                </span>
              )}
            </div>
          ))}
        </div>
      )}
      {title && <label className="semibold-text font-20 flex flex-1 zen-default-text">{title}</label>}

      {showFilter && (
        <div className="relative" onBlur={onBlur} tabIndex={0}>
          <div
            className="filter pxy-2 ml-6 flex items-center justify-center cursor"
            onClick={() => setOpenFilter(!openFilter)}>
            <FilterIcon className={openFilter || filter?.value ? 'open-filter-icon' : ''} />
          </div>
          {openFilter && <Filter objects={filterObject} setFilter={setFilter} values={filter} />}
        </div>
      )}

      {showMultipleFilter && (
        <div className="relative" onBlur={handleMultipleOnBlur} tabIndex={1}>
          <div
            className="filter pxy-2 ml-6 flex items-center justify-center cursor"
            onClick={() => setOpenMultiFilter(!openMultiFilter)}>
            <FilterIcon className={openMultiFilter || multiFilter?.some(f => f.value) ? 'open-filter-icon' : ''} />
          </div>
          {openMultiFilter && (
            <MultipleFilter filters={multiFilter} setFilters={setMultiFilter} filterFor={multiFilterFor} />
          )}
        </div>
      )}
      {showAddNewBtn && (
        <Button
          fontSize="14px"
          size="medium"
          width="110px"
          borderRadius="12px"
          className={`default-secondary ml-6 ${!hasPermission(addBtnPermission) && 'disabled'}`}
          icon={<PlusIcon height={16} width={16} />}
          label={t('ADD_NEW')}
          onClick={() => checkPermission(() => onAddClick(), addBtnPermission)}
        />
      )}
      {showSearch && (
        <InputSearch
          placeholder={'Search'}
          value={searchText}
          onChange={onSearchChange}
          className="ml-6 input-search"
        />
      )}
      {showDrawCount && targetDate && <DrawCountDown targetDate={moment(targetDate * 1000)} />}
      {showDateRangeFilter && (
        <div className="relative" onBlur={onDateRangeBlur} tabIndex={0}>
          <div
            className="flex items-center radius-3 border date-range-container ml-4 px-2 col-gap-1 cursor"
            onClick={() => setOpenDateRange(!openDateRange)}>
            {dateRangeSelected ? (
              <>
                <CalenderIconPurple height={16} width={16} />
                <label className="regular-text zen-purple-text">
                  {moment(startDate * 1000).format('MMM DD, YYYY')}
                </label>
                <RightArrowIconPurple />
                <label className="regular-text zen-purple-text">{moment(endDate * 1000).format('MMM DD, YYYY')}</label>
              </>
            ) : (
              <>
                <CalenderIcon height={16} width={16} color={theme.colors.ZenDefaultSecondary} />
                <label className="regular-text ">{moment(startDate * 1000).format('MMM DD, YYYY')}</label>
                <RightArrowIcon />
                <label className="regular-text">{moment(endDate * 1000).format('MMM DD, YYYY')}</label>
              </>
            )}
          </div>
          {openDateRange && (
            <DateRangeComponent
              startDate={startDate}
              endDate={endDate}
              handleApplyDates={data => {
                setStartDate(data?.startDate),
                  setEndDate(data?.endDate),
                  setOpenDateRange(false),
                  setDateRangeSelected(true);
              }}
              handleCancelDates={() => setOpenDateRange(false)}
              setDateRangeSelected={setDateRangeSelected}
            />
          )}
        </div>
      )}
      {showExportButton && hasPermission(exportButtonPermission) && (
        <Button
          className={`primary-grey ml-6 export-btn`}
          label={t('EXPORT')}
          borderRadius={'12px'}
          size={'medium'}
          icon={<ExportIcon height={16} width={16} />}
          onClick={() => checkPermission(() => onExportClick(), exportButtonPermission)}
        />
      )}
      {showPagination && (
        <Pagination
          className="ml-6"
          selectedPage={pagination.selectedPage}
          setSelectedPage={page => {
            pagination.setSelectedPage && pagination.setSelectedPage(page);
          }}
          totalPages={pagination.totalPages}
        />
      )}
    </PageHeaderWrapper>
  );
};

export default PageHeader;

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import ProfileUser from '../../components/my-profile';
import PageHeader from '../../components/page-header';
import { getDetailsProfileUser, getMFAConfigurationDetails } from '../../store/features/profileSlice';
import { PrizeDrawDetailContainerWrapper } from '../../styles/pages/prize-draw-detail-container.styled';

const Profile = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getDetailsProfileUser({
        id: id,
      }),
    ).catch(() => {});
    if (!id) {
      dispatch(getMFAConfigurationDetails()).catch(() => {});
    }
  }, [id]);

  return (
    <>
      <PrizeDrawDetailContainerWrapper className="flex-column flex-1">
        <PageHeader title={'Profile'} />
        <ProfileUser prfile_Id={id} />
      </PrizeDrawDetailContainerWrapper>
    </>
  );
};

export default Profile;

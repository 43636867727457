import styled from 'styled-components';

export const InsideModalWrapper = styled.div`
  position: fixed;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.modalBackground};
  top: 0;
  left: 0;
  user-select: none;
  outline: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .modal-content {
    display: flex;
    flex-direction: column;
    overflow-y: visible !important;
    background: #ffffff;
    border: 1px solid rgba(240, 246, 249, 0.5);
    box-shadow: 2px 2px 50px rgba(5, 49, 73, 0.2);
    border-radius: 16px;
    outline: 0;
    position: relative;
  }

  .popup-in-appear {
    opacity: 0;
    transform: translate(0px, 15px);
    transition: transform 100ms ease-in 50ms, opacity 100ms ease-in 50ms;
  }

  .popup-in-appear-active {
    opacity: 0;
    transform: translate(0px, 15px);
    transition: transform 100ms ease-in 50ms, opacity 100ms ease-in 50ms;
  }

  .popup-in-appear-done {
    opacity: 1;
    transform: unset;
    transition: transform 100ms ease-in 50ms, opacity 100ms ease-in 50ms;
  }

  .popup-fade-appear {
    opacity: 0;
    transform: translate(0px, 6px);
    transition: transform 100ms ease-in, opacity 50ms ease-in;
  }

  .popup-fade-appear-active {
    opacity: 0;
    transform: translate(0px, 6px);
    transition: transform 100ms ease-in 50ms, opacity 100ms ease-in 50ms;
  }

  .popup-fade-appear-done {
    opacity: 1;
    transform: unset;
    transition: transform 100ms ease-in 50ms, opacity 100ms ease-in 50ms;
  }
  .popup-dashboard-fullscreen {
    height: 100vh;
    width: 100vw;
    padding: 20px;
  }
`;

export const DeletePagePanelWrapper = styled.div`
  background: ${({ theme }) => theme.colors.backgroundColor};
  border-radius: 16px;
`;

export const DeleteFormWidgetWrapper = styled.div`
  background: ${({ theme }) => theme.colors.backgroundColor};
  border-radius: 16px;

  .component-details-wrapper {
    max-height: 174px;

    .component-details {
      background: #fff;
    }
  }
`;

export const AddChooserListWrapper = styled.div`
  .error-border {
    border-color: ${({ theme }) => theme.colors.ZenNegative};
  }
`;

export const AddProductsListWrapper = styled.div`
  .show-prize {
    .prize-container {
      height: fit-content;
      visibility: visible;
      padding: 4px 8px;
      margin-top: 4px;
    }
  }
  .hide-prize {
    .prize-container {
      height: 0;
      visibility: collapse;
    }
  }
  .plus,
  .minus {
    height: 16px;
    padding: 2px;
  }
  .plus {
    border-color: ${({ theme }) => theme.colors.ZenDefaultSecondary_O3};
    background-color: ${({ theme }) => theme.colors.ZenDefaultSecondary_O3};
  }
  .minus {
    border-color: ${({ theme }) => theme.colors.ZenNegativeSecondary_O3};
    background-color: ${({ theme }) => theme.colors.ZenNegativeSecondary_O3};
  }
  .prize-container {
    background-color: ${({ theme }) => theme.colors.backgroundColor};

    .update-action {
      top: 100%;
    }
  }

  .hover-edit {
    .edit-icon {
      display: none;
    }
    &:hover {
      .edit-icon {
        display: flex;
      }
    }
  }

  .prize-input {
    height: 24px;
  }

  .error-border {
    border-color: ${({ theme }) => theme.colors.ZenNegative};
  }
`;

import React from 'react';
import { useTranslation } from 'react-i18next';

const ComingSoonComponent = () => {
  const { t } = useTranslation();
  return (
    <div className="flex-column items-center justify-center h-full w-full">
      <label className="medium-text lighter-text font-16 ">{t('COMING_SOON')}</label>
    </div>
  );
};

export default ComingSoonComponent;

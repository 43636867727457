import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { addToast } from '../../../store/features/toastSlice';
import Button from '../button/button';

const StripePayment = ({
  backgroundColor,
  subscription,
  setPaymentDetails,
  onSubmitForm,
  selectedAddress,
  productComponentName,
  integration,
  selectedFormPanel,
}) => {
  const elements = useElements();
  const stripe = useStripe();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [message, setMessage] = useState(null);
  const [paymentConfirmationMessage, setPaymentConfirmationMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async () => {
    if (!subscription) {
      dispatch(addToast({ error: true, text: `Please select ${productComponentName}` }));
      return;
    }
    if (!stripe || !elements) {
      return;
    }
    setIsLoading(true);
    const { error: submitError } = await elements.submit();

    if (submitError) {
      setIsLoading(false);
      return;
    }

    try {
      setMessage(null);
      setPaymentConfirmationMessage('Your payment is completed');
      setIsLoading(false);
      if (selectedFormPanel?.submit_onpayment) {
        onSubmitForm();
      } else {
        setPaymentDetails({
          isCollectPayment: true,
          isConfirmed: true,
        });
      }
    } catch (e) {
      setMessage('An unexpected error occurred.');
      setIsLoading(false);
    }
  };

  const paymentElementOptions = {
    layout: 'tabs',
    fields: {
      billingDetails: {
        address: {
          country: selectedAddress?.country_code ? 'never' : 'auto',
        },
      },
    },
  };

  return (
    <div className="w-full">
      <label className="regular-text lighter-text">{t('ENTER_PAYMENT_DETAILS')}</label>
      <form className="mt-4 justify-center items-center">
        <PaymentElement options={paymentElementOptions} />
        <Button
          label={`Pay ${subscription?.priceAmountLabel ? subscription.priceAmountLabel : ''}`}
          className={`w-full mt-6 ${(isLoading || !stripe || !elements) && 'disabled'}`}
          disabled={isLoading || !stripe || !elements}
          onClick={onSubmit}
          size="large"
          borderRadius="16px"
          borderColor={backgroundColor || '#8927EF'}
          bgColor={backgroundColor || '#8927EF'}
          color="#ffffff"
          width="100%"
        />
        {message && <div className="regular-text error-text mt-4">{message}</div>}
        {paymentConfirmationMessage && (
          <div className="regular-text green-positive-text mt-4">{paymentConfirmationMessage}</div>
        )}
      </form>
    </div>
  );
};

const StripeWrapper = ({
  backgroundColor = '',
  subscription,
  setPaymentDetails,
  onSubmitForm,
  selectedAddress,
  productComponentName,
  integration,
  selectedFormPanel,
}) => {
  const stripePromise = integration?.public_key ? loadStripe(integration.public_key) : null;

  const appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: backgroundColor || '#ffffff',
      colorBackground: '#ffffff',
      colorText: '#16192C',
      spacingUnit: '4px',
      borderRadius: '16px',
    },
  };

  const option = {
    mode: 'subscription',
    amount: subscription?.amount || 1000,
    currency: subscription?.currency?.toLowerCase() || 'usd',
    paymentMethodCreation: 'manual',
    setupFutureUsage: 'off_session',
    appearance,
  };

  return (
    stripePromise && (
      <Elements stripe={stripePromise} options={option}>
        <StripePayment
          backgroundColor={backgroundColor}
          subscription={subscription}
          setPaymentDetails={setPaymentDetails}
          onSubmitForm={onSubmitForm}
          selectedAddress={selectedAddress}
          productComponentName={productComponentName}
          integration={integration}
          selectedFormPanel={selectedFormPanel}
        />
      </Elements>
    )
  );
};

export default StripeWrapper;

import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputElement from '../../../components/FormElements/input';
import DropDown from '../../../components/McDropdown';
import { fontStyleOptions } from '../../../constant/optionData';
import FormBuilderContext from '../../../context/FormBuilderContext';
import { ColorPicker } from '../common/color-picker';
export const HeaderGeneralConfig = ({ component, setSelectedComponent = () => {} }) => {
  const { fonts } = useContext(FormBuilderContext);
  const { name, font } = component;
  const { t } = useTranslation();
  const [colorPicker, setColorPicker] = useState('');

  return (
    <div className="flex-column my-8">
      <label className="flex-1 medium-text font-16">{t('GENERAL_UC')}</label>
      <InputElement
        name="Name"
        onChange={value => setSelectedComponent({ ...component, name: value })}
        value={name}
        label="Enter"
        className="w-full mt-6"
        font={{ size: 12 }}
        type={'TEXTAREA'}
        showRequiredError={true}
      />
      <div className="flex items-center mt-6">
        <label className="flex regular-text flex-1">{t('Font')}</label>
        <DropDown
          className="flex-1"
          options={fonts}
          selected={fonts.find(f => f.name === font.name)}
          placeholder="Font"
          setSelected={option => setSelectedComponent({ ...component, font: { ...font, name: option.name } })}
        />
      </div>
      <div className="flex items-center mt-4">
        <label className="flex regular-text flex-1">{t('STYLE')}</label>
        <DropDown
          className="flex-1"
          options={fontStyleOptions}
          selected={fontStyleOptions.find(fs => fs.value === font.style)}
          placeholder="Style"
          setSelected={option => setSelectedComponent({ ...component, font: { ...font, style: option.value } })}
        />
      </div>
      <div className="flex items-center mt-4">
        <label className="flex regular-text flex-1">{t('SIZE')}</label>
        <input
          className="input general-settings-input w-full"
          placeholder="Size"
          type="number"
          value={font.size}
          onChange={({ target: { value } }) => setSelectedComponent({ ...component, font: { ...font, size: value } })}
        />
      </div>
      <ColorPicker
        className="flex items-centers mt-6"
        label={'Color'}
        settingKey={'color'}
        selectedColor={font?.color || '#FFFFFF'}
        onChangeColor={(settingKey, color) =>
          setSelectedComponent({ ...component, font: { ...component.font, [settingKey]: color } })
        }
        colorPicker={colorPicker}
        setColorPicker={setColorPicker}
      />
    </div>
  );
};

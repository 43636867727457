import axios from 'axios';
import { setUser } from '../features/userSlice';
import instance from './index';

const setUpInterceptor = store => {
  instance.interceptors.request.use(
    async function (config) {
      try {
        config.headers.Authorization = 'Bearer ' + (await getAccessToken(store));
      } catch (e) {
        localStorage.removeItem('user');
        window.location.reload();
      }
      return config;
    },
    function (error) {
      return Promise.reject(error);
    },
  );
};

const getUserData = async store => {
  const user = JSON.parse(localStorage.getItem('user'));
  if (user.exp - 10000 < new Date().getTime()) {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/authentication/refresh?refresh_token=${user.refresh_token}`,
      {},
    );
    const newUser = { ...user, api_tokens: data };
    localStorage.setItem('user', JSON.stringify(newUser));
    store.dispatch(setUser(newUser));
    return newUser;
  } else return user;
};

const getAccessToken = async (ignore = false) => {
  const user = await getUserData(ignore);
  const api_tokens = user?.original_api_tokens || {};
  return api_tokens.access_token;
};

export default setUpInterceptor;

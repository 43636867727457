import React, { Fragment } from 'react';
import InputElement from '../../../components/FormElements/input';
import Switch from '../../../components/common/switch';

export const MultichoiceAdvanceConfig = ({ component, setSelectedComponent = () => {} }) => {
  const { lexicon, is_confidential, is_visible } = component;

  return (
    <Fragment>
      <InputElement
        name="Lexicon"
        onChange={value => setSelectedComponent({ ...component, lexicon: value })}
        value={lexicon}
        label="Enter"
        className="w-full mt-6"
        font={{ size: 12 }}
      />
      <div className="flex mt-6">
        <label className="regular-text flex-1">Confidential</label>
        <Switch
          enabled={is_confidential}
          onClick={() => setSelectedComponent({ ...component, is_confidential: !is_confidential })}
        />
      </div>
      <div className="flex mt-6">
        <label className="regular-text flex-1">Visible</label>
        <Switch enabled={is_visible} onClick={() => setSelectedComponent({ ...component, is_visible: !is_visible })} />
      </div>
    </Fragment>
  );
};
